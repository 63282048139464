import React, { useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { EventBus } from 'ts-bus';
import { MuiThemeProvider, TextField, makeStyles } from '@material-ui/core';

import { propertiesStyles } from '../PropertiesComponent';
import {
  clearMapEvent,
  drawPropertyWithoutMarkerEvent,
} from '../../map/MapComponent';
import { materialUiTheme, defaultStyles } from '../../../styling/styles';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../../styling/dimens';
import { Property } from '../../../server/model/property/Property';
import { centerBoundingBox } from '../PropertiesListComponent';
import MadePaymentComponent from './MadePaymentComponent';
import { MadePaymentInfo } from '../../../server/model/property/MadePaymentInfo';

export type SingleNonEditablePaymentDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  property: Property;
  groupColor: string;
};

export default function SingleNonEditablePaymentDetailComponent(
  singleNonEditablePaymentDetailComponentProps: SingleNonEditablePaymentDetailComponentProps
) {
  const { t } = useTranslation();

  function goBack() {
    singleNonEditablePaymentDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    singleNonEditablePaymentDetailComponentProps.goBack();
  }

  // At start clear once the map
  useEffect(() => {
    singleNonEditablePaymentDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    singleNonEditablePaymentDetailComponentProps.eventBus.publish(
      drawPropertyWithoutMarkerEvent({
        property: singleNonEditablePaymentDetailComponentProps.property,
        color: singleNonEditablePaymentDetailComponentProps.groupColor,
      })
    );
    centerBoundingBox(
      singleNonEditablePaymentDetailComponentProps.property,
      singleNonEditablePaymentDetailComponentProps.eventBus,
      false
    );
  }, [
    singleNonEditablePaymentDetailComponentProps.eventBus,
    singleNonEditablePaymentDetailComponentProps.property,
    singleNonEditablePaymentDetailComponentProps.groupColor,
  ]);

  const headerText = singleNonEditablePaymentDetailComponentProps.owner
    ? t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.HEADER_OWNER')
    : t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.HEADER_RENTER');

  const paymentAmountText = singleNonEditablePaymentDetailComponentProps
    .property.currentPaymentAmount
    ? singleNonEditablePaymentDetailComponentProps.property.currentPaymentAmount
    : t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.NO_INFO');
  const paymentCurrencyText = singleNonEditablePaymentDetailComponentProps
    .property.currentCurrency
    ? singleNonEditablePaymentDetailComponentProps.property.currentCurrency
    : t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.NO_INFO');
  let paymentInvervalText = '';
  switch (
    singleNonEditablePaymentDetailComponentProps.property.currentPaymentInterval
  ) {
    case 'ANNUALLY':
      paymentInvervalText = t('PAYMENT_INTERVAL.ANNUALLY');
      break;
    case 'BIANNUALLY':
      paymentInvervalText = t('PAYMENT_INTERVAL.BIANNUALLY');
      break;
    case 'QUARTERLY':
      paymentInvervalText = t('PAYMENT_INTERVAL.QUARTERLY');
      break;
    case 'MONTHLY':
      paymentInvervalText = t('PAYMENT_INTERVAL.MONTHLY');
      break;
    default:
      paymentInvervalText = t(
        'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.NO_INFO'
      );
  }

  const sortedPaymentEntriesByYear = sortPaymentEntriesByYear(
    singleNonEditablePaymentDetailComponentProps.property.paymentHistory
  );
  const materialStyles = provideMaterialStyles();
  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            goBack();
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <MuiThemeProvider theme={materialUiTheme}>
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.HINT_NO_CHANGE_POSSIBLE'
              ),
            }}
            className={css(styles.caption)}
          />
          <div className={css(styles.spacing)} />

          <div className={css(propertiesStyles.sectionSubView)}>
            {t(
              'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.SECTION_CURRENT_PAYMENT_DATA'
            )}
          </div>
          <div className={css(styles.smallSpacing)} />
          <div className={css(styles.doubleTextFieldContainer)}>
            <TextField
              className={css(styles.paymentAmountTextField)}
              disabled={true}
              type="number"
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.PAYMENT_AMOUNT'
              )}
              value={paymentAmountText}
            />
            <TextField
              className={css(styles.currencyTextField)}
              disabled={true}
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.PAYMENT_CURRENCY'
              )}
              value={paymentCurrencyText}
            />
          </div>
          <div className={css(styles.smallSpacing)} />
          <TextField
            className={css(styles.paymentIntervalTextField)}
            disabled={true}
            label={t(
              'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.PAYMENT_INTERVAL'
            )}
            value={paymentInvervalText}
          />

          <div className={css(styles.spacing)} />
          <div className={css(styles.bigSpacing)} />
          <div className={css(propertiesStyles.sectionSubView)}>
            {t(
              'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.SECTION_PAYMENT_HISTORY'
            )}
          </div>
          <div className={css(styles.smallSpacing)} />

          {singleNonEditablePaymentDetailComponentProps.property.paymentHistory
            .length > 0 ? (
            <List className={materialStyles.root} subheader={<li />}>
              {Object.keys(sortedPaymentEntriesByYear)
                .reverse()
                .map((year: string) => {
                  const sortedPaymentEntriesForCurrentYear =
                    sortedPaymentEntriesByYear[year];

                  return (
                    <li
                      key={`section-${year}`}
                      className={materialStyles.sectionYear}
                    >
                      <ul className={materialStyles.ul}>
                        <ListSubheader
                          className={materialStyles.sectionYearHeader}
                        >
                          {year}
                        </ListSubheader>
                        {sortedPaymentEntriesForCurrentYear.paymentEntries.map(
                          (paymentItem) => (
                            <MadePaymentComponent
                              key={paymentItem.id}
                              removeEnabled={false}
                              isSendingRequest={false}
                              paymentEntry={paymentItem}
                              clickedRemovePayment={() => {}}
                            />
                          )
                        )}
                      </ul>
                    </li>
                  );
                })}
            </List>
          ) : null}
          {singleNonEditablePaymentDetailComponentProps.property.paymentHistory
            .length === 0 ? (
            <div className={css(styles.caption)}>
              {t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PAYMENT.NO_PAYMENT_HISTORY'
              )}
            </div>
          ) : null}
          <div className={css(styles.bottomSpacing)} />
        </MuiThemeProvider>
      </div>
    </div>
  );
}

const provideMaterialStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
    overflow: 'auto',
  },
  sectionYear: {
    backgroundColor: 'inherit',
  },
  sectionYearHeader: {
    paddingBottom: 0,
    paddingTop: 20,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const styles = StyleSheet.create({
  doubleTextFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  paymentAmountTextField: {
    width: '55%',
  },
  currencyTextField: {
    width: '35%',
    height: 48,
  },
  paymentIntervalTextField: {
    width: '35%',
    height: 48,
    float: 'right',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 70,
  },
  bottomSpacing: {
    height: 50,
  },
});

type PaymentYearsWithEntries = {
  [year: string]: {
    paymentEntries: MadePaymentInfo[];
  };
};

function sortPaymentEntriesByYear(
  paymentEntries: MadePaymentInfo[]
): PaymentYearsWithEntries {
  const paymentYearsWithEntries: PaymentYearsWithEntries = {};
  paymentEntries
    .sort(function (a: MadePaymentInfo, b: MadePaymentInfo) {
      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
    })
    .forEach((madePaymentInfo: MadePaymentInfo) => {
      const date = new Date(Date.parse(madePaymentInfo.timestamp));
      const year = date.toLocaleDateString(i18next.language, {
        year: 'numeric',
      });
      var paymentYearWithEntries = paymentYearsWithEntries[year];
      if (!paymentYearWithEntries) {
        paymentYearWithEntries = {
          paymentEntries: [],
        };
      }
      paymentYearWithEntries.paymentEntries.push(madePaymentInfo);
      paymentYearsWithEntries[year] = paymentYearWithEntries;
    });

  return paymentYearsWithEntries;
}
