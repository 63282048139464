import { GroupsWithProperties } from '../components/property/PropertiesListComponent';
import { PropertyToDraw } from '../components/map/model/PropertyToDraw';
import { Property } from '../server/model/property/Property';

const COLORS = [
  '#b71c1c',
  '#880e4f',
  '#bc477b',
  '#4a148c',
  '#7c43bd',
  '#1a237e',
  '#534bae',
  '#01579b',
  '#006064',
  '#bc5100',
  '#ac1900',
  '#870000',
  '#4f5b62',
];
export const NO_GROUPS_GROUP_ID = 1337;
export const NO_GROUP_COLOR = '#4169E1';

export function sortPropertiesbyGroup(
  properties: Property[],
  showGroups: boolean,
  givenGroups?: any
): { groups: GroupsWithProperties; propertiesToDraw: PropertyToDraw[] } {
  const groups: GroupsWithProperties = {};
  const propertiesToDraw = properties.map((property: Property) => {
    const groupId = showGroups ? property.groupId : NO_GROUPS_GROUP_ID;
    var group = groups[groupId];
    if (!group) {
      if (showGroups) {
        group = {
          propertiesToDraw: [],
          color: COLORS[groupId % COLORS.length],
          groupData: givenGroups.find(
            (groupResponse: { id: number }) => groupId === groupResponse.id
          ),
        };
      } else {
        group = {
          propertiesToDraw: [],
          color: NO_GROUP_COLOR,
          groupData: undefined,
        };
      }
    }
    const propertyToDraw = {
      property,
      color: group.color,
      userVisibleNumber: group.propertiesToDraw.length + 1,
      groupId: groupId,
    };
    group.propertiesToDraw.push(propertyToDraw);
    groups[groupId] = group;

    return propertyToDraw;
  });

  return {
    groups,
    propertiesToDraw,
  };
}
