import React from 'react';
import { Route } from 'react-router-dom';

import { initiateAdminLogin } from './authentication';

async function login() {
  await initiateAdminLogin();
}

export default function AdminRoute({
  component,
  isAdminAuthenticated,
  appComponentsProps,
  ...rest
}: any) {
  if (!isAdminAuthenticated) {
    login();
    return null;
  }

  const routeComponent = (props: any) => {
    return React.createElement(component, { ...props, appComponentsProps });
  };
  return <Route {...rest} render={routeComponent} />;
}
