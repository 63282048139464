import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  Collapse,
  ListSubheader,
  makeStyles,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { css, StyleSheet } from 'aphrodite';

import {
  SECONDARY_TEXT_COLOR_ON_WHITE,
  PROPERTIES_BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../styling/colors';
import { defaultStyles } from '../../../styling/styles';
import { MadePaymentInfo } from '../../../server/model/property/MadePaymentInfo';

export type MadePaymentComponentProps = {
  paymentEntry: MadePaymentInfo;
  removeEnabled: boolean;
  isSendingRequest: boolean;
  clickedRemovePayment: () => void;
};

export default function MadePaymentComponent(
  madePaymentComponentProps: MadePaymentComponentProps
) {
  const [removePaymentDialogOpen, setRemovePaymentDialogOpen] = useState(false);
  const [isEntryOpened, setIsEntryOpened] = useState(false);
  const { t } = useTranslation();

  const handleCloseRemovePaymentCloseDialog = () => {
    setRemovePaymentDialogOpen(false);
  };

  const amountInfo =
    madePaymentComponentProps.paymentEntry.amount &&
    madePaymentComponentProps.paymentEntry.currency
      ? madePaymentComponentProps.paymentEntry.amount +
        ' ' +
        madePaymentComponentProps.paymentEntry.currency
      : t('PROPERTIES.PAYMENT_INFO.NO_AMOUNT');

  const date = new Date(
    Date.parse(madePaymentComponentProps.paymentEntry.timestamp)
  );
  const dayOfMonth = date.toLocaleDateString(i18next.language, {
    day: 'numeric',
  });
  const month = date.toLocaleDateString(i18next.language, {
    month: 'long',
  });

  const hasOtherOwnerInfo =
    madePaymentComponentProps.paymentEntry.otherCurrentOwnerMailAddresses
      .length !== 0 ||
    madePaymentComponentProps.paymentEntry.unknownOldOwnerAmount !== 0;
  const hasOtherRenterInfo =
    madePaymentComponentProps.paymentEntry.otherCurrentRenterMailAddresses
      .length !== 0 ||
    madePaymentComponentProps.paymentEntry.unknownOldRenterAmount !== 0;

  const otherOwnersInfo = (
    <div className={css(styles.userListContainer)}>
      <div className={css(styles.userCaption)}>
        {t('PROPERTIES.PAYMENT_INFO.OTHER_OWNER')}
      </div>
      {madePaymentComponentProps.paymentEntry.otherCurrentOwnerMailAddresses.map(
        (otherOwnerMail) => (
          <div className={css(styles.userText)}>{otherOwnerMail}</div>
        )
      )}
      {madePaymentComponentProps.paymentEntry.unknownOldOwnerAmount !== 0 ? (
        <div className={css(styles.userText)}>
          {madePaymentComponentProps.paymentEntry.unknownOldOwnerAmount +
            t('PROPERTIES.PAYMENT_INFO.UNKNOWN_OWNER')}
        </div>
      ) : null}
    </div>
  );

  const otherRentersInfo = (
    <div className={css(styles.userListContainer)}>
      <div className={css(styles.userCaption)}>
        {t('PROPERTIES.PAYMENT_INFO.OTHER_RENTER')}
      </div>
      {madePaymentComponentProps.paymentEntry.otherCurrentRenterMailAddresses.map(
        (otherRenterMail) => (
          <div className={css(styles.userText)}>{otherRenterMail}</div>
        )
      )}
      {madePaymentComponentProps.paymentEntry.unknownOldRenterAmount !== 0 ? (
        <div className={css(styles.userText)}>
          {madePaymentComponentProps.paymentEntry.unknownOldRenterAmount +
            t('PROPERTIES.PAYMENT_INFO.UNKNOWN_RENTER')}
        </div>
      ) : null}
    </div>
  );

  const otherUsersInfo =
    !hasOtherOwnerInfo && !hasOtherRenterInfo ? (
      t('PROPERTIES.PAYMENT_INFO.NO_OTHER_USERS')
    ) : hasOtherOwnerInfo && hasOtherRenterInfo ? (
      <div>
        {otherOwnersInfo}
        <div className={css(styles.spacing)} />
        {otherRentersInfo}
      </div>
    ) : hasOtherOwnerInfo ? (
      otherOwnersInfo
    ) : (
      otherRentersInfo
    );

  const materialStyles = provideMaterialStyles();
  return (
    <ul className={materialStyles.ul}>
      <ListSubheader className={materialStyles.listSectionHeader}>
        <ListItem button onClick={() => setIsEntryOpened(!isEntryOpened)}>
          <ListItemText>
            <div className={css(styles.container)}>
              <div className={css(styles.dateContainer)}>
                <div className={css(styles.dateText)}>{dayOfMonth}</div>
                <div className={css(styles.dateText)}>{month}</div>
              </div>
              <span className={css(styles.amountText)}>{amountInfo}</span>
            </div>
          </ListItemText>
          {isEntryOpened ? (
            <img
              src={window.location.origin + '/images/ExpandLess.svg'}
              alt="ExpandLess"
            />
          ) : (
            <img
              src={window.location.origin + '/images/ExpandMore.svg'}
              alt="ExpandMore"
            />
          )}
        </ListItem>
      </ListSubheader>
      <Collapse in={isEntryOpened} timeout="auto" unmountOnExit>
        <div className={css(styles.contentContainer)}>
          <div className={css(styles.divider)} />
          {madePaymentComponentProps.removeEnabled ? (
            <div>
              <div className={css(styles.smallSpacing)} />
              <span
                onClick={() => {
                  if (!madePaymentComponentProps.isSendingRequest) {
                    setRemovePaymentDialogOpen(true);
                  }
                }}
              >
                <Button
                  className={css(styles.smallButton)}
                  disabled={madePaymentComponentProps.isSendingRequest}
                  variant="contained"
                  color="primary"
                >
                  {madePaymentComponentProps.isSendingRequest && (
                    <CircularProgress color={'secondary'} size={20} />
                  )}
                  {!madePaymentComponentProps.isSendingRequest &&
                    t('PROPERTIES.PAYMENT_INFO.REMOVE_BUTTON')}
                </Button>
              </span>
              <div className={css(styles.smallSpacing)} />{' '}
            </div>
          ) : null}
          <div className={css(styles.textContainer)}>{otherUsersInfo}</div>
        </div>
      </Collapse>

      <Dialog
        open={removePaymentDialogOpen}
        onClose={(event: any) => {
          handleCloseRemovePaymentCloseDialog();

          // Don't forward click
          event.stopPropagation();
        }}
        aria-labelledby="removePaymentDialogTitle"
        aria-describedby="removePaymentDialogText"
      >
        <DialogTitle id="removePaymentDialogTitle">
          {t('PROPERTIES.PAYMENT_INFO.REMOVE_PAYMENT_DIALOG_TITLE')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="removePaymentDialogText">
            {t('PROPERTIES.PAYMENT_INFO.REMOVE_PAYMENT_DIALOG_TEXT')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleCloseRemovePaymentCloseDialog();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
          >
            {t('GLOBAL.NO')}
          </Button>
          <Button
            onClick={(event) => {
              handleCloseRemovePaymentCloseDialog();
              madePaymentComponentProps.clickedRemovePayment();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
            autoFocus
          >
            {t('GLOBAL.YES')}
          </Button>
        </DialogActions>
      </Dialog>
    </ul>
  );
}

const provideMaterialStyles = makeStyles(() => ({
  listSectionHeader: {
    padding: 0,
  },
  ul: {
    backgroundColor: PROPERTIES_BACKGROUND_COLOR,
    padding: 0,
    marginTop: 10,
    marginBottom: 10,
  },
}));

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateContainer: {
    minWidth: 100,
    width: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  dateText: {
    ...defaultStyles.textStyle,
    letterSpacing: '0.1em',
  },
  amountText: {
    ...defaultStyles.textStyle,
    alignSelf: 'center',
    marginLeft: 20,
  },
  userListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  userCaption: {
    ...defaultStyles.textStyle,
    marginBottom: 4,
  },
  userText: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    marginBottom: 2,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    marginTop: 15,
    marginLeft: 66,
    marginRight: 40,
    marginBottom: 15,
  },
  divider: {
    width: '92%',
    marginLeft: '4%',
    height: 1.0,
    opacity: 0.1,
    marginTop: 2,
    backgroundColor: PRIMARY_TEXT_COLOR,
  },
  spacing: {
    height: 30,
  },
  smallSpacing: {
    height: 10,
  },
  smallButton: {
    ...defaultStyles.materialSmallButtonStyle,
    fontWeight: 'bold',
    float: 'right',
    marginRight: 16,
  },
});
