import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { ACCENT_COLOR } from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function ErrorContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  document.title = t('GLOBAL.ERROR_PAGE_TITLE');

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.error)}>
        <div>{t('ERROR_PAGE.HEADER')}</div>
      </span>
      <span className={css(styles.text)}>
        <div style={{ marginTop: 150, marginBottom: 30 }}>
          {t('ERROR_PAGE.TEXT')}
        </div>
      </span>
      <div style={{ textAlign: 'center' }}>
        <MuiThemeProvider theme={materialUiTheme}>
          <span
            className={css(styles.buttonContainerStyle)}
            onClick={() => {
              history.push('/');
            }}
          >
            <Button
              className={css(styles.buttonStyle)}
              variant="contained"
              color="primary"
            >
              {t('ERROR_PAGE.BUTTON')}
            </Button>
          </span>
        </MuiThemeProvider>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    textAlign: 'center',
    color: ACCENT_COLOR,
    fontSize: '8em',
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'center',
  },
  buttonContainerStyle: {
    textDecoration: 'none',
  },
  buttonStyle: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
  },
});
