import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { fetchBackend } from '../../authentication';
import {
  SystemInformation,
  DiskInfo,
  getReadableUpTime,
} from '../../server/model/admin/SystemInformation';
import { MuiThemeProvider, Button } from '@material-ui/core';
import { TFunction } from 'i18next';

export default function AdminSystemInformation(_props: any) {
  const [systemInformation, setSystemInformation] = useState<
    SystemInformation | undefined
  >(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  useEffect(() => {
    const loadSystemInformation = async function () {
      const requestOptions = {
        method: 'GET',
      };
      setSystemInformation(
        await fetchBackend(
          'secure/admin/systemInformation',
          requestOptions,
          false,
          true,
          true
        )
      );
    };
    if (systemInformation === undefined) loadSystemInformation();
  });

  if (systemInformation === undefined) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ADMIN_SYSTEM_INFORMATION_PAGE.TITLE')}</div>
      </span>
      <div className={css(styles.spacing)} />

      <div className={css(styles.section)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.PROCESSOR_INFO')}
      </div>
      <div className={css(styles.text)}>{systemInformation.processorInfo}</div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.RAM_INFO')}
      </div>
      <div className={css(styles.text)}>{systemInformation.ramInfo}</div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.UPTIME')}
      </div>
      <div className={css(styles.text)}>
        {getReadableUpTime(systemInformation, t)}
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.CPU_LOAD_HEADER')}
      </div>
      <div className={css(styles.text)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.CPU_LOAD_ONE_MINUTE') +
          systemInformation.cpuLoad.usageLastMinute +
          '%'}
      </div>
      <div className={css(styles.text)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.CPU_LOAD_FIVE_MINUTES') +
          systemInformation.cpuLoad.usageLastFiveMinutes +
          '%'}
      </div>
      <div className={css(styles.text)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.CPU_LOAD_FIFTEEN_MINUTES') +
          systemInformation.cpuLoad.usageLastFifteenMinutes +
          '%'}
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_SYSTEM_INFORMATION_PAGE.DISK_INFO')}
      </div>
      {systemInformation.disks.map((diskInfo: DiskInfo, index: number) => {
        return <DiskInfoView key={index} diskInfo={diskInfo} t={t} />;
      })}

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <MuiThemeProvider theme={materialUiTheme}>
        <span onClick={() => history.goBack()}>
          <Button
            className={css(styles.secondaryButton)}
            variant="contained"
            color="secondary"
          >
            {t('GLOBAL.BACK')}
          </Button>
        </span>
      </MuiThemeProvider>
    </div>
  );
}

function DiskInfoView(props: { diskInfo: DiskInfo; t: TFunction }) {
  return (
    <div className={css(styles.text)}>
      {props.diskInfo.label +
        ': ' +
        props.diskInfo.freeSpace.toFixed(2) +
        ' Gb / ' +
        props.diskInfo.totalSpace.toFixed(2) +
        ' Gb ' +
        props.t('ADMIN_SYSTEM_INFORMATION_PAGE.AVAILABLE')}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 20,
  },
  section: {
    ...defaultStyles.textStyle,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
  },
});
