import React, { useState, useEffect } from 'react';
import { EventBus } from 'ts-bus';
import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';

import { GetPropertiesResponse } from '../../server/responses/GetPropertiesResponse';
import { GetInvitedPropertiesAmountResponse } from '../../server/responses/GetInvitedPropertiesAmountResponse';
import { UserSettings } from '../../server/model/user/UserSettings';
import { fetchBackend } from '../../authentication';
import { materialUiTheme, defaultStyles } from '../../styling/styles';
import {
  centerBoundingBoxEvent,
  drawPropertiesEvent,
} from '../map/MapComponent';
import {
  TEXT_SIZE_SLIGHTLY_BIGGER,
  PROPERTY_SUBVIEW_HEADER_HEIGHT,
} from '../../styling/dimens';
import PropertiesListComponent from './PropertiesListComponent';
import { useTranslation } from 'react-i18next';
import {
  MAX_WIDTH_PROPERTIES_LIST,
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../../util/AppComponentsProps';
import PropertyAddButtonComponent from './PropertyAddButtonComponent';
import AddNewPropertyComponent from './AddNewPropertyComponent';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../styling/colors';
import ShowInvitedPropertiesListComponent from './ShowInvitedPropertiesListComponent';
import { sortPropertiesbyGroup } from '../../util/PropertyUtil';
import SinglePropertyDetailComponent from './detailviews/SinglePropertyDetailComponent';
import { PropertyToDraw } from '../map/model/PropertyToDraw';
import SinglePaymentDetailComponent from './detailviews/SinglePaymentDetailComponent';
import { AccountLimit } from '../../server/responses/AccountLimit';
import { useHistory } from 'react-router-dom';

export type PropertiesComponentProps = {
  hidden: boolean;
  owner: boolean;
  eventBus: EventBus;
  appComponentsProps: AppComponentsProps;
};

export default function PropertiesComponent(
  propertiesComponentProps: PropertiesComponentProps
) {
  const [accountLimit, setAccountLimit] = useState<AccountLimit | undefined>(
    undefined
  );
  const [getPropertiesResponse, setGetPropertiesResponse] = useState<
    GetPropertiesResponse | undefined
  >(undefined);
  const [
    getInvitedPropertiesAmountResponse,
    setGetInvitedPropertiesAmountResponse,
  ] = useState<GetInvitedPropertiesAmountResponse | undefined>(undefined);
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    undefined
  );
  const [addNewPropertyOpened, setAddNewPropertyOpened] = useState<boolean>(
    false
  );
  const [
    showInvitedPropertiesOpened,
    setShowInvitedPropertiesOpened,
  ] = useState<boolean>(false);
  const [showSinglePropertyDetails, setShowSinglePropertyDetails] = useState<
    PropertyToDraw | undefined
  >(undefined);
  const [showSinglePaymentDetails, setShowSinglePaymentDetails] = useState<
    PropertyToDraw | undefined
  >(undefined);

  const history = useHistory();
  const { t } = useTranslation();
  const mobileScreen =
    propertiesComponentProps.appComponentsProps.windowWidth <=
    MAX_WIDTH_MOBILE_PHONE;

  useEffect(() => {
    const getAccountLimitResponse = async function () {
      const accountLimitResponse = await fetchBackend('secure/account/limit');
      setAccountLimit(accountLimitResponse);
    };
    if (accountLimit === undefined) getAccountLimitResponse();
  }, [accountLimit]);

  useEffect(() => {
    const getGetPropertiesResponse = async function () {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ owner: propertiesComponentProps.owner }),
      };
      const propertiesResponse = await fetchBackend(
        'secure/property/getProperties',
        requestOptions
      );

      if (propertiesResponse.success === true) {
        setGetPropertiesResponse(propertiesResponse);
      }
    };
    if (getPropertiesResponse === undefined) getGetPropertiesResponse();
  }, [getPropertiesResponse, propertiesComponentProps.owner]);

  useEffect(() => {
    const getGetInvitedPropertiesAmountResponse = async function () {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ owner: propertiesComponentProps.owner }),
      };
      const invitedPropertiesAmountResponse = await fetchBackend(
        'secure/property/getInvitedPropertiesAmount',
        requestOptions
      );

      if (invitedPropertiesAmountResponse.success === true) {
        setGetInvitedPropertiesAmountResponse(invitedPropertiesAmountResponse);
      }
    };

    if (getInvitedPropertiesAmountResponse === undefined)
      getGetInvitedPropertiesAmountResponse();
  }, [getInvitedPropertiesAmountResponse, propertiesComponentProps.owner]);

  useEffect(() => {
    const getUserSettings = async function () {
      const userSettings = await fetchBackend('secure/account/settings');

      if (userSettings.success === true) {
        setUserSettings(userSettings.settings);
      }
    };

    if (userSettings === undefined) getUserSettings();
  }, [userSettings]);

  useEffect(() => {
    // Update the map-data only if we have new properties loaded
    if (getPropertiesResponse && userSettings) {
      if (
        getPropertiesResponse.boundingBoxUpperLeft &&
        getPropertiesResponse.boundingBoxLowerRight
      ) {
        propertiesComponentProps.eventBus.publish(
          centerBoundingBoxEvent({
            switchToMapViewOnMobileDevice: false,
            upperLeft: getPropertiesResponse.boundingBoxUpperLeft,
            lowerRight: getPropertiesResponse.boundingBoxLowerRight,
          })
        );
      }

      // get properties for each group and generate properties for drawing on map
      const sortedPropertiesWithGroups = sortPropertiesbyGroup(
        getPropertiesResponse.properties,
        userSettings.showGroups,
        getPropertiesResponse.groups
      );

      propertiesComponentProps.eventBus.publish(
        drawPropertiesEvent({
          propertiesToDraw: sortedPropertiesWithGroups.propertiesToDraw,
        })
      );
    }
  }, [getPropertiesResponse, userSettings, propertiesComponentProps.eventBus]);

  const height = mobileScreen
    ? propertiesComponentProps.appComponentsProps.windowHeight - 50 - 48 // Only minus header and tabbar-height, rest of height can be used
    : 'calc(100% - 148px)'; // Minus Header and Footer (Desktop)

  if (
    accountLimit === undefined ||
    getPropertiesResponse === undefined ||
    getInvitedPropertiesAmountResponse === undefined ||
    userSettings === undefined
  ) {
    return (
      <div
        className={
          propertiesComponentProps.hidden
            ? css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.mobileContainer,
                    visibility: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
            : mobileScreen
            ? css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.mobileContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
            : css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.container,
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
        }
      >
        <MuiThemeProvider theme={materialUiTheme}>
          <div className={css(propertiesStyles.loadingIconContainer)}>
            <CircularProgress color={'primary'} size={30} />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }

  if (
    addNewPropertyOpened ||
    showInvitedPropertiesOpened ||
    showSinglePropertyDetails ||
    showSinglePaymentDetails
  ) {
    return (
      <div
        className={
          propertiesComponentProps.hidden
            ? css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.mobileContainer,
                    visibility: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
            : mobileScreen
            ? css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.mobileContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
            : css(
                StyleSheet.create({
                  style: {
                    ...runtimeStyles.container,
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    height: height,
                  },
                }).style
              )
        }
      >
        {addNewPropertyOpened && (
          <AddNewPropertyComponent
            owner={propertiesComponentProps.owner}
            eventBus={propertiesComponentProps.eventBus}
            userSettings={userSettings}
            goBack={() => {
              // Reload properties
              setAccountLimit(undefined);
              setGetPropertiesResponse(undefined);
              setGetInvitedPropertiesAmountResponse(undefined);
              setAddNewPropertyOpened(false);
            }}
            mobileScreen={mobileScreen}
          />
        )}
        {showInvitedPropertiesOpened && (
          <ShowInvitedPropertiesListComponent
            owner={propertiesComponentProps.owner}
            eventBus={propertiesComponentProps.eventBus}
            userSettings={userSettings}
            goBack={() => {
              // Reload properties
              setAccountLimit(undefined);
              setGetPropertiesResponse(undefined);
              setGetInvitedPropertiesAmountResponse(undefined);
              setShowInvitedPropertiesOpened(false);
            }}
            mobileScreen={mobileScreen}
            appComponentsProps={propertiesComponentProps.appComponentsProps}
          />
        )}
        {showSinglePropertyDetails && (
          <SinglePropertyDetailComponent
            owner={propertiesComponentProps.owner}
            eventBus={propertiesComponentProps.eventBus}
            userSettings={userSettings}
            goBack={() => {
              // Reload properties
              setAccountLimit(undefined);
              setGetPropertiesResponse(undefined);
              setGetInvitedPropertiesAmountResponse(undefined);
              setShowSinglePropertyDetails(undefined);
            }}
            property={showSinglePropertyDetails.property}
            groupColor={showSinglePropertyDetails.color}
            mobileScreen={mobileScreen}
          />
        )}
        {showSinglePaymentDetails && (
          <SinglePaymentDetailComponent
            owner={propertiesComponentProps.owner}
            eventBus={propertiesComponentProps.eventBus}
            goBack={() => {
              // Reload properties
              setAccountLimit(undefined);
              setGetPropertiesResponse(undefined);
              setGetInvitedPropertiesAmountResponse(undefined);
              setShowSinglePaymentDetails(undefined);
            }}
            userSettings={userSettings}
            property={showSinglePaymentDetails.property}
            groupColor={showSinglePaymentDetails.color}
          />
        )}
      </div>
    );
  }

  const sortedPropertiesWithGroups = sortPropertiesbyGroup(
    getPropertiesResponse.properties,
    userSettings.showGroups,
    getPropertiesResponse.groups
  );

  const headerText = propertiesComponentProps.owner
    ? t('PROPERTIES.LIST.MY_PROPERTIES')
    : t('PROPERTIES.LIST.RENTED_PROPERTIES');
  return (
    <div
      className={
        propertiesComponentProps.hidden
          ? css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.mobileContainer,
                  visibility: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  height: height,
                },
              }).style
            )
          : mobileScreen
          ? css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.mobileContainer,
                  display: 'flex',
                  flexDirection: 'column',
                  height: height,
                },
              }).style
            )
          : css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.container,
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  height: height,
                },
              }).style
            )
      }
    >
      <div className={css(propertiesStyles.headline)}>{headerText}</div>

      <PropertiesListComponent
        nonUsablePropertiesAmount={
          getPropertiesResponse.nonUsablePropertiesAmount
        }
        canAddMoreProperties={accountLimit.canAddMoreProperties}
        isInvitedProperties={false}
        owner={propertiesComponentProps.owner}
        eventBus={propertiesComponentProps.eventBus}
        groupsWithProperties={sortedPropertiesWithGroups.groups}
        showGroups={userSettings.showGroups}
        preferredAreaType={userSettings.preferredAreaType}
        appComponentsProps={propertiesComponentProps.appComponentsProps}
        clickedPropertyDetails={(propertyToDraw: PropertyToDraw) => {
          setShowSinglePropertyDetails(propertyToDraw);
        }}
        clickedPaymentDetails={(propertyToDraw: PropertyToDraw) => {
          setShowSinglePaymentDetails(propertyToDraw);
        }}
      />
      <PropertyAddButtonComponent
        invitedPropertiesAmount={getInvitedPropertiesAmountResponse.amount}
        clickedAddNewProperty={() => {
          if (accountLimit.canAddMoreProperties) {
            setAddNewPropertyOpened(true);
          } else {
            history.push('/accountpayment', {
              infoForUser: t(
                'PAYMENT_PAGE.NO_MORE_PROPERTIES_ALLOWED_IN_FREE_ABO'
              ),
            });
          }
        }}
        clickedShowInvitedProperties={() => {
          if (accountLimit.canAddMoreProperties) {
            setShowInvitedPropertiesOpened(true);
          } else {
            history.push('/accountpayment', {
              infoForUser: t(
                'PAYMENT_PAGE.NO_MORE_PROPERTIES_ALLOWED_IN_FREE_ABO'
              ),
            });
          }
        }}
      />
    </div>
  );
}

export const propertiesStyles = StyleSheet.create({
  headline: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    fontWeight: 'bold',
    marginTop: 18,
    marginBottom: 18,
    marginLeft: 40,
  },
  headlineSubView: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    fontWeight: 'bold',
    marginTop: 18,
    marginBottom: 18,
    alignItems: 'center',
  },
  sectionSubView: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    fontWeight: 'bold',
    alignItems: 'center',
  },
  subViewMainContainer: {
    height: '100%',
  },
  subViewHeaderContainer: {
    display: 'flex',
    height: PROPERTY_SUBVIEW_HEADER_HEIGHT,
    flex: 1,
    flexDirection: 'row',
  },
  // Use the maximum height minus the property-header and minus the padding
  subViewContentContainer: {
    height: 'calc(100% - ' + PROPERTY_SUBVIEW_HEADER_HEIGHT + ' - 40px)',
    padding: 20,
    overflowY: 'scroll',
  },
  backIconSubView: {
    alignSelf: 'center',
    width: 40,
    height: 24,
  },
  loadingIconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const runtimeStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    left: 0,
    top: 50,
    width: '100%',
    maxWidth: MAX_WIDTH_PROPERTIES_LIST,
  },
  mobileContainer: {
    flex: 1,
    display: 'flex',
    zIndex: -1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: MAX_WIDTH_MOBILE_PHONE,
  },
};
