import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';

import { useTranslation } from 'react-i18next';
import { propertiesStyles } from './PropertiesComponent';
import {
  clearMapEvent,
  restartDrawPropertyEvent,
  clearDrawPolygonMapEvent,
  polygonDrawnEvent,
  centerBoundingBoxEvent,
} from '../map/MapComponent';
import { EventBus } from 'ts-bus';
import { LatLng } from '../../server/model/property/LatLng';
import { getAreaSizeInGivenAreaType } from '../../util/MapUtil';
import {
  UserSettings,
  getReadableSize,
  AreaType,
} from '../../server/model/user/UserSettings';
import {
  MuiThemeProvider,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { materialUiTheme, defaultStyles } from '../../styling/styles';
import { fetchBackend } from '../../authentication';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../styling/dimens';
import GeocoderAutoComplete, { GeocodeResult } from './GeocoderAutoComplete';
import {
  Currency,
  PaymentInterval,
} from '../../server/model/property/Property';
import InvitedUserComponent from './invitations/InvitedUserComponent';
import AddUserComponent from './invitations/AddUserComponent';
import { delay } from '../../util/DelayUtil';
import { SHOW_ERROR_INFO_TO_USER } from '../../util/AppComponentsProps';

export type AddNewPropertyComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  mobileScreen: boolean;
};

let subscribedEvents: { (): void }[] = [];

export default function AddNewPropertyComponent(
  addNewPropertyComponentProps: AddNewPropertyComponentProps
) {
  const [info, setInfo] = useState<string>('');
  const [coordinates, setCoordinates] = useState<LatLng[]>([]);
  const [title, setTitle] = useState<string>('');
  const [officialData, setOfficialData] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [usedAreaType, setUsedAreaType] = useState<AreaType>(
    addNewPropertyComponentProps.userSettings.preferredAreaType
  );
  const [size, setSize] = useState<number>(0);
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(
    undefined
  );
  const [currency, setCurrency] = useState<Currency>('EUR');
  const [paymentInterval, setPaymentInterval] = useState<PaymentInterval>(
    'ANNUALLY'
  );
  const [invitedOwnerMailAddresses, setInvitedOwnerMailAddresses] = useState<
    string[]
  >([]);
  const [invitedRenterMailAddresses, setInvitedRenterMailAddresses] = useState<
    string[]
  >([]);
  const [invitedInfoText, setInvitedInfoText] = useState<string>('');

  const [
    sendingCreatePropertyRequest,
    setSendingCreatePropertyRequest,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  function goBack() {
    addNewPropertyComponentProps.eventBus.publish(clearDrawPolygonMapEvent({}));
    addNewPropertyComponentProps.goBack();
  }

  async function sendCreatePropertyRequest() {
    setSendingCreatePropertyRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        owner: addNewPropertyComponentProps.owner,
        invitedOwnerMailAddresses: invitedOwnerMailAddresses,
        invitedRenterMailAddresses: invitedRenterMailAddresses,
        invitedInfoText: invitedInfoText ? invitedInfoText : undefined,
        title: title,
        officialData: officialData,
        additionalInfo: additionalInfo,
        size: size,
        areaType: usedAreaType,
        coordinates: coordinates,
        currentPaymentAmount: paymentAmount,
        currentCurrency: paymentAmount ? currency : undefined,
        currentPaymentInterval: paymentAmount ? paymentInterval : undefined,
      }),
    };
    const response = await fetchBackend(
      'secure/property/addProperty',
      requestOptions
    );

    if (response.success === false) {
      setSendingCreatePropertyRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and go back after a few seconds to account-page
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      goBack();
    }
  }

  useEffect(() => {
    subscribedEvents.push(
      addNewPropertyComponentProps.eventBus.subscribe(
        polygonDrawnEvent,
        (event) => {
          const polygonCoordinates = event.payload.coordinates;
          setCoordinates(polygonCoordinates);
        }
      )
    );

    return () => {
      subscribedEvents.forEach((subscribedEvent) => subscribedEvent());
      subscribedEvents = [];
    };
  }, [addNewPropertyComponentProps.eventBus]);

  // At start clear once the map
  useEffect(() => {
    addNewPropertyComponentProps.eventBus.publish(clearMapEvent({}));
    addNewPropertyComponentProps.eventBus.publish(restartDrawPropertyEvent({}));
  }, [addNewPropertyComponentProps.eventBus]);

  const headerText = addNewPropertyComponentProps.owner
    ? t('PROPERTIES.ADD_PROPERTY.HEADER_OWNER')
    : t('PROPERTIES.ADD_PROPERTY.HEADER_RENTER');

  const calculatedSize = getAreaSizeInGivenAreaType(coordinates, usedAreaType);
  const userReadableCalculatedSize = getReadableSize(
    calculatedSize,
    usedAreaType,
    t
  );

  const createButtonEnabled = title && coordinates.length > 2 && size > 0;

  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            if (!sendingCreatePropertyRequest) {
              goBack();
            }
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <div className={css(propertiesStyles.sectionSubView)}>
              {t('PROPERTIES.ADD_PROPERTY.SECTION_COMMON_DATA')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              onChange={(input) => {
                const value = input.target.value;
                setTitle(value);
              }}
              disabled={sendingCreatePropertyRequest}
              fullWidth
              label={t('PROPERTIES.ADD_PROPERTY.TITLE')}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              onChange={(input) => {
                const value = input.target.value;
                setOfficialData(value);
              }}
              disabled={sendingCreatePropertyRequest}
              fullWidth
              label={t('PROPERTIES.ADD_PROPERTY.OFFICIAL_DATA')}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              onChange={(input) => {
                const value = input.target.value;
                setAdditionalInfo(value);
              }}
              disabled={sendingCreatePropertyRequest}
              fullWidth
              multiline
              rowsMax={5}
              label={t('PROPERTIES.ADD_PROPERTY.ADDITIONAL_INFO')}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.doubleTextFieldContainer)}>
              <TextField
                className={css(styles.sizeTextField)}
                onChange={(input) => {
                  const value = (input.target.value as unknown) as number;
                  setSize(value);
                }}
                disabled={sendingCreatePropertyRequest}
                type="number"
                label={t('PROPERTIES.ADD_PROPERTY.SIZE')}
              />
              <Select
                className={css(styles.areaTypeTextField)}
                value={usedAreaType}
                disabled={sendingCreatePropertyRequest}
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    value !== 'SQUARE_FOOT' &&
                    value !== 'SQUARE_METER' &&
                    value !== 'ACRE' &&
                    value !== 'HECTARE'
                  )
                    return;

                  setUsedAreaType(value as AreaType);
                }}
              >
                <MenuItem value={'SQUARE_FOOT'}>
                  {t('AREA_TYPES.SQUARE_FOOT')}
                </MenuItem>
                <MenuItem value={'SQUARE_METER'}>
                  {t('AREA_TYPES.SQUARE_METER')}
                </MenuItem>
                <MenuItem value={'ACRE'}>{t('AREA_TYPES.ACRE')}</MenuItem>
                <MenuItem value={'HECTARE'}>{t('AREA_TYPES.HECTARE')}</MenuItem>
              </Select>
            </div>
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {addNewPropertyComponentProps.mobileScreen
                ? t('PROPERTIES.ADD_PROPERTY.SECTION_DRAW_MOBILE')
                : t('PROPERTIES.ADD_PROPERTY.SECTION_DRAW')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <div
              dangerouslySetInnerHTML={{
                __html: t('PROPERTIES.ADD_PROPERTY.DRAW_INSTRUCTIONS'),
              }}
              className={css(styles.caption)}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <GeocoderAutoComplete
              disabled={sendingCreatePropertyRequest}
              clickedGeocodeResult={(geocodeResult: GeocodeResult) => {
                addNewPropertyComponentProps.eventBus.publish(
                  centerBoundingBoxEvent({
                    switchToMapViewOnMobileDevice: true,
                    upperLeft: geocodeResult.boundingBox.upperLeft,
                    lowerRight: geocodeResult.boundingBox.lowerRight,
                  })
                );
              }}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.caption)}>
              {t('PROPERTIES.ADD_PROPERTY.CALCULATED_SIZE') +
                (calculatedSize === 0
                  ? t('PROPERTIES.ADD_PROPERTY.NO_DRAWN_PROPERTY')
                  : userReadableCalculatedSize)}
            </div>
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <span
              onClick={() => {
                if (!sendingCreatePropertyRequest) {
                  setCoordinates([]);
                  addNewPropertyComponentProps.eventBus.publish(
                    restartDrawPropertyEvent({})
                  );
                }
              }}
            >
              <Button
                className={css(styles.smallButton)}
                disabled={
                  sendingCreatePropertyRequest || coordinates.length === 0
                }
                variant="contained"
                color="primary"
              >
                {t('PROPERTIES.ADD_PROPERTY.RESTART_DRAWING')}
              </Button>
            </span>
            <div className={css(styles.spacing)} />
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t('PROPERTIES.ADD_PROPERTY.SECTION_PAYMENT')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.doubleTextFieldContainer)}>
              <TextField
                className={css(styles.paymentAmountTextField)}
                onChange={(input) => {
                  const value = (input.target.value as unknown) as number;
                  setPaymentAmount(value);
                }}
                disabled={sendingCreatePropertyRequest}
                type="number"
                label={t('PROPERTIES.ADD_PROPERTY.PAYMENT_AMOUNT')}
              />
              <Select
                className={css(styles.currencyTextField)}
                value={currency}
                disabled={sendingCreatePropertyRequest}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value !== 'EUR' && value !== 'USD') return;

                  setCurrency(value as Currency);
                }}
              >
                <MenuItem value={'EUR'}>EUR</MenuItem>
                <MenuItem value={'USD'}>USD</MenuItem>
              </Select>
            </div>
            <div className={css(styles.smallSpacing)} />
            <Select
              className={css(styles.paymentIntervalTextField)}
              value={paymentInterval}
              disabled={sendingCreatePropertyRequest}
              onChange={(event) => {
                const value = event.target.value;
                if (
                  value !== 'ANNUALLY' &&
                  value !== 'BIANNUALLY' &&
                  value !== 'QUARTERLY' &&
                  value !== 'MONTHLY'
                )
                  return;

                setPaymentInterval(value as PaymentInterval);
              }}
            >
              <MenuItem value={'ANNUALLY'}>
                {t('PAYMENT_INTERVAL.ANNUALLY')}
              </MenuItem>
              <MenuItem value={'BIANNUALLY'}>
                {t('PAYMENT_INTERVAL.BIANNUALLY')}
              </MenuItem>
              <MenuItem value={'QUARTERLY'}>
                {t('PAYMENT_INTERVAL.QUARTERLY')}
              </MenuItem>
              <MenuItem value={'MONTHLY'}>
                {t('PAYMENT_INTERVAL.MONTHLY')}
              </MenuItem>
            </Select>
            <div className={css(styles.spacing)} />
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t('PROPERTIES.ADD_PROPERTY.SECTION_INVITATIONS')}
            </div>
            <div className={css(styles.smallSpacing)} />
            {invitedOwnerMailAddresses
              ? invitedOwnerMailAddresses.map(
                  (invitedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={invitedAddress}
                          owner={true}
                          editEnabled={!sendingCreatePropertyRequest}
                          onlyRemovePossible={false}
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newInvitedOwnerMailAddresses = [
                              ...invitedOwnerMailAddresses,
                            ];
                            newInvitedOwnerMailAddresses[index] = newValue;
                            setInvitedOwnerMailAddresses(
                              newInvitedOwnerMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newInvitedOwnerMailAddresses = [
                              ...invitedOwnerMailAddresses,
                            ];
                            newInvitedOwnerMailAddresses.splice(index, 1);
                            setInvitedOwnerMailAddresses(
                              newInvitedOwnerMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            <div>
              <AddUserComponent
                owner={true}
                clickedAddUser={() => {
                  if (!sendingCreatePropertyRequest) {
                    setInvitedOwnerMailAddresses([
                      ...invitedOwnerMailAddresses,
                      '',
                    ]);
                  }
                }}
              />
            </div>
            <div className={css(styles.smallSpacing)} />
            {invitedRenterMailAddresses
              ? invitedRenterMailAddresses.map(
                  (invitedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={invitedAddress}
                          owner={false}
                          editEnabled={!sendingCreatePropertyRequest}
                          onlyRemovePossible={false}
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newInvitedRenterMailAddresses = [
                              ...invitedRenterMailAddresses,
                            ];
                            newInvitedRenterMailAddresses[index] = newValue;
                            setInvitedRenterMailAddresses(
                              newInvitedRenterMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newInvitedRenterMailAddresses = [
                              ...invitedRenterMailAddresses,
                            ];
                            newInvitedRenterMailAddresses.splice(index, 1);
                            setInvitedRenterMailAddresses(
                              newInvitedRenterMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            <div>
              <AddUserComponent
                owner={false}
                clickedAddUser={() => {
                  if (!sendingCreatePropertyRequest) {
                    setInvitedRenterMailAddresses([
                      ...invitedRenterMailAddresses,
                      '',
                    ]);
                  }
                }}
              />
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              onChange={(input) => {
                const value = input.target.value;
                setInvitedInfoText(value);
              }}
              disabled={sendingCreatePropertyRequest}
              fullWidth
              multiline
              rowsMax={5}
              label={t('PROPERTIES.ADD_PROPERTY.INVITATION_INFO')}
            />

            <div className={css(styles.bigSpacing)} />
            <div className={css(styles.infoText)}>{info}</div>
            <div className={css(styles.smallSpacing)} />
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={!createButtonEnabled || sendingCreatePropertyRequest}
              onClick={() => {
                sendCreatePropertyRequest();
              }}
              color="primary"
            >
              {sendingCreatePropertyRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!sendingCreatePropertyRequest &&
                t('PROPERTIES.ADD_PROPERTY.ADD_BUTTON')}
            </Button>
            <span onClick={() => goBack()}>
              <Button
                className={css(styles.secondaryButton)}
                variant="contained"
                color="secondary"
              >
                {t('GLOBAL.CANCEL')}
              </Button>
            </span>
            <div className={css(styles.bottomSpacing)} />
          </MuiThemeProvider>
        </form>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  doubleTextFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  sizeTextField: {
    width: '45%',
  },
  areaTypeTextField: {
    width: '45%',
    height: 48,
  },
  paymentAmountTextField: {
    width: '55%',
  },
  currencyTextField: {
    width: '35%',
    height: 48,
  },
  paymentIntervalTextField: {
    width: '35%',
    height: 48,
    float: 'right',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 90,
  },
  bottomSpacing: {
    height: 50,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    float: 'right',
    marginRight: 15,
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    float: 'left',
    marginLeft: 15,
  },
  smallButton: {
    ...defaultStyles.materialSmallButtonStyle,
    fontWeight: 'bold',
    float: 'right',
  },
});
