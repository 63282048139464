import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Switch, MuiThemeProvider, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  TEXT_SIZE_SLIGHTLY_BIGGER,
  TEXT_SIZE_VERY_BIG,
  TEXT_SIZE_VERY_SMALL,
} from '../../styling/dimens';
import {
  ACCENT_COLOR,
  PRIMARY_TEXT_COLOR,
  TEXT_COLOR_LINK,
  SECONDARY_TEXT_COLOR,
} from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import {
  UserAccount,
  getReadableDate,
} from '../../server/model/user/UserAccount';
import { fetchBackend } from '../../authentication';
import { ACCOUNT_MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { UserSettings, AreaType } from '../../server/model/user/UserSettings';
import {
  Subscription,
  getReadableValidUntilDate,
  getHumanReadableProvider,
} from '../../server/model/payment/Subscription';
import { useHistory } from 'react-router-dom';

export default function AccountOverview(_props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const [userAccount, setUserAccount] = useState<UserAccount | undefined>(
    undefined
  );
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    undefined
  );
  const [hasSubscriptionData, setHasSubscriptionData] = useState<
    Boolean | undefined
  >(undefined);
  const [subscription, setSubscription] = useState<Subscription | undefined>(
    undefined
  );

  async function changeUserSettings(updatedUserSettings: UserSettings) {
    // Update data in backend and get newest info again
    await fetchBackend(
      'secure/account/changeSettings',
      {
        body: JSON.stringify(updatedUserSettings),
      },
      true
    );

    const userSettings = await fetchBackend('secure/account/settings');
    if (userSettings.success === true) {
      setUserSettings(userSettings.settings);
    }
  }

  useEffect(() => {
    const getAccountData = async function () {
      const userData = await fetchBackend('secure/account/data');

      if (userData.success === true) {
        setUserAccount(userData.data);
      }
    };
    const getUserSettings = async function () {
      const userSettings = await fetchBackend('secure/account/settings');

      if (userSettings.success === true) {
        setUserSettings(userSettings.settings);
      }
    };
    const getSubscriptionInfo = async function () {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ updateDataViaProvider: false }),
      };
      const subscriptionData = await fetchBackend(
        'secure/payment/getSubscriptionDetails',
        requestOptions
      );

      if (subscriptionData.success === true) {
        setHasSubscriptionData(true);
        if (subscriptionData.subscription) {
          setSubscription(subscriptionData.subscription);
        }
      }
    };

    if (userAccount === undefined) getAccountData();
    if (userSettings === undefined) getUserSettings();
    if (hasSubscriptionData === undefined) getSubscriptionInfo();
  });

  if (
    userAccount === undefined ||
    userSettings === undefined ||
    hasSubscriptionData === undefined
  ) {
    return null;
  }

  const subscriptionStatus = subscription ? subscription.status : undefined;
  let subscriptionInfo = null;
  switch (subscriptionStatus) {
    case 'ACTIVE':
      if (subscription) {
        subscriptionInfo = (
          <div className={css(styles.entryContainer)}>
            <div>
              <span className={css(styles.text)}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      t('ACCOUNT_PAGE.ABO_STATUS_ACTIVE') +
                      getReadableValidUntilDate(subscription),
                  }}
                />
              </span>
            </div>

            <span
              className={css(styles.link)}
              onClick={() => {
                history.push('/accountpayment');
              }}
            >
              {t('ACCOUNT_PAGE.LINK_ABO')}
            </span>
          </div>
        );
      }
      break;
    case 'PAUSED':
      if (subscription) {
        subscriptionInfo = (
          <div className={css(styles.entryContainer)}>
            <div>
              <span className={css(styles.text)}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      t('ACCOUNT_PAGE.ABO_STATUS_PAUSED') +
                      getReadableValidUntilDate(subscription),
                  }}
                />
              </span>
            </div>

            <span
              className={css(styles.link)}
              onClick={() => {
                history.push('/accountpayment');
              }}
            >
              {t('ACCOUNT_PAGE.LINK_ABO')}
            </span>
          </div>
        );
      }
      break;
    case 'PENDING':
      if (subscription) {
        const provider = getHumanReadableProvider(t, subscription);

        subscriptionInfo = (
          <div className={css(styles.entryContainer)}>
            <div>
              <span className={css(styles.text)}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('ACCOUNT_PAGE.ABO_STATUS_PEDNING') + provider,
                  }}
                />
              </span>
            </div>

            <span
              className={css(styles.link)}
              onClick={() => {
                history.push('/accountpayment');
              }}
            >
              {t('ACCOUNT_PAGE.LINK_ABO')}
            </span>
          </div>
        );
      }
      break;
    case 'FAILURE':
      subscriptionInfo = (
        <div className={css(styles.entryContainer)}>
          <div>
            <span className={css(styles.text)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('ACCOUNT_PAGE.ABO_STATUS_FAILURE'),
                }}
              />
            </span>
          </div>

          <span
            className={css(styles.link)}
            onClick={() => {
              history.push('/accountpayment');
            }}
          >
            {t('ACCOUNT_PAGE.LINK_ABO')}
          </span>
        </div>
      );
      break;
    default:
      subscriptionInfo = (
        <div className={css(styles.entryContainer)}>
          <div>
            <span className={css(styles.text)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('ACCOUNT_PAGE.ABO_NO_CURRENT_SUBSCRIPTION'),
                }}
              />
            </span>
          </div>

          <span
            className={css(styles.link)}
            onClick={() => {
              history.push('/accountpayment');
            }}
          >
            {t('ACCOUNT_PAGE.LINK_ABO_NEW')}
          </span>
        </div>
      );
      break;
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ACCOUNT_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ACCOUNT_PAGE.SECTION_PERSONAL_DATA')}
      </div>
      <div className={css(styles.divider)} />

      <div className={css(styles.entryContainer)}>
        <div>
          <span className={css(styles.text)}>{userAccount.email}</span>
        </div>
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/accountchangemail');
          }}
        >
          {t('ACCOUNT_PAGE.LINK_CHANGE_MAIL')}
        </span>
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.entryContainer)}>
        <div>
          <span className={css(styles.text)}>
            {t('ACCOUNT_PAGE.PASSWORD_LABEL')}
          </span>
        </div>
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/accountchangepassword');
          }}
        >
          {t('ACCOUNT_PAGE.LINK_CHANGE_PASSWORD')}
        </span>
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.entryContainer)}>
        <div>
          <span className={css(styles.text)}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  t('ACCOUNT_PAGE.REGISTERED_SINCE') +
                  getReadableDate(userAccount),
              }}
            />
          </span>
        </div>

        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/accountdelete');
          }}
        >
          {t('ACCOUNT_PAGE.LINK_DELETE')}
        </span>
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>{t('ACCOUNT_PAGE.SECTION_ABO')}</div>
      <div className={css(styles.divider)} />
      {subscriptionInfo}

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ACCOUNT_PAGE.SECTION_SETTINGS')}
      </div>
      <div className={css(styles.divider)} />

      <MuiThemeProvider theme={materialUiTheme}>
        <div className={css(styles.entryContainer)}>
          <div>
            <span className={css(styles.text)}>
              {t('ACCOUNT_PAGE.SETTINGS_AREA_TYPE')}
            </span>
          </div>
          <div>
            <Select
              value={userSettings.preferredAreaType}
              onChange={(event) => {
                const value = event.target.value;
                if (
                  value !== 'SQUARE_FOOT' &&
                  value !== 'SQUARE_METER' &&
                  value !== 'ACRE' &&
                  value !== 'HECTARE'
                )
                  return;

                const newUserSettings = {
                  preferredAreaType: value as AreaType,
                  showGroups: userSettings.showGroups,
                  mailNotificationsEnabled:
                    userSettings.mailNotificationsEnabled,
                };
                changeUserSettings(newUserSettings);
              }}
            >
              <MenuItem value={'SQUARE_FOOT'}>
                {t('AREA_TYPES.SQUARE_FOOT')}
              </MenuItem>
              <MenuItem value={'SQUARE_METER'}>
                {t('AREA_TYPES.SQUARE_METER')}
              </MenuItem>
              <MenuItem value={'ACRE'}>{t('AREA_TYPES.ACRE')}</MenuItem>
              <MenuItem value={'HECTARE'}>{t('AREA_TYPES.HECTARE')}</MenuItem>
            </Select>
          </div>
        </div>

        <div className={css(styles.spacing)} />
        <div className={css(styles.entryContainer)}>
          <div>
            <span className={css(styles.text)}>
              {t('ACCOUNT_PAGE.SETTINGS_SHOW_GROUPS')}
            </span>
          </div>
          <div>
            <Switch
              checked={userSettings.showGroups}
              color="primary"
              onChange={() => {
                const newUserSettings = {
                  preferredAreaType: userSettings.preferredAreaType,
                  showGroups: !userSettings.showGroups,
                  mailNotificationsEnabled:
                    userSettings.mailNotificationsEnabled,
                };
                changeUserSettings(newUserSettings);
              }}
            />
          </div>
        </div>

        <div className={css(styles.spacing)} />
        <div className={css(styles.entryContainer)}>
          <div>
            <span className={css(styles.text)}>
              {t('ACCOUNT_PAGE.SETTINGS_ENABLED_MAIL_NOTIFICATIONS')}
            </span>
          </div>
          <div>
            <Switch
              checked={userSettings.mailNotificationsEnabled}
              color="primary"
              onChange={() => {
                const newUserSettings = {
                  preferredAreaType: userSettings.preferredAreaType,
                  showGroups: userSettings.showGroups,
                  mailNotificationsEnabled: !userSettings.mailNotificationsEnabled,
                };
                changeUserSettings(newUserSettings);
              }}
            />
          </div>
        </div>
      </MuiThemeProvider>
      <div className={css(styles.spacing)} />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: ACCOUNT_MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  section: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_SMALL,
    color: SECONDARY_TEXT_COLOR,
    textAlign: 'justify',
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    height: 1.0,
    marginTop: 2,
    marginBottom: 10,
    backgroundColor: PRIMARY_TEXT_COLOR,
  },
  entryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spacing: {
    height: 35,
  },
  text: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
  },
  link: {
    ...defaultStyles.textStyle,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    color: TEXT_COLOR_LINK,
    textAlign: 'right',
  },
});
