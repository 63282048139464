import React from 'react';
import { Route } from 'react-router-dom';

import { initiateLogin } from './authentication';

async function login() {
  await initiateLogin();
}

export default function PrivateRoute({
  component,
  isAuthenticated,
  appComponentsProps,
  ...rest
}: any) {
  if (!isAuthenticated) {
    login();
    return null;
  }

  const routeComponent = (props: any) => {
    return React.createElement(component, { ...props, appComponentsProps });
  };
  return <Route {...rest} render={routeComponent} />;
}
