import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import MetaTags from 'react-meta-tags';
import CheckMark from '@material-ui/icons/CheckCircleRounded';
import {
  TEXT_SIZE_VERY_BIG,
  TEXT_SIZE_VERY_SMALL,
  TEXT_SIZE_SMALL,
} from '../../styling/dimens';
import {
  ACCENT_COLOR,
  TEXT_COLOR_ERROR,
  SECONDARY_BUTTON_COLOR,
} from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { useTranslation } from 'react-i18next';
import {
  MuiThemeProvider,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { fetchBackend, initiateLogin } from '../../authentication';
import { SMALL_INPUT_MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { validateMail } from '../../util/MailCheck';
import {
  passwordLengthOk,
  passwordHasAtLeastOneNumber,
  passwordHasAtLeastOneUpperCase,
  passwordHasAtLeastOneLowerCase,
} from '../../util/PasswordCheck';
import { delay } from '../../util/DelayUtil';

export default function Register(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.REGISTER_TITLE');

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [info, setInfo] = useState<string | undefined>(undefined);
  const [errorInfo, setErrorInfo] = useState<string | undefined>(undefined);
  const [givenMailAddress, setGivenMailAddress] = useState<string | undefined>(
    undefined
  );
  const [givenPassword, setGivenPassword] = useState<string | undefined>(
    undefined
  );
  const [isPasswordLengthOk, setIsPasswordLengthOk] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneNumber,
    setHasPasswordAtLeastOneNumber,
  ] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneLowerCase,
    setHasPasswordAtLeastOneLowerCase,
  ] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneUpperCase,
    setHasPasswordAtLeastOneUpperCase,
  ] = useState<boolean>(false);

  const [
    sendingCreatingAccountRequest,
    setSendingCreatingAccountRequest,
  ] = useState<boolean>(false);

  async function sendCreateAccountRequest() {
    setSendingCreatingAccountRequest(true);
    const requestOptions = {
      method: 'POST',
      body:
        '{"email": "' +
        givenMailAddress +
        '", "password": "' +
        givenPassword +
        '"}',
    };
    const response = await fetchBackend('account/register', requestOptions);

    if (response.success === false) {
      setSendingCreatingAccountRequest(false);
      setInfo('');
      setErrorInfo(response.message);
    }

    if (response.success === true) {
      setInfo(response.message);
      await delay(2500);
      initiateLogin();
    }
  }

  function checkDataInput(
    password: string | undefined,
    mail: string | undefined,
    showPasswordInfo: boolean
  ) {
    const lengthOk = passwordLengthOk(password);
    const atLeastOneNumber = passwordHasAtLeastOneNumber(password);
    const atLeastOneUpperCase = passwordHasAtLeastOneUpperCase(password);
    const atLeastOneLowerCase = passwordHasAtLeastOneLowerCase(password);
    const passwordIsCorrectFormat =
      lengthOk &&
      atLeastOneLowerCase &&
      atLeastOneUpperCase &&
      atLeastOneNumber;

    const isMailValid = validateMail(mail);
    setIsPasswordLengthOk(lengthOk);
    setHasPasswordAtLeastOneNumber(atLeastOneNumber);
    setHasPasswordAtLeastOneUpperCase(atLeastOneUpperCase);
    setHasPasswordAtLeastOneLowerCase(atLeastOneLowerCase);
    setButtonDisabled(!passwordIsCorrectFormat || !isMailValid);
  }

  function onClickMainButton() {
    sendCreateAccountRequest();
  }

  return (
    <div className={css(styles.container)}>
      <MetaTags>
        <meta name="description" content={t('GLOBAL.REGISTER_DESCRIPTION')} />
      </MetaTags>
      <span className={css(styles.header)}>
        <div>{t('REGISTER.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <MuiThemeProvider theme={materialUiTheme}>
        <TextField
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (buttonDisabled || sendingCreatingAccountRequest) return;
              onClickMainButton();
            }
          }}
          type="email"
          onChange={(input) => {
            const value = input.target.value;
            setGivenMailAddress(value);
            checkDataInput(givenPassword, value, false);
          }}
          disabled={sendingCreatingAccountRequest}
          fullWidth
          label={t('REGISTER.MAIL_ADDRESS')}
        />
        <div className={css(styles.spacing)} />
        <TextField
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (buttonDisabled || sendingCreatingAccountRequest) return;
              onClickMainButton();
            }
          }}
          type="password"
          onChange={(input) => {
            const value = input.target.value;
            setGivenPassword(value);
            checkDataInput(value, givenMailAddress, true);
          }}
          disabled={sendingCreatingAccountRequest}
          fullWidth
          label={t('REGISTER.PASSWORD')}
        />
        <div className={css(styles.spacing)} />

        <div className={css(styles.passwordHintContainer)}>
          <CheckMark
            className={
              isPasswordLengthOk
                ? css(styles.checkMarkIconFulfilled)
                : css(styles.checkMarkIcon)
            }
          />
          <span className={css(styles.passwordHintText)}>
            {t('PASSWORD_POLICY.LENGTH')}
          </span>
        </div>
        <div className={css(styles.passwordHintContainer)}>
          <CheckMark
            className={
              hasPasswordAtLeastOneNumber
                ? css(styles.checkMarkIconFulfilled)
                : css(styles.checkMarkIcon)
            }
          />
          <span className={css(styles.passwordHintText)}>
            {t('PASSWORD_POLICY.NUMBER')}
          </span>
        </div>
        <div className={css(styles.passwordHintContainer)}>
          <CheckMark
            className={
              hasPasswordAtLeastOneUpperCase
                ? css(styles.checkMarkIconFulfilled)
                : css(styles.checkMarkIcon)
            }
          />
          <span className={css(styles.passwordHintText)}>
            {t('PASSWORD_POLICY.UPPER_CASE')}
          </span>
        </div>
        <div className={css(styles.passwordHintContainer)}>
          <CheckMark
            className={
              hasPasswordAtLeastOneLowerCase
                ? css(styles.checkMarkIconFulfilled)
                : css(styles.checkMarkIcon)
            }
          />
          <span className={css(styles.passwordHintText)}>
            {t('PASSWORD_POLICY.LOWER_CASE')}
          </span>
        </div>

        <div className={css(styles.spacing)} />
        <Button
          className={css(styles.primaryButton)}
          variant="contained"
          disabled={buttonDisabled || sendingCreatingAccountRequest}
          onClick={() => onClickMainButton()}
          color="primary"
        >
          {sendingCreatingAccountRequest && (
            <CircularProgress color={'secondary'} size={22} />
          )}
          {!sendingCreatingAccountRequest && t('REGISTER.BUTTON_REGISTER')}
        </Button>
        <div className={css(styles.spacing)} />
        <span className={css(styles.termsText)}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('REGISTER.TERMS_TEXT', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </span>
      </MuiThemeProvider>
      <div className={css(styles.spacing)} />
      <span className={css(styles.text)}>{info}</span>
      <span className={css(styles.errorText)}>{errorInfo}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: SMALL_INPUT_MAX_WIDTH_CONTENT,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    width: '-webkit-fill-available',
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 35,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  errorText: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
    color: TEXT_COLOR_ERROR,
  },
  termsText: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
    fontSize: TEXT_SIZE_VERY_SMALL,
  },
  passwordHintContainer: {
    flexDirection: 'row',
  },
  passwordHintText: {
    ...defaultStyles.textStyle,
    verticalAlign: 'super',
    fontSize: TEXT_SIZE_SMALL,
    marginLeft: 8,
  },
  checkMarkIcon: {
    color: SECONDARY_BUTTON_COLOR,
  },
  checkMarkIconFulfilled: {
    color: ACCENT_COLOR,
  },
});
