import React, { useState, useEffect } from 'react';
import { EventBus } from 'ts-bus';
import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import { css } from 'aphrodite';
import { UserSettings } from '../../server/model/user/UserSettings';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import { GetInvitedPropertiesResponse } from '../../server/responses/GetInvitedPropertiesResponse';
import { useTranslation } from 'react-i18next';
import { fetchBackend } from '../../authentication';
import { propertiesStyles } from './PropertiesComponent';
import { materialUiTheme } from '../../styling/styles';
import {
  centerBoundingBoxEvent,
  drawPropertiesEvent,
  clearMapEvent,
} from '../map/MapComponent';
import PropertiesListComponent from './PropertiesListComponent';
import { AppComponentsProps } from '../../util/AppComponentsProps';
import { sortPropertiesbyGroup } from '../../util/PropertyUtil';
import { Property } from '../../server/model/property/Property';
import ShowSingleInvitedPropertyComponent from './detailviews/ShowSingleInvitedPropertyComponent';
import { AccountLimit } from '../../server/responses/AccountLimit';

export type ShowInvitedPropertiesListComponentProps = {
  userSettings: UserSettings;
  owner: boolean;
  eventBus: EventBus;
  goBack: () => void;
  mobileScreen: boolean;
  appComponentsProps: AppComponentsProps;
};

export default function ShowInvitedPropertiesListComponent(
  showInvitedPropertiesListComponentProps: ShowInvitedPropertiesListComponentProps
) {
  const [accountLimit, setAccountLimit] = useState<AccountLimit | undefined>(
    undefined
  );
  const [
    getInvitedPropertiesResponse,
    setGetInvitedPropertiesResponse,
  ] = useState<GetInvitedPropertiesResponse | undefined>(undefined);
  const [
    isSendingInvitationAnswerRequest,
    setIsSendingInvitationAnswerRequest,
  ] = useState<boolean>(false);
  const [
    showSingleInvitedPropertyOpened,
    setShowSingleInvitedPropertyOpened,
  ] = useState<Property | undefined>(undefined);

  const { t } = useTranslation();

  async function sendPropertyInvitationAnswerRequest(
    property: Property,
    acceptInvite: boolean
  ) {
    setIsSendingInvitationAnswerRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        propertyId: property.id,
        accept: acceptInvite,
      }),
    };
    await fetchBackend('secure/property/inviteAnswer', requestOptions);

    // Reload invited properties afterwards
    showInvitedPropertiesListComponentProps.eventBus.publish(clearMapEvent({}));
    setAccountLimit(undefined);
    setGetInvitedPropertiesResponse(undefined);
    setIsSendingInvitationAnswerRequest(false);
  }

  function goBack() {
    showInvitedPropertiesListComponentProps.eventBus.publish(clearMapEvent({}));
    showInvitedPropertiesListComponentProps.goBack();
  }

  useEffect(() => {
    const getAccountLimitResponse = async function () {
      const accountLimitResponse = await fetchBackend('secure/account/limit');
      setAccountLimit(accountLimitResponse);
    };
    if (accountLimit === undefined) getAccountLimitResponse();
  }, [accountLimit]);

  useEffect(() => {
    const getGetInvitedPropertiesResponse = async function () {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          owner: showInvitedPropertiesListComponentProps.owner,
        }),
      };
      const invitedPropertiesResponse = await fetchBackend(
        'secure/property/getInvitedProperties',
        requestOptions
      );

      if (invitedPropertiesResponse.success === true) {
        setGetInvitedPropertiesResponse(invitedPropertiesResponse);
      }
    };

    if (getInvitedPropertiesResponse === undefined)
      getGetInvitedPropertiesResponse();
  }, [
    getInvitedPropertiesResponse,
    showInvitedPropertiesListComponentProps.owner,
  ]);

  useEffect(() => {
    // Update the map-data only if we have new invited properties loaded
    if (getInvitedPropertiesResponse) {
      if (
        getInvitedPropertiesResponse.boundingBoxUpperLeft &&
        getInvitedPropertiesResponse.boundingBoxLowerRight
      ) {
        showInvitedPropertiesListComponentProps.eventBus.publish(
          centerBoundingBoxEvent({
            switchToMapViewOnMobileDevice: false,
            upperLeft: getInvitedPropertiesResponse.boundingBoxUpperLeft,
            lowerRight: getInvitedPropertiesResponse.boundingBoxLowerRight,
          })
        );
      }

      // get invited properties and generate properties for drawing on map
      const sortedPropertiesWithGroups = sortPropertiesbyGroup(
        getInvitedPropertiesResponse.properties,
        false,
        undefined
      );

      showInvitedPropertiesListComponentProps.eventBus.publish(
        drawPropertiesEvent({
          propertiesToDraw: sortedPropertiesWithGroups.propertiesToDraw,
        })
      );
    }
  }, [
    getInvitedPropertiesResponse,
    showInvitedPropertiesListComponentProps.eventBus,
  ]);

  if (
    accountLimit === undefined ||
    getInvitedPropertiesResponse === undefined ||
    isSendingInvitationAnswerRequest
  ) {
    return (
      <MuiThemeProvider theme={materialUiTheme}>
        <div className={css(propertiesStyles.loadingIconContainer)}>
          <CircularProgress color={'primary'} size={30} />
        </div>
      </MuiThemeProvider>
    );
  }

  if (showSingleInvitedPropertyOpened) {
    return (
      <ShowSingleInvitedPropertyComponent
        property={showSingleInvitedPropertyOpened}
        owner={showInvitedPropertiesListComponentProps.owner}
        eventBus={showInvitedPropertiesListComponentProps.eventBus}
        userSettings={showInvitedPropertiesListComponentProps.userSettings}
        goBack={() => {
          // Reload invited properties
          showInvitedPropertiesListComponentProps.eventBus.publish(
            clearMapEvent({})
          );
          setShowSingleInvitedPropertyOpened(undefined);
          setAccountLimit(undefined);
          setGetInvitedPropertiesResponse(undefined);
          setIsSendingInvitationAnswerRequest(false);
        }}
      />
    );
  }

  const headerText = showInvitedPropertiesListComponentProps.owner
    ? t('PROPERTIES.INVITED_PROPERTIES.HEADER_OWNER')
    : t('PROPERTIES.INVITED_PROPERTIES.HEADER_RENTER');

  const sortedPropertiesWithGroups = sortPropertiesbyGroup(
    getInvitedPropertiesResponse.properties,
    false,
    undefined
  );

  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            goBack();
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <PropertiesListComponent
        nonUsablePropertiesAmount={
          getInvitedPropertiesResponse.nonUsablePropertiesAmount
        }
        canAddMoreProperties={accountLimit.canAddMoreProperties}
        isInvitedProperties={true}
        owner={showInvitedPropertiesListComponentProps.owner}
        eventBus={showInvitedPropertiesListComponentProps.eventBus}
        groupsWithProperties={sortedPropertiesWithGroups.groups}
        showGroups={false}
        preferredAreaType={
          showInvitedPropertiesListComponentProps.userSettings.preferredAreaType
        }
        appComponentsProps={
          showInvitedPropertiesListComponentProps.appComponentsProps
        }
        clickedShowSingleInvitedProperty={(property: Property) => {
          setShowSingleInvitedPropertyOpened(property);
        }}
        clickedAcceptInvitedProperty={(property: Property) => {
          sendPropertyInvitationAnswerRequest(property, true);
        }}
        clickedDeclineInvitedProperty={(property: Property) => {
          sendPropertyInvitationAnswerRequest(property, false);
        }}
      />
    </div>
  );
}
