import React from 'react';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { css, StyleSheet } from 'aphrodite';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../../styling/colors';
import { defaultStyles } from '../../../styling/styles';
import { TEXT_SIZE_NORMAL } from '../../../styling/dimens';

export type AddUserComponentProps = {
  owner: boolean;
  clickedAddUser: () => void;
};

export default function AddUserComponent(
  addUserComponentProps: AddUserComponentProps
) {
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.caption)}>
        {addUserComponentProps.owner
          ? t('PROPERTIES.ADD_PROPERTY.INFO_ADD_NEW_OWNER')
          : t('PROPERTIES.ADD_PROPERTY.INFO_ADD_NEW_RENTER')}
      </span>
      <span
        className={css(styles.button)}
        onClick={() => {
          addUserComponentProps.clickedAddUser();
        }}
      >
        <AddCircleOutlineOutlinedIcon className={css(styles.buttonStyle)} />
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    marginBottom: 5,
    height: 48,
    display: 'flex',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_NORMAL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    width: 'calc(85% - 5px)',
    alignSelf: 'center',
    paddingLeft: 5,
  },
  button: {
    height: 48,
    marginLeft: '5%',
    width: '10%',
    float: 'right',
  },
  buttonStyle: {
    marginTop: 12,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    height: 24,
    width: 24,
  },
});
