import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { EventBus, createEventDefinition } from 'ts-bus';
import { LatLng } from '../../server/model/property/LatLng';
import { PropertyToDraw } from './model/PropertyToDraw';
import {
  updateMarkers,
  removeCachedMarkerAndClusters,
} from './MapClusterHelper';
import {
  MAX_WIDTH_PROPERTIES_LIST,
  MAX_WIDTH_MOBILE_PHONE,
  AppComponentsProps,
} from '../../util/AppComponentsProps';
import { Property } from '../../server/model/property/Property';

const MAP_PADDING = 60;
const MAP_POLYGON_OPACITY = 0.7;

export type MapComponentProps = {
  hidden: boolean;
  eventBus: EventBus;
  appComponentsProps: AppComponentsProps;
};

let subscribedEvents: { (): void }[] = [];

let drawPolygonControl: any = undefined;
let addedMapLayers: string[] = [];
let addedMapSources: string[] = [];
export let lastUsedPropertyMarkersSourceId: string = '';

export default function MapComponent(mapComponentProps: MapComponentProps) {
  const [map, setMap] = useState<mapboxgl.Map | undefined>(undefined);
  const mapContainer = useRef(null);

  const mobileScreen =
    mapComponentProps.appComponentsProps.windowWidth <= MAX_WIDTH_MOBILE_PHONE;

  useEffect(() => {
    // Workaround for this issue: https://github.com/alex3165/react-mapbox-gl/issues/931
    // @ts-ignore
    // eslint-disable-next-line import/no-webpack-loader-syntax
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
    mapboxgl.accessToken =
      'pk.eyJ1IjoicmVudGQiLCJhIjoiY2s5azE4bG4zMDNicDNrcmRuaGszcHRpciJ9.ySMFgb0u9yO6la9i6xRRrQ';
    const initializedMap = new mapboxgl.Map({
      container: mapContainer.current!! as string,
      attributionControl: false,
      style: 'mapbox://styles/rentd/ckbdxnsvq2mox1inncrxo7hl6',
      center: { lng: 10.447683, lat: 51.163361 },
      zoom: 5,
    });
    initializedMap.addControl(new mapboxgl.NavigationControl());
    initializedMap.addControl(new mapboxgl.GeolocateControl());

    initializedMap.on('load', () => {
      initializedMap.resize();
    });

    setMap(initializedMap);
  }, []);

  useEffect(() => {
    if (!map) return;

    // disable map rotation using right click + drag
    map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    // Register listener for drawing polygons
    map.on('draw.create', updateArea);
    map.on('draw.update', updateArea);

    map.on('draw.modechange', (event) => {
      if (drawPolygonControl === undefined) return;

      // Restart drawing if user only has drawn a point or a line
      if (event.mode === 'simple_select') {
        const polygonData = drawPolygonControl.getAll().features;
        if (polygonData.length === 0) {
          mapComponentProps.eventBus.publish(restartDrawPropertyEvent({}));
        }
      }
    });

    function updateArea() {
      if (drawPolygonControl === undefined) return;

      const polygonData = drawPolygonControl.getAll().features[0].geometry
        .coordinates;
      const coordinates = polygonData[0].map((singlePolygonData: number[]) => {
        return {
          longitude: singlePolygonData[0],
          latitude: singlePolygonData[1],
        };
      });
      // Remove last coordinate, because this is always the same as the first coordinate
      coordinates.pop();
      mapComponentProps.eventBus.publish(polygonDrawnEvent({ coordinates }));
    }

    // Register listener for drawing marker and cluster
    map.on('data', function (event) {
      if (
        event.sourceId !== lastUsedPropertyMarkersSourceId ||
        !event.isSourceLoaded
      )
        return;
      updateMarkers(map, mapboxgl, mapComponentProps.eventBus);
    });
    map.on('move', () =>
      updateMarkers(map, mapboxgl, mapComponentProps.eventBus)
    );
    map.on('moveend', () =>
      updateMarkers(map, mapboxgl, mapComponentProps.eventBus)
    );
  }, [map, mapComponentProps.eventBus]);

  useEffect(() => {
    if (!map) return;

    function isMapFullyLoaded() {
      return map && map.isStyleLoaded();
    }

    function sendEventAgainIfMapLoaded(event: {
      type:
        | 'centerBoundingBox'
        | 'drawProperties'
        | 'clearMap'
        | 'restartDrawProperty'
        | 'clearDrawPolygonMap'
        | 'drawPropertyWithoutMarker'
        | 'startDrawPropertyWithPolygon';
      payload:
        | { upperLeft: LatLng; lowerRight: LatLng }
        | { propertiesToDraw: PropertyToDraw[] }
        | {};
    }) {
      // Wait 10ms and check again, if event can be executed
      setTimeout(function () {
        if (isMapFullyLoaded()) {
          mapComponentProps.eventBus.publish(event);
        } else {
          sendEventAgainIfMapLoaded(event);
        }
      }, 10);
    }

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(clearMapEvent, (event) => {
        if (!isMapFullyLoaded()) {
          sendEventAgainIfMapLoaded(event);
          return;
        }

        try {
          if (map.getLayer('clusterDummyLayer')) {
            map.removeLayer('clusterDummyLayer');
          }
          map.removeSource(lastUsedPropertyMarkersSourceId);
          removeCachedMarkerAndClusters();

          // Remove the old polygons
          addedMapLayers.forEach((layerId) => map.removeLayer(layerId));
          addedMapLayers = [];
          addedMapSources.forEach((sourceId) => map.removeSource(sourceId));
          addedMapSources = [];
        } catch (_ignore) {
        } finally {
        }
      })
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(
        clearDrawPolygonMapEvent,
        (event) => {
          if (!isMapFullyLoaded()) {
            sendEventAgainIfMapLoaded(event);
            return;
          }

          if (drawPolygonControl !== undefined) {
            map.removeControl(drawPolygonControl);
            drawPolygonControl = undefined;
          }
        }
      )
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(
        restartDrawPropertyEvent,
        (event) => {
          if (!isMapFullyLoaded()) {
            sendEventAgainIfMapLoaded(event);
            return;
          }

          if (drawPolygonControl !== undefined) {
            map.removeControl(drawPolygonControl);
            drawPolygonControl = undefined;
          }

          drawPolygonControl = new MapboxDraw({
            displayControlsDefault: false,
            defaultMode: 'draw_polygon',
            controls: {
              polygon: false,
              trash: false,
            },
          });
          map.addControl(drawPolygonControl);
        }
      )
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(
        startDrawPropertyWithPolygonEvent,
        (event) => {
          if (!isMapFullyLoaded()) {
            sendEventAgainIfMapLoaded(event);
            return;
          }

          const { polygon } = event.payload;
          const mapboxCompliantPolygon = polygon;
          mapboxCompliantPolygon.push(mapboxCompliantPolygon[0]);
          const coordinates = mapboxCompliantPolygon.map((latLng) => [
            latLng.longitude,
            latLng.latitude,
          ]);
          var feature = { type: 'Polygon', coordinates: [coordinates] };

          // Reset the draw-stuff and draw directly the given polygon
          if (drawPolygonControl !== undefined) {
            map.removeControl(drawPolygonControl);
            drawPolygonControl = undefined;
          }
          drawPolygonControl = new MapboxDraw({
            displayControlsDefault: false,
            defaultMode: 'simple_select',
            controls: {
              polygon: false,
              trash: false,
            },
          });
          map.addControl(drawPolygonControl);
          drawPolygonControl.add(feature);
        }
      )
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(centerBoundingBoxEvent, (event) => {
        if (!isMapFullyLoaded()) {
          sendEventAgainIfMapLoaded(event);
          return;
        }

        const { lowerRight, upperLeft } = event.payload;
        map.fitBounds(
          [
            [upperLeft.longitude, upperLeft.latitude],
            [lowerRight.longitude, lowerRight.latitude],
          ],
          { padding: MAP_PADDING }
        );
      })
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(drawPropertiesEvent, (event) => {
        const { propertiesToDraw } = event.payload;
        if (propertiesToDraw.length === 0) return;

        if (!isMapFullyLoaded()) {
          sendEventAgainIfMapLoaded(event);
          return;
        }

        // Prepare the data for showing marker and clusters
        const groupIds: number[] = [];
        const features = propertiesToDraw.map((propertyToDraw) => {
          if (!groupIds[propertyToDraw.groupId])
            groupIds.push(propertyToDraw.groupId);

          const coordinates = [
            propertyToDraw.property.center.longitude,
            propertyToDraw.property.center.latitude,
          ];
          return {
            type: 'Feature' as const,
            properties: {
              classId: propertyToDraw.property.id,
              id: propertyToDraw.property.id,
              color: propertyToDraw.color,
              userVisibleNumber: propertyToDraw.userVisibleNumber,
              groupId: propertyToDraw.groupId,
            },
            geometry: { type: 'Point' as const, coordinates },
          };
        });

        try {
          map.removeSource(lastUsedPropertyMarkersSourceId);
          map.removeLayer('clusterDummyLayer');
        } catch (_ignore) {
        } finally {
        }

        // we need to use a new id for source for every new datasource-set
        lastUsedPropertyMarkersSourceId = 'propertyMarker' + Math.random();
        map.addSource(lastUsedPropertyMarkersSourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features,
          },
          cluster: true,
          clusterRadius: 40,
        });
        // Following layer is only a dummer layer, but needed to start clustering
        map.addLayer({
          id: 'clusterDummyLayer',
          type: 'circle',
          source: lastUsedPropertyMarkersSourceId,
          filter: ['==', 'NOT_USED', true],
        });

        // Remove the old polygons
        try {
          addedMapLayers.forEach((layerId) => map.removeLayer(layerId));
          addedMapSources.forEach((sourceId) => map.removeSource(sourceId));
        } catch (_ignore) {
        } finally {
        }
        addedMapLayers = [];
        addedMapSources = [];

        // Draw the polygons of the properties
        propertiesToDraw.forEach((propertyToDraw) => {
          const id = propertyToDraw.property.id.toString();
          const coordinates = propertyToDraw.property.coordinates.map(
            (latLng) => [latLng.longitude, latLng.latitude]
          );
          addedMapSources.push(id);
          addedMapLayers.push(id);

          map.addSource(id, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: { classId: 1 },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          });

          map.addLayer({
            id: id,
            type: 'fill',
            source: id,
            layout: {},
            paint: {
              'fill-color': propertyToDraw.color,
              'fill-opacity': MAP_POLYGON_OPACITY,
            },
          });
        });
      })
    );

    subscribedEvents.push(
      mapComponentProps.eventBus.subscribe(
        drawPropertyWithoutMarkerEvent,
        (event) => {
          const { property, color } = event.payload;

          if (!isMapFullyLoaded()) {
            sendEventAgainIfMapLoaded(event);
            return;
          }

          // Remove the old polygons
          addedMapLayers.forEach((layerId) => map.removeLayer(layerId));
          addedMapLayers = [];
          addedMapSources.forEach((sourceId) => map.removeSource(sourceId));
          addedMapSources = [];

          // Draw the polygon of the property
          const id = property.id.toString();
          const coordinates = property.coordinates.map((latLng) => [
            latLng.longitude,
            latLng.latitude,
          ]);
          addedMapSources.push(id);
          addedMapLayers.push(id);

          map.addSource(id, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: { classId: 1 },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          });

          map.addLayer({
            id: id,
            type: 'fill',
            source: id,
            layout: {},
            paint: {
              'fill-color': color,
              'fill-opacity': MAP_POLYGON_OPACITY,
            },
          });
        }
      )
    );

    return () => {
      subscribedEvents.forEach((subscribedEvent) => subscribedEvent());
      subscribedEvents = [];
    };
  }, [map, mapComponentProps.eventBus]);

  const height = mobileScreen
    ? 'calc(100% - 98px)' // Only minus header and tabview-height, rest of height can be used
    : 'calc(100% - 148px)'; // Minus Header and Footer (Desktop)
  return (
    <div
      ref={mapContainer}
      className={
        mapComponentProps.hidden
          ? css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.mobileContainer,
                  visibility: 'hidden',
                  height: height,
                  position: 'absolute',
                  overflow: 'hidden',
                },
              }).style
            )
          : mobileScreen
          ? css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.mobileContainer,
                  height: height,
                  position: 'absolute',
                  overflow: 'hidden',
                },
              }).style
            )
          : css(
              StyleSheet.create({
                style: {
                  ...runtimeStyles.container,
                  position: 'absolute',
                  height: height,
                },
              }).style
            )
      }
    />
  );
}

const runtimeStyles = {
  container: {
    position: 'absolute',
    width: 'calc(100% - ' + MAX_WIDTH_PROPERTIES_LIST + 'px)',
    right: 0,
    top: 50,
  },
  mobileContainer: {
    position: 'absolute',
    top: 98,
    width: '100%',
    overflow: 'hidden',
  },
};

// Input events
export const centerBoundingBoxEvent = createEventDefinition<{
  switchToMapViewOnMobileDevice: boolean;
  upperLeft: LatLng;
  lowerRight: LatLng;
}>()('centerBoundingBox');

export const drawPropertiesEvent = createEventDefinition<{
  propertiesToDraw: PropertyToDraw[];
}>()('drawProperties');

export const drawPropertyWithoutMarkerEvent = createEventDefinition<{
  property: Property;
  color: string;
}>()('drawPropertyWithoutMarker');

export const clearMapEvent = createEventDefinition<{}>()('clearMap');

export const clearDrawPolygonMapEvent = createEventDefinition<{}>()(
  'clearDrawPolygonMap'
);

export const restartDrawPropertyEvent = createEventDefinition<{}>()(
  'restartDrawProperty'
);

export const startDrawPropertyWithPolygonEvent = createEventDefinition<{
  polygon: LatLng[];
}>()('startDrawPropertyWithPolygon');

// Output events
export const markerClickedEvent = createEventDefinition<{
  lastClickedPropertyId: number | undefined;
  propertyId: number;
}>()('markerClicked');

export const polygonDrawnEvent = createEventDefinition<{
  coordinates: LatLng[];
}>()('polygonDrawn');

// Other events
export const switchToMapTabEvent = createEventDefinition<{}>()(
  'switchToMapTab'
);
