import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';
import { propertiesStyles } from '../PropertiesComponent';
import {
  clearMapEvent,
  drawPropertyWithoutMarkerEvent,
  switchToMapTabEvent,
} from '../../map/MapComponent';
import { EventBus } from 'ts-bus';
import { UserSettings } from '../../../server/model/user/UserSettings';
import {
  MuiThemeProvider,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { materialUiTheme, defaultStyles } from '../../../styling/styles';
import {
  SECONDARY_TEXT_COLOR_ON_WHITE,
  SECONDARY_BUTTON_COLOR,
} from '../../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../../styling/dimens';
import {
  Property,
  getReadableUserGivenSize,
  getReadableCalculatedSize,
  getReadableCreatedDate,
  getReadableLastChangedDate,
} from '../../../server/model/property/Property';
import { centerBoundingBox } from '../PropertiesListComponent';
import InvitedUserComponent from '../invitations/InvitedUserComponent';
import { delay } from '../../../util/DelayUtil';
import { SHOW_ERROR_INFO_TO_USER } from '../../../util/AppComponentsProps';
import { fetchBackend } from '../../../authentication';

export type SingleNonEditablePropertyDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
  groupColor: string;
  mobileScreen: boolean;
};

export default function SingleNonEditablePropertyDetailComponent(
  singleNonEditablePropertyDetailComponentProps: SingleNonEditablePropertyDetailComponentProps
) {
  const [info, setInfo] = useState<string>('');
  const [removePropertyDialogOpen, setRemovePropertyDialogOpen] = useState(
    false
  );
  const [
    sendingRemovePropertyRequest,
    setSendingRemovePropertyRequest,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClickRemovePropertyOpenDialog = () => {
    setRemovePropertyDialogOpen(true);
  };

  const handleCloseRemovePropertyCloseDialog = () => {
    setRemovePropertyDialogOpen(false);
  };

  function goBack() {
    singleNonEditablePropertyDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    singleNonEditablePropertyDetailComponentProps.goBack();
  }

  async function sendPropertyRemoveRequest() {
    setSendingRemovePropertyRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        id: singleNonEditablePropertyDetailComponentProps.property.id,
      }),
    };
    const response = await fetchBackend(
      'secure/property/deleteProperty',
      requestOptions
    );

    if (response.success === false) {
      setSendingRemovePropertyRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and go back after a few seconds
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      goBack();
    }
  }

  // At start clear once the map
  useEffect(() => {
    singleNonEditablePropertyDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    singleNonEditablePropertyDetailComponentProps.eventBus.publish(
      drawPropertyWithoutMarkerEvent({
        property: singleNonEditablePropertyDetailComponentProps.property,
        color: singleNonEditablePropertyDetailComponentProps.groupColor,
      })
    );
    centerBoundingBox(
      singleNonEditablePropertyDetailComponentProps.property,
      singleNonEditablePropertyDetailComponentProps.eventBus,
      false
    );
  }, [
    singleNonEditablePropertyDetailComponentProps.eventBus,
    singleNonEditablePropertyDetailComponentProps.property,
    singleNonEditablePropertyDetailComponentProps.groupColor,
  ]);

  const headerText = singleNonEditablePropertyDetailComponentProps.owner
    ? t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.HEADER_OWNER')
    : t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.HEADER_RENTER');

  const verifiedUsersSection =
    singleNonEditablePropertyDetailComponentProps.property
      .otherVerifiedOwnerUserMailAddresses.length > 0 ||
    singleNonEditablePropertyDetailComponentProps.property
      .otherVerifiedRenterUserMailAddresses.length > 0 ? (
      <div>
        <div className={css(styles.bigSpacing)} />
        <div className={css(propertiesStyles.sectionSubView)}>
          {t(
            'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_OTHER_USERS'
          )}
        </div>
        <div className={css(styles.smallSpacing)} />
        {singleNonEditablePropertyDetailComponentProps.property.otherVerifiedOwnerUserMailAddresses.map(
          (verifiedAddress: string, index: number) => {
            return (
              <div key={index}>
                <InvitedUserComponent
                  value={verifiedAddress}
                  owner={true}
                  editEnabled={false}
                  onlyRemovePossible={false}
                />
              </div>
            );
          }
        )}
        {singleNonEditablePropertyDetailComponentProps.property.otherVerifiedRenterUserMailAddresses.map(
          (verifiedAddress: string, index: number) => {
            return (
              <div key={index}>
                <InvitedUserComponent
                  value={verifiedAddress}
                  owner={false}
                  editEnabled={false}
                  onlyRemovePossible={false}
                />
              </div>
            );
          }
        )}
      </div>
    ) : null;

  const invitedUsersSection =
    singleNonEditablePropertyDetailComponentProps.property
      .otherInvitedOwnerUserMailAddresses.length > 0 ||
    singleNonEditablePropertyDetailComponentProps.property
      .otherInvitedRenterUserMailAddresses.length > 0 ? (
      <div>
        <div className={css(styles.bigSpacing)} />
        <div className={css(propertiesStyles.sectionSubView)}>
          {t(
            'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_OTHER_INVITED_USERS'
          )}
        </div>
        <div className={css(styles.smallSpacing)} />
        {singleNonEditablePropertyDetailComponentProps.property.otherInvitedOwnerUserMailAddresses.map(
          (verifiedAddress: string, index: number) => {
            return (
              <div key={index}>
                <InvitedUserComponent
                  value={verifiedAddress}
                  owner={true}
                  editEnabled={false}
                  onlyRemovePossible={false}
                />
              </div>
            );
          }
        )}
        {singleNonEditablePropertyDetailComponentProps.property.otherInvitedRenterUserMailAddresses.map(
          (verifiedAddress: string, index: number) => {
            return (
              <div key={index}>
                <InvitedUserComponent
                  value={verifiedAddress}
                  owner={false}
                  editEnabled={false}
                  onlyRemovePossible={false}
                />
              </div>
            );
          }
        )}
      </div>
    ) : null;

  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            goBack();
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.HINT_NO_CHANGE_POSSIBLE'
                ),
              }}
              className={css(styles.caption)}
            />
            <div className={css(styles.spacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_COMMON_DATA'
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.TITLE')}
              defaultValue={
                singleNonEditablePropertyDetailComponentProps.property.title
              }
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.OFFICIAL_DATA'
              )}
              defaultValue={
                singleNonEditablePropertyDetailComponentProps.property
                  .officialData
              }
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.ADDITIONAL_INFO'
              )}
              defaultValue={
                singleNonEditablePropertyDetailComponentProps.property
                  .additionalInfo
              }
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SIZE')}
              defaultValue={getReadableUserGivenSize(
                singleNonEditablePropertyDetailComponentProps.property,
                singleNonEditablePropertyDetailComponentProps.userSettings
                  .preferredAreaType,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.CALCULATED_SIZE'
              )}
              defaultValue={getReadableCalculatedSize(
                singleNonEditablePropertyDetailComponentProps.property,
                singleNonEditablePropertyDetailComponentProps.userSettings
                  .preferredAreaType,
                t
              )}
            />
            <div className={css(styles.bigSpacing)} />

            {singleNonEditablePropertyDetailComponentProps.mobileScreen ? (
              <div>
                <div className={css(propertiesStyles.sectionSubView)}>
                  {t(
                    'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_MAP_MOBILE'
                  )}
                </div>
                <div className={css(styles.smallSpacing)} />
                <Button
                  className={css(styles.smallButton)}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    singleNonEditablePropertyDetailComponentProps.eventBus.publish(
                      switchToMapTabEvent({})
                    );
                  }}
                >
                  {t(
                    'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.CHANGE_MAP_BUTTON'
                  )}
                </Button>
                <div className={css(styles.spacing)} />
                <div className={css(styles.bigSpacing)} />
              </div>
            ) : null}

            <div className={css(propertiesStyles.sectionSubView)}>
              {t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_DATES')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.CREATION_DATE'
              )}
              defaultValue={getReadableCreatedDate(
                singleNonEditablePropertyDetailComponentProps.property,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.LAST_CHANGED_DATE'
              )}
              defaultValue={getReadableLastChangedDate(
                singleNonEditablePropertyDetailComponentProps.property,
                t
              )}
            />
            {verifiedUsersSection}
            {invitedUsersSection}

            <div className={css(styles.bigSpacing)} />
            <div className={css(styles.infoText)}>{info}</div>
            <div className={css(styles.smallSpacing)} />

            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              onClick={() => {
                if (!sendingRemovePropertyRequest) {
                  handleClickRemovePropertyOpenDialog();
                }
              }}
              color="secondary"
            >
              {sendingRemovePropertyRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingRemovePropertyRequest &&
                t('PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.REMOVE_BUTTON')}
            </Button>

            <div className={css(styles.bottomSpacing)} />
          </MuiThemeProvider>
        </form>
      </div>

      <Dialog
        open={removePropertyDialogOpen}
        onClose={(event: any) => {
          handleCloseRemovePropertyCloseDialog();

          // Don't forward click
          event.stopPropagation();
        }}
        aria-labelledby="removePropertyDialogTitle"
        aria-describedby="removePropertyDialogText"
      >
        <DialogTitle id="removePropertyDialogTitle">
          {t(
            'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.REMOVE_PROPERTY_DIALOG_TITLE'
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="removePropertyDialogText">
            {t(
              'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.REMOVE_PROPERTY_DIALOG_TEXT'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleCloseRemovePropertyCloseDialog();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
          >
            {t('GLOBAL.NO')}
          </Button>
          <Button
            onClick={(event) => {
              handleCloseRemovePropertyCloseDialog();
              sendPropertyRemoveRequest();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
            autoFocus
          >
            {t('GLOBAL.YES')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = StyleSheet.create({
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 70,
  },
  bottomSpacing: {
    height: 50,
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    background: SECONDARY_BUTTON_COLOR,
    float: 'left',
    marginLeft: 15,
  },
  smallButton: {
    ...defaultStyles.materialSmallButtonStyle,
    fontWeight: 'bold',
    float: 'left',
  },
});
