import { PaymentMethod, PaymentProvider } from './Subscription';
import i18next, { TFunction } from 'i18next';

export type Payment = {
  id: number;
  amount: number;
  taxAmount: number;
  currency: string;
  paymentMethod: PaymentMethod;
  provider: PaymentProvider;
  date: string;
};

export function getReadableDate(payment: Payment) {
  const date = new Date(Date.parse(payment.date));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getReadableDateRange(payment: Payment) {
  const fromDate = new Date(Date.parse(payment.date));
  let untilDate = new Date(fromDate);
  untilDate.setDate(untilDate.getDate() + 364);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    fromDate.toLocaleDateString(i18next.language, options) +
    ' - ' +
    untilDate.toLocaleDateString(i18next.language, options)
  );
}

function getHumanReadableProvider(t: TFunction, payment: Payment) {
  switch (payment.provider) {
    case 'PAYPAL':
      return t('PAYMENT_PROVIDER.PAYPAL');
  }
}

export function getHumanReadableProviderWithPaymentMethod(
  t: TFunction,
  payment: Payment
) {
  const provider = getHumanReadableProvider(t, payment);

  switch (payment.paymentMethod) {
    case 'NONE':
      return provider;
    case 'CREDIT_CARD':
      return t('PAYMENT_METHOD.CREDIT_CARD') + ' (via ' + provider + ')';
    case 'SEPA':
      return t('PAYMENT_METHOD.SEPA') + ' (via ' + provider + ')';
    case 'PAYPAL':
      return t('PAYMENT_METHOD.PAYPAL') + ' (via ' + provider + ')';
  }
}
