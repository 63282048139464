import React from 'react';

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../../styling/colors';

export type InvitedUserComponentProps = {
  value: string;
  owner: boolean;
  editEnabled: boolean;
  onlyRemovePossible: boolean;
  onValueChanged?: (newValue: string) => void;
  clickedRemoveUser?: () => void;
};

export default function InvitedUserComponent(
  invitedUserComponentProps: InvitedUserComponentProps
) {
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <TextField
        disabled={
          !invitedUserComponentProps.editEnabled ||
          invitedUserComponentProps.onlyRemovePossible
        }
        className={css(
          invitedUserComponentProps.editEnabled ||
            invitedUserComponentProps.onlyRemovePossible
            ? styles.textField
            : styles.textFieldFullWidth
        )}
        onChange={(input) => {
          const value = input.target.value;

          if (invitedUserComponentProps.onValueChanged) {
            invitedUserComponentProps.onValueChanged(value);
          }
        }}
        value={invitedUserComponentProps.value}
        label={
          invitedUserComponentProps.owner
            ? t('PROPERTIES.ADD_PROPERTY.PLACEHOLDR_OWNER_MAIL_ADDRESS')
            : t('PROPERTIES.ADD_PROPERTY.PLACEHOLDR_RENTER_MAIL_ADDRESS')
        }
      />
      {(invitedUserComponentProps.editEnabled ||
        invitedUserComponentProps.onlyRemovePossible) && (
        <span
          className={css(styles.button)}
          onClick={() => {
            if (invitedUserComponentProps.clickedRemoveUser) {
              invitedUserComponentProps.clickedRemoveUser();
            }
          }}
        >
          <RemoveCircleOutlineOutlinedIcon
            className={css(styles.buttonStyle)}
          />
        </span>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    marginBotton: 5,
    height: 48,
    display: 'flex',
  },
  textField: {
    width: '85%',
    alignSelf: 'center',
  },
  textFieldFullWidth: {
    width: '100%',
    alignSelf: 'center',
  },
  button: {
    height: 48,
    marginLeft: '5%',
    width: '10%',
    float: 'right',
  },
  buttonStyle: {
    marginTop: 12,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    height: 24,
    width: 24,
  },
});
