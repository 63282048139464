import i18next, { TFunction } from 'i18next';

export type SubscriptionStatus =
  | 'PENDING'
  | 'ACTIVE'
  | 'PAUSED'
  | 'CANCELLED'
  | 'FAILURE'
  | 'INACTIVE';
export type PaymentProvider = 'PAYPAL';
export type PaymentMethod = 'NONE' | 'PAYPAL' | 'SEPA' | 'CREDIT_CARD';

export type Subscription = {
  amount: number;
  currency: string;
  paymentMethod: PaymentMethod;
  status: SubscriptionStatus;
  provider: PaymentProvider;
  createdDate: string;
  validUntil: string;
};

export function getReadableCreatedDate(subscription: Subscription) {
  const date = new Date(Date.parse(subscription.createdDate));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getReadableValidUntilDate(subscription: Subscription) {
  const date = new Date(Date.parse(subscription.validUntil));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getHumanReadableProvider(
  t: TFunction,
  subscription: Subscription
) {
  switch (subscription.provider) {
    case 'PAYPAL':
      return t('PAYMENT_PROVIDER.PAYPAL');
  }
}

export function getHumanReadableProviderWithPaymentMethod(
  t: TFunction,
  subscription: Subscription
) {
  const provider = getHumanReadableProvider(t, subscription);

  switch (subscription.paymentMethod) {
    case 'NONE':
      return provider;
    case 'CREDIT_CARD':
      return t('PAYMENT_METHOD.CREDIT_CARD') + ' (via ' + provider + ')';
    case 'SEPA':
      return t('PAYMENT_METHOD.SEPA') + ' (via ' + provider + ')';
    case 'PAYPAL':
      return t('PAYMENT_METHOD.PAYPAL') + ' (via ' + provider + ')';
  }
}
