import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PaymentCancel(_props: any) {
  const { t } = useTranslation();
  return (
    <Redirect
      to={{
        pathname: '/accountpayment',
        state: { infoForUser: t('PAYMENT_PAGE.PAYMENT_CANCEL') },
      }}
    />
  );
}
