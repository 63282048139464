import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';
import { propertiesStyles } from '../PropertiesComponent';
import {
  clearMapEvent,
  restartDrawPropertyEvent,
  clearDrawPolygonMapEvent,
  polygonDrawnEvent,
  centerBoundingBoxEvent,
  startDrawPropertyWithPolygonEvent,
} from '../../map/MapComponent';
import { EventBus } from 'ts-bus';
import { LatLng } from '../../../server/model/property/LatLng';
import { getAreaSizeInGivenAreaType } from '../../../util/MapUtil';
import {
  UserSettings,
  getReadableSize,
  AreaType,
} from '../../../server/model/user/UserSettings';
import {
  MuiThemeProvider,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { materialUiTheme, defaultStyles } from '../../../styling/styles';
import { TEXT_SIZE_SMALL } from '../../../styling/dimens';
import GeocoderAutoComplete, { GeocodeResult } from '../GeocoderAutoComplete';
import AddUserComponent from '../invitations/AddUserComponent';
import {
  Property,
  getReadableCreatedDate,
  getReadableLastChangedDate,
} from '../../../server/model/property/Property';
import InvitedUserComponent from '../invitations/InvitedUserComponent';
import {
  SECONDARY_TEXT_COLOR_ON_WHITE,
  SECONDARY_BUTTON_COLOR,
} from '../../../styling/colors';
import { centerBoundingBox } from '../PropertiesListComponent';
import { fetchBackend } from '../../../authentication';
import { SHOW_ERROR_INFO_TO_USER } from '../../../util/AppComponentsProps';
import { delay } from '../../../util/DelayUtil';

export type SingleEditablePropertyDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
  mobileScreen: boolean;
};

let subscribedEvents: { (): void }[] = [];

export default function SingleEditablePropertyDetailComponent(
  singleEditablePropertyDetailComponentProps: SingleEditablePropertyDetailComponentProps
) {
  const [removePropertyDialogOpen, setRemovePropertyDialogOpen] = useState(
    false
  );
  const [
    sendingRemovePropertyRequest,
    setSendingRemovePropertyRequest,
  ] = useState<boolean>(false);
  const [
    leaveUnsavedChangesDialogOpen,
    setLeaveUnsavedChangesDialogOpen,
  ] = useState(false);

  const [info, setInfo] = useState<string>('');
  const [coordinates, setCoordinates] = useState<LatLng[]>(
    singleEditablePropertyDetailComponentProps.property.coordinates
  );
  const [title, setTitle] = useState<string>(
    singleEditablePropertyDetailComponentProps.property.title
  );
  const [officialData, setOfficialData] = useState<string>(
    singleEditablePropertyDetailComponentProps.property.officialData
      ? singleEditablePropertyDetailComponentProps.property.officialData
      : ''
  );
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    singleEditablePropertyDetailComponentProps.property.additionalInfo
      ? singleEditablePropertyDetailComponentProps.property.additionalInfo
      : ''
  );
  const [usedAreaType, setUsedAreaType] = useState<AreaType>(
    singleEditablePropertyDetailComponentProps.userSettings.preferredAreaType
  );
  const [size, setSize] = useState<number>(
    singleEditablePropertyDetailComponentProps.property.size
  );
  const [verifiedOwnerMailAddresses, setVerifiedOwnerMailAddresses] = useState<
    string[]
  >(
    singleEditablePropertyDetailComponentProps.property
      .otherVerifiedOwnerUserMailAddresses
  );
  const [
    verifiedRenterMailAddresses,
    setVerifiedRenterMailAddresses,
  ] = useState<string[]>(
    singleEditablePropertyDetailComponentProps.property
      .otherVerifiedRenterUserMailAddresses
  );
  const [invitedOwnerMailAddresses, setInvitedOwnerMailAddresses] = useState<
    string[]
  >(
    singleEditablePropertyDetailComponentProps.property
      .otherInvitedOwnerUserMailAddresses
  );
  const [invitedRenterMailAddresses, setInvitedRenterMailAddresses] = useState<
    string[]
  >(
    singleEditablePropertyDetailComponentProps.property
      .otherInvitedRenterUserMailAddresses
  );
  const [invitedInfoText, setInvitedInfoText] = useState<string>('');

  const [
    sendingChangePropertyRequest,
    setSendingChangePropertyRequest,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCloseRemovePropertyCloseDialog = () => {
    setRemovePropertyDialogOpen(false);
  };

  const handleCloseLeaveUnsavedChangesCloseDialog = () => {
    setLeaveUnsavedChangesDialogOpen(false);
  };

  async function sendPropertyRemoveRequest() {
    setSendingRemovePropertyRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        id: singleEditablePropertyDetailComponentProps.property.id,
      }),
    };
    const response = await fetchBackend(
      'secure/property/deleteProperty',
      requestOptions
    );

    if (response.success === false) {
      setSendingRemovePropertyRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and go back after a few seconds
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      goBack();
    }
  }

  function goBack() {
    singleEditablePropertyDetailComponentProps.eventBus.publish(
      clearDrawPolygonMapEvent({})
    );
    singleEditablePropertyDetailComponentProps.goBack();
  }

  async function sendChangePropertyRequest() {
    setSendingChangePropertyRequest(true);

    const property = singleEditablePropertyDetailComponentProps.property;
    let responseText = '';
    let errorOccured = false;

    // Remove verified owner
    const verifiedOwnerAddressesToRemove = getRemovedAddressesOfGivenMailAddressLists(
      property.otherVerifiedOwnerUserMailAddresses,
      verifiedOwnerMailAddresses
    );

    for (const ownerAddressToRemove of verifiedOwnerAddressesToRemove) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          userMailToDelete: ownerAddressToRemove,
        }),
      };
      const response = await fetchBackend(
        'secure/property/deleteUserFromProperty',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Remove verified renter
    const verifiedRenterAddressesToRemove = getRemovedAddressesOfGivenMailAddressLists(
      property.otherVerifiedRenterUserMailAddresses,
      verifiedRenterMailAddresses
    );

    for (const renterAddressToRemove of verifiedRenterAddressesToRemove) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          userMailToDelete: renterAddressToRemove,
        }),
      };
      const response = await fetchBackend(
        'secure/property/deleteUserFromProperty',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Remove invited owner
    const invitedOwnerAddressesToRemove = getRemovedAddressesOfGivenMailAddressLists(
      property.otherInvitedOwnerUserMailAddresses,
      invitedOwnerMailAddresses
    );

    for (const invitedAddressToRemove of invitedOwnerAddressesToRemove) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          invitedUserMailAddress: invitedAddressToRemove,
        }),
      };
      const response = await fetchBackend(
        'secure/property/deleteInvitedUser',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Remove invited renter
    const invitedRenterAddressesToRemove = getRemovedAddressesOfGivenMailAddressLists(
      property.otherInvitedRenterUserMailAddresses,
      invitedRenterMailAddresses
    );

    for (const invitedAddressToRemove of invitedRenterAddressesToRemove) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          invitedUserMailAddress: invitedAddressToRemove,
        }),
      };
      const response = await fetchBackend(
        'secure/property/deleteInvitedUser',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Invite new owner
    const invitedOwnerAddressesToAdd = getRemovedAddressesOfGivenMailAddressLists(
      invitedOwnerMailAddresses,
      property.otherInvitedOwnerUserMailAddresses
    );

    for (const invitedAddressToAdd of invitedOwnerAddressesToAdd) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          invitedMailAddress: invitedAddressToAdd,
          invitedUserIsOwner: true,
          invitedInfoText: invitedInfoText ? invitedInfoText : undefined,
        }),
      };
      const response = await fetchBackend(
        'secure/property/inviteUser',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Invite new renter
    const invitedRenterAddressesToAdd = getRemovedAddressesOfGivenMailAddressLists(
      invitedRenterMailAddresses,
      property.otherInvitedRenterUserMailAddresses
    );

    for (const invitedAddressToAdd of invitedRenterAddressesToAdd) {
      if (errorOccured) return;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          propertyId: property.id,
          invitedMailAddress: invitedAddressToAdd,
          invitedUserIsOwner: false,
          invitedInfoText: invitedInfoText ? invitedInfoText : undefined,
        }),
      };
      const response = await fetchBackend(
        'secure/property/inviteUser',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // Change property if necessary
    const propertyHasChanged =
      property.title !== title ||
      (property.officialData
        ? property.officialData !== officialData
        : officialData.length > 0) ||
      (property.additionalInfo
        ? property.additionalInfo !== additionalInfo
        : additionalInfo.length > 0) ||
      property.size !== size ||
      property.coordinates !== coordinates;

    if (propertyHasChanged && !errorOccured) {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          id: property.id,
          title: title,
          officialData: officialData ? officialData : undefined,
          additionalInfo: additionalInfo ? additionalInfo : undefined,
          size: size,
          areaType: usedAreaType,
          coordinates: coordinates,
          currentPaymentAmount: property.currentPaymentAmount
            ? property.currentPaymentAmount
            : undefined,
          currentCurrency: property.currentCurrency
            ? property.currentCurrency
            : undefined,
          currentPaymentInterval: property.currentPaymentInterval
            ? property.currentPaymentInterval
            : undefined,
        }),
      };
      const response = await fetchBackend(
        'secure/property/changeProperty',
        requestOptions
      );

      responseText = responseText + '<br/>' + response.message;
      if (response.success === false) {
        errorOccured = true;
        setSendingChangePropertyRequest(false);
        setInfo(responseText);

        // Remove info text after some time
        setTimeout(function () {
          setInfo('');
        }, SHOW_ERROR_INFO_TO_USER);
      }
    }

    // We will stay in loading mode, show the info and go back after a few seconds to properties-page
    if (!errorOccured) {
      setInfo(responseText);
      await delay(3000);
      goBack();
    }
  }

  useEffect(() => {
    subscribedEvents.push(
      singleEditablePropertyDetailComponentProps.eventBus.subscribe(
        polygonDrawnEvent,
        (event) => {
          const polygonCoordinates = event.payload.coordinates;
          setCoordinates(polygonCoordinates);
        }
      )
    );

    return () => {
      subscribedEvents.forEach((subscribedEvent) => subscribedEvent());
      subscribedEvents = [];
    };
  }, [singleEditablePropertyDetailComponentProps.eventBus]);

  // At start clear once the map
  useEffect(() => {
    singleEditablePropertyDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    centerBoundingBox(
      singleEditablePropertyDetailComponentProps.property,
      singleEditablePropertyDetailComponentProps.eventBus,
      false
    );
    singleEditablePropertyDetailComponentProps.eventBus.publish(
      startDrawPropertyWithPolygonEvent({
        polygon:
          singleEditablePropertyDetailComponentProps.property.coordinates,
      })
    );
  }, [
    singleEditablePropertyDetailComponentProps.eventBus,
    singleEditablePropertyDetailComponentProps.property,
  ]);

  const headerText = singleEditablePropertyDetailComponentProps.owner
    ? t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.HEADER_OWNER')
    : t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.HEADER_RENTER');

  const calculatedSize = getAreaSizeInGivenAreaType(coordinates, usedAreaType);
  const userReadableCalculatedSize = getReadableSize(
    calculatedSize,
    usedAreaType,
    t
  );

  const isSendingSomeRequest =
    sendingRemovePropertyRequest || sendingChangePropertyRequest;

  // Check if some value has changed
  const property = singleEditablePropertyDetailComponentProps.property;
  const somethingHasChanged =
    property.title !== title ||
    (property.officialData
      ? property.officialData !== officialData
      : officialData.length > 0) ||
    (property.additionalInfo
      ? property.additionalInfo !== additionalInfo
      : additionalInfo.length > 0) ||
    property.size !== size ||
    property.coordinates !== coordinates ||
    property.otherVerifiedOwnerUserMailAddresses !==
      verifiedOwnerMailAddresses ||
    property.otherVerifiedRenterUserMailAddresses !==
      verifiedRenterMailAddresses ||
    property.otherInvitedOwnerUserMailAddresses !== invitedOwnerMailAddresses ||
    property.otherInvitedRenterUserMailAddresses !== invitedRenterMailAddresses;
  const isValidProperty = title && coordinates.length > 2 && size > 0;
  const changeButtonEnabled = somethingHasChanged && isValidProperty;

  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            if (!isSendingSomeRequest) {
              if (somethingHasChanged) {
                setLeaveUnsavedChangesDialogOpen(true);
              } else {
                goBack();
              }
            }
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <div className={css(propertiesStyles.sectionSubView)}>
              {t(
                'PROPERTIES.SHOW_SINGLE_NON_EDITABLE_PROPERTY.SECTION_COMMON_DATA'
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              value={title}
              disabled={isSendingSomeRequest}
              onChange={(input) => {
                const value = input.target.value;
                setTitle(value);
              }}
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.TITLE')}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              value={officialData}
              disabled={isSendingSomeRequest}
              onChange={(input) => {
                const value = input.target.value;
                setOfficialData(value);
              }}
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.OFFICIAL_DATA'
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              value={additionalInfo}
              disabled={isSendingSomeRequest}
              onChange={(input) => {
                const value = input.target.value;
                setAdditionalInfo(value);
              }}
              fullWidth
              multiline
              rowsMax={5}
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.ADDITIONAL_INFO'
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.doubleTextFieldContainer)}>
              <TextField
                value={size.toString()}
                disabled={isSendingSomeRequest}
                className={css(styles.sizeTextField)}
                onChange={(input) => {
                  const value = (input.target.value as unknown) as number;
                  setSize(value);
                }}
                type="number"
                label={t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SIZE')}
              />
              <Select
                className={css(styles.areaTypeTextField)}
                disabled={isSendingSomeRequest}
                value={usedAreaType}
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    value !== 'SQUARE_FOOT' &&
                    value !== 'SQUARE_METER' &&
                    value !== 'ACRE' &&
                    value !== 'HECTARE'
                  )
                    return;

                  setUsedAreaType(value as AreaType);
                }}
              >
                <MenuItem value={'SQUARE_FOOT'}>
                  {t('AREA_TYPES.SQUARE_FOOT')}
                </MenuItem>
                <MenuItem value={'SQUARE_METER'}>
                  {t('AREA_TYPES.SQUARE_METER')}
                </MenuItem>
                <MenuItem value={'ACRE'}>{t('AREA_TYPES.ACRE')}</MenuItem>
                <MenuItem value={'HECTARE'}>{t('AREA_TYPES.HECTARE')}</MenuItem>
              </Select>
            </div>
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {singleEditablePropertyDetailComponentProps.mobileScreen
                ? t(
                    'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SECTION_DRAW_MOBILE'
                  )
                : t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SECTION_DRAW')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.DRAW_INSTRUCTIONS'
                ),
              }}
              className={css(styles.caption)}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <GeocoderAutoComplete
              disabled={isSendingSomeRequest}
              clickedGeocodeResult={(geocodeResult: GeocodeResult) => {
                singleEditablePropertyDetailComponentProps.eventBus.publish(
                  centerBoundingBoxEvent({
                    switchToMapViewOnMobileDevice: true,
                    upperLeft: geocodeResult.boundingBox.upperLeft,
                    lowerRight: geocodeResult.boundingBox.lowerRight,
                  })
                );
              }}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.caption)}>
              {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.CALCULATED_SIZE') +
                (calculatedSize === 0
                  ? t(
                      'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.NO_DRAWN_PROPERTY'
                    )
                  : userReadableCalculatedSize)}
            </div>
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            <span
              onClick={() => {
                if (!isSendingSomeRequest) {
                  setCoordinates([]);
                  singleEditablePropertyDetailComponentProps.eventBus.publish(
                    restartDrawPropertyEvent({})
                  );
                }
              }}
            >
              <Button
                className={css(styles.smallButton)}
                disabled={isSendingSomeRequest || coordinates.length === 0}
                variant="contained"
                color="primary"
              >
                {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.RESTART_DRAWING')}
              </Button>
            </span>
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SECTION_DATES')}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.CREATION_DATE'
              )}
              defaultValue={getReadableCreatedDate(
                singleEditablePropertyDetailComponentProps.property,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.LAST_CHANGED_DATE'
              )}
              defaultValue={getReadableLastChangedDate(
                singleEditablePropertyDetailComponentProps.property,
                t
              )}
            />
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SECTION_OTHER_USERS'
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            {verifiedOwnerMailAddresses
              ? verifiedOwnerMailAddresses.map(
                  (verifiedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={verifiedAddress}
                          owner={true}
                          editEnabled={false}
                          onlyRemovePossible={!isSendingSomeRequest}
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newVerifiedOwnerMailAddresses = [
                              ...verifiedOwnerMailAddresses,
                            ];
                            newVerifiedOwnerMailAddresses[index] = newValue;
                            setVerifiedOwnerMailAddresses(
                              newVerifiedOwnerMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newVerifiedOwnerMailAddresses = [
                              ...verifiedOwnerMailAddresses,
                            ];
                            newVerifiedOwnerMailAddresses.splice(index, 1);
                            setVerifiedOwnerMailAddresses(
                              newVerifiedOwnerMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.smallSpacing)} />
            {verifiedRenterMailAddresses
              ? verifiedRenterMailAddresses.map(
                  (verifiedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={verifiedAddress}
                          owner={false}
                          editEnabled={false}
                          onlyRemovePossible={!isSendingSomeRequest}
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newVerifiedRenterMailAddresses = [
                              ...verifiedRenterMailAddresses,
                            ];
                            newVerifiedRenterMailAddresses[index] = newValue;
                            setVerifiedRenterMailAddresses(
                              newVerifiedRenterMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newVerifiedRenterMailAddresses = [
                              ...verifiedRenterMailAddresses,
                            ];
                            newVerifiedRenterMailAddresses.splice(index, 1);
                            setVerifiedRenterMailAddresses(
                              newVerifiedRenterMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            {verifiedOwnerMailAddresses.length === 0 &&
            verifiedRenterMailAddresses.length === 0 ? (
              <div className={css(styles.caption)}>
                {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.NO_USERS')}
              </div>
            ) : null}
            <div className={css(styles.bigSpacing)} />

            <div className={css(propertiesStyles.sectionSubView)}>
              {t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.SECTION_OTHER_INVITED_USERS'
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            {invitedOwnerMailAddresses
              ? invitedOwnerMailAddresses.map(
                  (invitedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={invitedAddress}
                          owner={true}
                          editEnabled={
                            !isSendingSomeRequest &&
                            !isInvitedMailAddressAlreadyInvited(
                              singleEditablePropertyDetailComponentProps.property,
                              invitedAddress,
                              true
                            )
                          }
                          onlyRemovePossible={
                            !isSendingSomeRequest &&
                            isInvitedMailAddressAlreadyInvited(
                              singleEditablePropertyDetailComponentProps.property,
                              invitedAddress,
                              true
                            )
                          }
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newInvitedOwnerMailAddresses = [
                              ...invitedOwnerMailAddresses,
                            ];
                            newInvitedOwnerMailAddresses[index] = newValue;
                            setInvitedOwnerMailAddresses(
                              newInvitedOwnerMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newInvitedOwnerMailAddresses = [
                              ...invitedOwnerMailAddresses,
                            ];
                            newInvitedOwnerMailAddresses.splice(index, 1);
                            setInvitedOwnerMailAddresses(
                              newInvitedOwnerMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            <div>
              {!isSendingSomeRequest && (
                <AddUserComponent
                  owner={true}
                  clickedAddUser={() => {
                    if (!isSendingSomeRequest) {
                      setInvitedOwnerMailAddresses([
                        ...invitedOwnerMailAddresses,
                        '',
                      ]);
                    }
                  }}
                />
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            {invitedRenterMailAddresses
              ? invitedRenterMailAddresses.map(
                  (invitedAddress: string, index: number) => {
                    return (
                      <div key={index}>
                        <InvitedUserComponent
                          value={invitedAddress}
                          owner={false}
                          editEnabled={
                            !isSendingSomeRequest &&
                            !isInvitedMailAddressAlreadyInvited(
                              singleEditablePropertyDetailComponentProps.property,
                              invitedAddress,
                              false
                            )
                          }
                          onlyRemovePossible={
                            !isSendingSomeRequest &&
                            isInvitedMailAddressAlreadyInvited(
                              singleEditablePropertyDetailComponentProps.property,
                              invitedAddress,
                              false
                            )
                          }
                          onValueChanged={(newValue: string) => {
                            // Need to copy the array
                            const newInvitedRenterMailAddresses = [
                              ...invitedRenterMailAddresses,
                            ];
                            newInvitedRenterMailAddresses[index] = newValue;
                            setInvitedRenterMailAddresses(
                              newInvitedRenterMailAddresses
                            );
                          }}
                          clickedRemoveUser={() => {
                            // Need to copy the array
                            const newInvitedRenterMailAddresses = [
                              ...invitedRenterMailAddresses,
                            ];
                            newInvitedRenterMailAddresses.splice(index, 1);
                            setInvitedRenterMailAddresses(
                              newInvitedRenterMailAddresses
                            );
                          }}
                        />
                      </div>
                    );
                  }
                )
              : null}
            <div>
              {!isSendingSomeRequest && (
                <AddUserComponent
                  owner={false}
                  clickedAddUser={() => {
                    if (!isSendingSomeRequest) {
                      setInvitedRenterMailAddresses([
                        ...invitedRenterMailAddresses,
                        '',
                      ]);
                    }
                  }}
                />
              )}
            </div>
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled={isSendingSomeRequest}
              onChange={(input) => {
                const value = input.target.value;
                setInvitedInfoText(value);
              }}
              fullWidth
              multiline
              rowsMax={5}
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.INVITATION_INFO'
              )}
            />

            <div className={css(styles.bigSpacing)} />
            <div
              dangerouslySetInnerHTML={{
                __html: info,
              }}
              className={css(styles.infoText)}
            />
            <div className={css(styles.smallSpacing)} />
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={!changeButtonEnabled}
              onClick={() => {
                if (!isSendingSomeRequest) {
                  sendChangePropertyRequest();
                }
              }}
              color="primary"
            >
              {isSendingSomeRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!isSendingSomeRequest &&
                t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.CHANGE_BUTTON')}
            </Button>
            <Button
              className={css(styles.secondaryButton)}
              onClick={() => {
                if (!isSendingSomeRequest) {
                  setRemovePropertyDialogOpen(true);
                }
              }}
              variant="contained"
              color="secondary"
            >
              {isSendingSomeRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!isSendingSomeRequest &&
                t('PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.REMOVE_BUTTON')}
            </Button>
            <div className={css(styles.bottomSpacing)} />
          </MuiThemeProvider>
        </form>
      </div>

      <Dialog
        open={removePropertyDialogOpen}
        onClose={(event: any) => {
          handleCloseRemovePropertyCloseDialog();

          // Don't forward click
          event.stopPropagation();
        }}
        aria-labelledby="removePropertyDialogTitle"
        aria-describedby="removePropertyDialogText"
      >
        <DialogTitle id="removePropertyDialogTitle">
          {t(
            'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.REMOVE_PROPERTY_DIALOG_TITLE'
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="removePropertyDialogText">
            {t(
              'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.REMOVE_PROPERTY_DIALOG_TEXT'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleCloseRemovePropertyCloseDialog();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
          >
            {t('GLOBAL.NO')}
          </Button>
          <Button
            onClick={(event) => {
              handleCloseRemovePropertyCloseDialog();
              sendPropertyRemoveRequest();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
            autoFocus
          >
            {t('GLOBAL.YES')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={leaveUnsavedChangesDialogOpen}
        onClose={(event: any) => {
          handleCloseLeaveUnsavedChangesCloseDialog();

          // Don't forward click
          event.stopPropagation();
        }}
        aria-labelledby="unsavedChangesDialogTitle"
        aria-describedby="unsavedChangesDialogText"
      >
        <DialogTitle id="unsavedChangesDialogTitle">
          {t(
            'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.UNSAVED_CHANGES_DIALOG_TITLE'
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="unsavedChangesDialogText">
            {t(
              'PROPERTIES.SHOW_SINGLE_EDITABLE_PROPERTY.UNSAVED_CHANGES_DIALOG_TEXT'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleCloseLeaveUnsavedChangesCloseDialog();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
          >
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button
            onClick={(event) => {
              handleCloseLeaveUnsavedChangesCloseDialog();
              goBack();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
            autoFocus
          >
            {t('GLOBAL.YES')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function getRemovedAddressesOfGivenMailAddressLists(
  oldList: string[],
  newList: string[]
): string[] {
  const removedList: string[] = [];
  oldList.forEach((oldListEntry) => {
    let isRemovedFromList = true;
    newList.forEach((newListEntry) => {
      if (oldListEntry === newListEntry) isRemovedFromList = false;
    });

    if (isRemovedFromList) {
      removedList.push(oldListEntry);
    }
  });

  return removedList;
}

function isInvitedMailAddressAlreadyInvited(
  property: Property,
  mailAddressToCheck: string,
  owner: boolean
): boolean {
  if (mailAddressToCheck.length === 0) return false;

  const addressesToCheck = owner
    ? property.otherInvitedOwnerUserMailAddresses
    : property.otherInvitedRenterUserMailAddresses;

  let addressIsAlreadyInvited = false;
  addressesToCheck.forEach((alreadyInvitedAddress: string) => {
    if (mailAddressToCheck.includes(alreadyInvitedAddress)) {
      addressIsAlreadyInvited = true;
    }
  });

  return addressIsAlreadyInvited;
}

const styles = StyleSheet.create({
  doubleTextFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  sizeTextField: {
    width: '45%',
  },
  areaTypeTextField: {
    width: '45%',
    height: 48,
  },
  paymentAmountTextField: {
    width: '55%',
  },
  currencyTextField: {
    width: '35%',
    height: 48,
  },
  paymentIntervalTextField: {
    width: '35%',
    height: 48,
    float: 'right',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 90,
  },
  bottomSpacing: {
    height: 50,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    width: '38%',
    float: 'right',
    marginRight: 15,
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    background: SECONDARY_BUTTON_COLOR,
    width: '38%',
    float: 'left',
    marginLeft: 15,
  },
  smallButton: {
    ...defaultStyles.materialSmallButtonStyle,
    fontWeight: 'bold',
    float: 'right',
  },
});
