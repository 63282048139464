import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';

import { useTranslation } from 'react-i18next';
import { propertiesStyles } from '../PropertiesComponent';
import {
  clearMapEvent,
  drawPropertyWithoutMarkerEvent,
} from '../../map/MapComponent';
import { EventBus } from 'ts-bus';
import { UserSettings } from '../../../server/model/user/UserSettings';
import {
  MuiThemeProvider,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { materialUiTheme, defaultStyles } from '../../../styling/styles';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../../styling/dimens';
import {
  Property,
  getReadableUserGivenSize,
  getReadableCalculatedSize,
  getReadableCreatedDate,
  getReadableLastChangedDate,
} from '../../../server/model/property/Property';
import { centerBoundingBox } from '../PropertiesListComponent';
import InvitedUserComponent from '../invitations/InvitedUserComponent';
import { delay } from '../../../util/DelayUtil';
import { SHOW_ERROR_INFO_TO_USER } from '../../../util/AppComponentsProps';
import { fetchBackend } from '../../../authentication';
import { NO_GROUP_COLOR } from '../../../util/PropertyUtil';

export type ShowSingleInvitedPropertyComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
};

export default function ShowSingleInvitedPropertyComponent(
  showSingleInvitedPropertyComponentProps: ShowSingleInvitedPropertyComponentProps
) {
  const [info, setInfo] = useState<string>('');

  const [
    sendingAnswerInvitationRequest,
    setSendingAnswerInvitationRequest,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  function goBack() {
    showSingleInvitedPropertyComponentProps.eventBus.publish(clearMapEvent({}));
    showSingleInvitedPropertyComponentProps.goBack();
  }

  async function sendAnswerInvitationRequest(accept: boolean) {
    setSendingAnswerInvitationRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        propertyId: showSingleInvitedPropertyComponentProps.property.id,
        accept: accept,
      }),
    };
    const response = await fetchBackend(
      'secure/property/inviteAnswer',
      requestOptions
    );

    if (response.success === false) {
      setSendingAnswerInvitationRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and go back after a few seconds
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      goBack();
    }
  }

  // At start clear once the map
  useEffect(() => {
    showSingleInvitedPropertyComponentProps.eventBus.publish(clearMapEvent({}));
    showSingleInvitedPropertyComponentProps.eventBus.publish(
      drawPropertyWithoutMarkerEvent({
        property: showSingleInvitedPropertyComponentProps.property,
        color: NO_GROUP_COLOR,
      })
    );
    centerBoundingBox(
      showSingleInvitedPropertyComponentProps.property,
      showSingleInvitedPropertyComponentProps.eventBus,
      false
    );
  }, [
    showSingleInvitedPropertyComponentProps.eventBus,
    showSingleInvitedPropertyComponentProps.property,
  ]);

  const headerText = showSingleInvitedPropertyComponentProps.owner
    ? t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.HEADER_OWNER')
    : t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.HEADER_RENTER');

  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            goBack();
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.TITLE')}
              defaultValue={
                showSingleInvitedPropertyComponentProps.property.title
              }
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.OFFICIAL_DATA')}
              defaultValue={
                showSingleInvitedPropertyComponentProps.property.officialData
              }
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.ADDITIONAL_INFO'
              )}
              defaultValue={
                showSingleInvitedPropertyComponentProps.property.additionalInfo
              }
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.spacing)} />
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.SIZE')}
              defaultValue={getReadableUserGivenSize(
                showSingleInvitedPropertyComponentProps.property,
                showSingleInvitedPropertyComponentProps.userSettings
                  .preferredAreaType,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.CALCULATED_SIZE'
              )}
              defaultValue={getReadableCalculatedSize(
                showSingleInvitedPropertyComponentProps.property,
                showSingleInvitedPropertyComponentProps.userSettings
                  .preferredAreaType,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.spacing)} />
            <TextField
              disabled
              fullWidth
              label={t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.CREATION_DATE')}
              defaultValue={getReadableCreatedDate(
                showSingleInvitedPropertyComponentProps.property,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <TextField
              disabled
              fullWidth
              label={t(
                'PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.LAST_CHANGED_DATE'
              )}
              defaultValue={getReadableLastChangedDate(
                showSingleInvitedPropertyComponentProps.property,
                t
              )}
            />
            <div className={css(styles.smallSpacing)} />
            <div className={css(styles.spacing)} />
            {showSingleInvitedPropertyComponentProps.property.otherVerifiedOwnerUserMailAddresses.map(
              (verifiedAddress: string, index: number) => {
                return (
                  <div key={index}>
                    <InvitedUserComponent
                      value={verifiedAddress}
                      owner={true}
                      editEnabled={false}
                      onlyRemovePossible={false}
                    />
                  </div>
                );
              }
            )}
            <div className={css(styles.smallSpacing)} />
            {showSingleInvitedPropertyComponentProps.property.otherVerifiedRenterUserMailAddresses.map(
              (verifiedAddress: string, index: number) => {
                return (
                  <div key={index}>
                    <InvitedUserComponent
                      value={verifiedAddress}
                      owner={false}
                      editEnabled={false}
                      onlyRemovePossible={false}
                    />
                  </div>
                );
              }
            )}
            <div className={css(styles.spacing)} />
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.HINT_NO_PAYMENT_INFO'
                ),
              }}
              className={css(styles.caption)}
            />

            <div className={css(styles.bigSpacing)} />
            <div className={css(styles.infoText)}>{info}</div>
            <div className={css(styles.smallSpacing)} />
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              onClick={() => {
                if (!sendingAnswerInvitationRequest) {
                  sendAnswerInvitationRequest(true);
                }
              }}
              color="primary"
            >
              {sendingAnswerInvitationRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!sendingAnswerInvitationRequest &&
                t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.ACCEPT_INVITE')}
            </Button>
            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              onClick={() => {
                if (!sendingAnswerInvitationRequest) {
                  sendAnswerInvitationRequest(false);
                }
              }}
              color="secondary"
            >
              {sendingAnswerInvitationRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingAnswerInvitationRequest &&
                t('PROPERTIES.SHOW_SINGLE_INVITED_PROPERTY.DECLINE_INVITE')}
            </Button>

            <div className={css(styles.bottomSpacing)} />
          </MuiThemeProvider>
        </form>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 70,
  },
  bottomSpacing: {
    height: 50,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    width: '38%',
    float: 'right',
    marginRight: 15,
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    width: '38%',
    float: 'left',
    marginLeft: 15,
  },
});
