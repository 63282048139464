import React from 'react';
import { EventBus } from 'ts-bus';
import { UserSettings } from '../../../server/model/user/UserSettings';
import { Property } from '../../../server/model/property/Property';
import SingleNonEditablePropertyDetailComponent from './SingleNonEditablePropertyDetailComponent';
import SingleEditablePropertyDetailComponent from './SingleEditablePropertyDetailComponent';

export type SinglePropertyDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
  groupColor: string;
  mobileScreen: boolean;
};

export default function SinglePropertyDetailComponent(
  singlePropertyDetailComponentProps: SinglePropertyDetailComponentProps
) {
  if (singlePropertyDetailComponentProps.property.changeDataPossible) {
    return (
      <SingleEditablePropertyDetailComponent
        goBack={singlePropertyDetailComponentProps.goBack}
        eventBus={singlePropertyDetailComponentProps.eventBus}
        owner={singlePropertyDetailComponentProps.owner}
        userSettings={singlePropertyDetailComponentProps.userSettings}
        property={singlePropertyDetailComponentProps.property}
        mobileScreen={singlePropertyDetailComponentProps.mobileScreen}
      />
    );
  }
  return (
    <SingleNonEditablePropertyDetailComponent
      goBack={singlePropertyDetailComponentProps.goBack}
      eventBus={singlePropertyDetailComponentProps.eventBus}
      owner={singlePropertyDetailComponentProps.owner}
      userSettings={singlePropertyDetailComponentProps.userSettings}
      property={singlePropertyDetailComponentProps.property}
      groupColor={singlePropertyDetailComponentProps.groupColor}
      mobileScreen={singlePropertyDetailComponentProps.mobileScreen}
    />
  );
}
