import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { EventBus } from 'ts-bus';
import {
  MuiThemeProvider,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { propertiesStyles } from '../PropertiesComponent';
import {
  clearMapEvent,
  drawPropertyWithoutMarkerEvent,
  clearDrawPolygonMapEvent,
} from '../../map/MapComponent';
import { materialUiTheme, defaultStyles } from '../../../styling/styles';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../../styling/dimens';
import {
  Property,
  PaymentInterval,
  Currency,
} from '../../../server/model/property/Property';
import { centerBoundingBox } from '../PropertiesListComponent';
import MadePaymentComponent from './MadePaymentComponent';
import { MadePaymentInfo } from '../../../server/model/property/MadePaymentInfo';
import { UserSettings } from '../../../server/model/user/UserSettings';
import { fetchBackend } from '../../../authentication';
import { SHOW_ERROR_INFO_TO_USER } from '../../../util/AppComponentsProps';
import { delay } from '../../../util/DelayUtil';

export type SingleEditablePaymentDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
  groupColor: string;
};

export default function SingleEditablePaymentDetailComponent(
  singleEditablePaymentDetailComponentProps: SingleEditablePaymentDetailComponentProps
) {
  const [
    leaveUnsavedChangesDialogOpen,
    setLeaveUnsavedChangesDialogOpen,
  ] = useState(false);

  const [property, setProperty] = useState<Property>(
    singleEditablePaymentDetailComponentProps.property
  );

  const [info, setInfo] = useState<string>('');
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(
    singleEditablePaymentDetailComponentProps.property.currentPaymentAmount
  );
  const [currency, setCurrency] = useState<Currency>(
    singleEditablePaymentDetailComponentProps.property.currentCurrency
      ? singleEditablePaymentDetailComponentProps.property.currentCurrency
      : 'EUR'
  );
  const [paymentInterval, setPaymentInterval] = useState<PaymentInterval>(
    singleEditablePaymentDetailComponentProps.property.currentPaymentInterval
      ? singleEditablePaymentDetailComponentProps.property
          .currentPaymentInterval
      : 'ANNUALLY'
  );
  const [
    sendingChangePaymentRequest,
    setSendingChangePaymentRequest,
  ] = useState<boolean>(false);
  const [sendingAddPaymentRequest, setSendingAddPaymentRequest] = useState<
    boolean
  >(false);
  const [
    sendingRemovePaymentRequest,
    setSendingRemovePaymentRequest,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCloseLeaveUnsavedChangesCloseDialog = () => {
    setLeaveUnsavedChangesDialogOpen(false);
  };

  function goBack() {
    singleEditablePaymentDetailComponentProps.eventBus.publish(
      clearDrawPolygonMapEvent({})
    );
    singleEditablePaymentDetailComponentProps.goBack();
  }

  async function sendChangePropertyRequest() {
    setSendingChangePaymentRequest(true);

    const property = singleEditablePaymentDetailComponentProps.property;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        id: property.id,
        title: property.title,
        officialData: property.officialData ? property.officialData : undefined,
        additionalInfo: property.additionalInfo
          ? property.additionalInfo
          : undefined,
        size: property.size,
        areaType:
          singleEditablePaymentDetailComponentProps.userSettings
            .preferredAreaType,
        coordinates: property.coordinates,
        currentPaymentAmount: paymentAmount ? paymentAmount : undefined,
        currentCurrency: currency ? currency : undefined,
        currentPaymentInterval: paymentInterval ? paymentInterval : undefined,
      }),
    };
    const response = await fetchBackend(
      'secure/property/changeProperty',
      requestOptions
    );

    if (response.success === false) {
      setSendingChangePaymentRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and go back after a few seconds to properties-page
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      goBack();
    }
  }

  async function reloadProperty() {
    const requestPropertyOptions = {
      method: 'POST',
      body: JSON.stringify({
        id: property.id,
      }),
    };
    const propertyResponse = await fetchBackend(
      'secure/property/getProperty',
      requestPropertyOptions
    );

    if (propertyResponse.success === true) {
      setProperty(propertyResponse.property);
    }
  }

  async function sendAddPaymentRequest() {
    setSendingAddPaymentRequest(true);

    const property = singleEditablePaymentDetailComponentProps.property;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        propertyId: property.id,
      }),
    };
    await fetchBackend('secure/property/addMadePaymentInfo', requestOptions);

    // After adding the payment, get the full property again
    await reloadProperty();
    setSendingAddPaymentRequest(false);
  }

  async function sendRemovePaymentRequest(paymentId: number) {
    setSendingRemovePaymentRequest(true);

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        id: paymentId,
      }),
    };
    await fetchBackend('secure/property/deleteMadePaymentInfo', requestOptions);

    // After adding the payment, get the full property again
    await reloadProperty();
    setSendingRemovePaymentRequest(false);
  }

  // At start clear once the map
  useEffect(() => {
    singleEditablePaymentDetailComponentProps.eventBus.publish(
      clearMapEvent({})
    );
    singleEditablePaymentDetailComponentProps.eventBus.publish(
      drawPropertyWithoutMarkerEvent({
        property: singleEditablePaymentDetailComponentProps.property,
        color: singleEditablePaymentDetailComponentProps.groupColor,
      })
    );
    centerBoundingBox(
      singleEditablePaymentDetailComponentProps.property,
      singleEditablePaymentDetailComponentProps.eventBus,
      false
    );
  }, [
    singleEditablePaymentDetailComponentProps.eventBus,
    singleEditablePaymentDetailComponentProps.property,
    singleEditablePaymentDetailComponentProps.groupColor,
  ]);

  const headerText = singleEditablePaymentDetailComponentProps.owner
    ? t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.HEADER_OWNER')
    : t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.HEADER_RENTER');

  const sortedPaymentEntriesByYear = sortPaymentEntriesByYear(
    property.paymentHistory
  );

  const materialStyles = provideMaterialStyles();

  const isSendingSomeRequest =
    sendingChangePaymentRequest ||
    sendingAddPaymentRequest ||
    sendingRemovePaymentRequest;
  const changeButtonEnabled =
    property.currentPaymentAmount !== paymentAmount ||
    property.currentCurrency !== currency ||
    property.currentPaymentInterval !== paymentInterval;
  return (
    <div className={css(propertiesStyles.subViewMainContainer)}>
      <div className={css(propertiesStyles.subViewHeaderContainer)}>
        <ArrowBackIcon
          onClick={() => {
            if (!isSendingSomeRequest) {
              if (changeButtonEnabled) {
                setLeaveUnsavedChangesDialogOpen(true);
              } else {
                goBack();
              }
            }
          }}
          className={css(propertiesStyles.backIconSubView)}
        />
        <div className={css(propertiesStyles.headlineSubView)}>
          {headerText}
        </div>
      </div>
      <div className={css(propertiesStyles.subViewContentContainer)}>
        <MuiThemeProvider theme={materialUiTheme}>
          <div className={css(propertiesStyles.sectionSubView)}>
            {t(
              'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.SECTION_CURRENT_PAYMENT_DATA'
            )}
          </div>
          <div className={css(styles.smallSpacing)} />
          <div className={css(styles.doubleTextFieldContainer)}>
            <TextField
              className={css(styles.paymentAmountTextField)}
              disabled={isSendingSomeRequest}
              onChange={(input) => {
                const value = (input.target.value as unknown) as number;
                setPaymentAmount(value);
              }}
              value={paymentAmount}
              type="number"
              label={t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.PAYMENT_AMOUNT'
              )}
            />
            <Select
              className={css(styles.currencyTextField)}
              disabled={isSendingSomeRequest}
              value={currency}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== 'EUR' && value !== 'USD') return;

                setCurrency(value as Currency);
              }}
            >
              <MenuItem value={'EUR'}>EUR</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
            </Select>
          </div>
          <div className={css(styles.smallSpacing)} />
          <Select
            className={css(styles.paymentIntervalTextField)}
            disabled={isSendingSomeRequest}
            value={paymentInterval}
            onChange={(event) => {
              const value = event.target.value;
              if (
                value !== 'ANNUALLY' &&
                value !== 'BIANNUALLY' &&
                value !== 'QUARTERLY' &&
                value !== 'MONTHLY'
              )
                return;

              setPaymentInterval(value as PaymentInterval);
            }}
          >
            <MenuItem value={'ANNUALLY'}>
              {t('PAYMENT_INTERVAL.ANNUALLY')}
            </MenuItem>
            <MenuItem value={'BIANNUALLY'}>
              {t('PAYMENT_INTERVAL.BIANNUALLY')}
            </MenuItem>
            <MenuItem value={'QUARTERLY'}>
              {t('PAYMENT_INTERVAL.QUARTERLY')}
            </MenuItem>
            <MenuItem value={'MONTHLY'}>
              {t('PAYMENT_INTERVAL.MONTHLY')}
            </MenuItem>
          </Select>
          <div className={css(styles.spacing)} />
          <div className={css(styles.spacing)} />

          <div
            dangerouslySetInnerHTML={{
              __html: info,
            }}
            className={css(styles.infoText)}
          />
          <div className={css(styles.smallSpacing)} />
          <div className={css(styles.primaryButtonContainer)}>
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={!changeButtonEnabled}
              onClick={() => {
                if (!isSendingSomeRequest) {
                  sendChangePropertyRequest();
                }
              }}
              color="primary"
            >
              {isSendingSomeRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!isSendingSomeRequest &&
                t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.CHANGE_BUTTON')}
            </Button>
          </div>
          <div className={css(styles.spacing)} />
          <div className={css(styles.bigSpacing)} />

          <div className={css(propertiesStyles.sectionSubView)}>
            {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.SECTION_ADD_PAYMENT')}
          </div>
          <div className={css(styles.smallSpacing)} />
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.ADD_PAYMENT_INFO'
              ),
            }}
            className={css(styles.caption)}
          />
          <div className={css(styles.smallSpacing)} />
          <div className={css(styles.smallSpacing)} />
          <span
            onClick={() => {
              if (!isSendingSomeRequest) {
                sendAddPaymentRequest();
              }
            }}
          >
            <Button
              className={css(styles.smallButton)}
              disabled={isSendingSomeRequest}
              variant="contained"
              color="primary"
            >
              {isSendingSomeRequest && (
                <CircularProgress color={'secondary'} size={20} />
              )}
              {!isSendingSomeRequest &&
                t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.ADD_PAYMENT_BUTTON')}
            </Button>
          </span>
          <div className={css(styles.spacing)} />
          <div className={css(styles.bigSpacing)} />

          <div className={css(propertiesStyles.sectionSubView)}>
            {t(
              'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.SECTION_PAYMENT_HISTORY'
            )}
          </div>
          <div className={css(styles.smallSpacing)} />

          {property.paymentHistory.length > 0 ? (
            <List className={materialStyles.root} subheader={<li />}>
              {Object.keys(sortedPaymentEntriesByYear)
                .reverse()
                .map((year: string) => {
                  const sortedPaymentEntriesForCurrentYear =
                    sortedPaymentEntriesByYear[year];

                  return (
                    <li
                      key={`section-${year}`}
                      className={materialStyles.sectionYear}
                    >
                      <ul className={materialStyles.ul}>
                        <ListSubheader
                          className={materialStyles.sectionYearHeader}
                        >
                          {year}
                        </ListSubheader>
                        {sortedPaymentEntriesForCurrentYear.paymentEntries.map(
                          (paymentItem) => (
                            <MadePaymentComponent
                              key={paymentItem.id}
                              removeEnabled={true}
                              isSendingRequest={isSendingSomeRequest}
                              paymentEntry={paymentItem}
                              clickedRemovePayment={() => {
                                sendRemovePaymentRequest(paymentItem.id);
                              }}
                            />
                          )
                        )}
                      </ul>
                    </li>
                  );
                })}
            </List>
          ) : null}
          {property.paymentHistory.length === 0 ? (
            <div className={css(styles.caption)}>
              {t('PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.NO_PAYMENT_HISTORY')}
            </div>
          ) : null}
          <div className={css(styles.bottomSpacing)} />
        </MuiThemeProvider>
      </div>

      <Dialog
        open={leaveUnsavedChangesDialogOpen}
        onClose={(event: any) => {
          handleCloseLeaveUnsavedChangesCloseDialog();

          // Don't forward click
          event.stopPropagation();
        }}
        aria-labelledby="unsavedChangesDialogTitle"
        aria-describedby="unsavedChangesDialogText"
      >
        <DialogTitle id="unsavedChangesDialogTitle">
          {t(
            'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.UNSAVED_CHANGES_DIALOG_TITLE'
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="unsavedChangesDialogText">
            {t(
              'PROPERTIES.SHOW_SINGLE_EDITABLE_PAYMENT.UNSAVED_CHANGES_DIALOG_TEXT'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleCloseLeaveUnsavedChangesCloseDialog();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
          >
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button
            onClick={(event) => {
              handleCloseLeaveUnsavedChangesCloseDialog();
              goBack();

              // Don't forward click
              event.stopPropagation();
            }}
            color="primary"
            autoFocus
          >
            {t('GLOBAL.YES')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const provideMaterialStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
    overflow: 'auto',
  },
  sectionYear: {
    backgroundColor: 'inherit',
  },
  sectionYearHeader: {
    paddingBottom: 0,
    paddingTop: 20,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const styles = StyleSheet.create({
  doubleTextFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoText: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
    textAlign: 'center',
  },
  caption: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
    alignItems: 'center',
  },
  paymentAmountTextField: {
    width: '55%',
  },
  currencyTextField: {
    width: '35%',
    height: 48,
  },
  paymentIntervalTextField: {
    width: '35%',
    height: 48,
    float: 'right',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 35,
  },
  bigSpacing: {
    height: 70,
  },
  bottomSpacing: {
    height: 50,
  },
  primaryButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    width: '38%',
  },
  smallButton: {
    ...defaultStyles.materialSmallButtonStyle,
    fontWeight: 'bold',
    float: 'right',
  },
});

type PaymentYearsWithEntries = {
  [year: string]: {
    paymentEntries: MadePaymentInfo[];
  };
};

function sortPaymentEntriesByYear(
  paymentEntries: MadePaymentInfo[]
): PaymentYearsWithEntries {
  const paymentYearsWithEntries: PaymentYearsWithEntries = {};
  paymentEntries
    .sort(function (a: MadePaymentInfo, b: MadePaymentInfo) {
      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
    })
    .forEach((madePaymentInfo: MadePaymentInfo) => {
      const date = new Date(Date.parse(madePaymentInfo.timestamp));
      const year = date.toLocaleDateString(i18next.language, {
        year: 'numeric',
      });
      var paymentYearWithEntries = paymentYearsWithEntries[year];
      if (!paymentYearWithEntries) {
        paymentYearWithEntries = {
          paymentEntries: [],
        };
      }
      paymentYearWithEntries.paymentEntries.push(madePaymentInfo);
      paymentYearsWithEntries[year] = paymentYearWithEntries;
    });

  return paymentYearsWithEntries;
}
