import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationDE from './languages/de/translation.json';
import translationEN from './languages/en/translation.json';

// the translations
const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
};

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
