import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { HEADER_TEXT_COLOR, ACCENT_COLOR_LIGHT } from '../styling/colors';
import { TEXT_SIZE_NORMAL } from '../styling/dimens';
import { useHistory } from 'react-router-dom';

export type NavLink = {
  labelIdentifier: string;
  route: string;
};

export type AppHeaderNavEntryProps = {
  navLink: NavLink;
  isMobileScreen: boolean;
  onClick?: () => void;
};

export default function AppHeaderNavEntry(
  appHeaderNavEntryProps: AppHeaderNavEntryProps
) {
  const { labelIdentifier, route } = appHeaderNavEntryProps.navLink;
  const { t } = useTranslation();
  const history = useHistory();

  const currentlyChosenLink =
    route === '/'
      ? history.location.pathname.endsWith('/')
      : history.location.pathname.includes(route);

  if (appHeaderNavEntryProps.isMobileScreen) {
    return (
      <span
        className={css(mobileStyles.navEntryLink)}
        onClick={() => {
          if (appHeaderNavEntryProps.onClick) appHeaderNavEntryProps.onClick();
          history.push(route);
        }}
      >
        <span
          className={css(
            currentlyChosenLink
              ? mobileStyles.navEntrykMarked
              : mobileStyles.navEntry
          )}
        >
          {t(labelIdentifier)}
        </span>
      </span>
    );
  }
  return (
    <div className={css(styles.container)}>
      <span
        className={css(styles.navEntryLink)}
        onClick={() => {
          if (appHeaderNavEntryProps.onClick) appHeaderNavEntryProps.onClick();
          history.push(route);
        }}
      >
        <p className={css(styles.navEntry)}>{t(labelIdentifier)}</p>
      </span>
      <div
        className={css(
          currentlyChosenLink ? styles.underline : styles.emptyUnderline
        )}
      ></div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 110,
    height: 50,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  navEntryLink: {
    textDecoration: 'none',
  },
  underline: {
    height: 2,
    background: HEADER_TEXT_COLOR,
  },
  emptyUnderline: {
    height: 2,
    background: '#00000000',
  },
  navEntry: {
    height: 12,
    color: HEADER_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_NORMAL,
    cursor: 'pointer',
    textAlign: 'center',
  },
});

const mobileStyles = StyleSheet.create({
  navEntryLink: {
    textDecoration: 'none',
    marginTop: 10,
    marginBottom: 10,
  },
  navEntry: {
    paddingBottom: 5,
    paddingTop: 5,
    color: HEADER_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: '1.3em',
    cursor: 'pointer',
  },
  navEntrykMarked: {
    paddingBottom: 5,
    paddingTop: 5,
    color: ACCENT_COLOR_LIGHT,
    fontWeight: 'bold',
    fontSize: '1.3em',
    cursor: 'pointer',
  },
});
