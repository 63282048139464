import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import UserIcon from '@material-ui/icons/AccountCircle';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LocationState } from 'history';

import AppHeaderNavEntry, { NavLink } from '../components/AppHeaderNavEntry';
import {
  HEADER_BACKGROUND_COLOR,
  HEADER_TEXT_COLOR,
  ACCENT_COLOR,
} from '../styling/colors';
import { initiateLogin, initiateLogout } from '../authentication';
import {
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../util/AppComponentsProps';
import { TEXT_SIZE_NORMAL, TEXT_SIZE_BUTTON_BIG } from '../styling/dimens';
import { defaultStyles, materialUiTheme } from '../styling/styles';

const anonymousLinks = [
  {
    labelIdentifier: 'GLOBAL.HOME',
    route: '/',
  },
  {
    labelIdentifier: 'GLOBAL.PRICE',
    route: '/price',
  },
];

const loggedInLinks = [
  {
    labelIdentifier: 'APP_HEADER.LINK_OWNER',
    route: '/owner',
  },
  {
    labelIdentifier: 'APP_HEADER.LINK_RENTER',
    route: '/renter',
  },
];

export type AppHeaderProps = {
  isAuthenticated: boolean;
  appComponentsProps: AppComponentsProps;
};

export default function AppHeader(props: AppHeaderProps) {
  const [, setReload] = useState<LocationState | undefined>(undefined);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { isAuthenticated, appComponentsProps } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const links = isAuthenticated ? loggedInLinks : anonymousLinks;
  const mobileScreen = appComponentsProps.windowWidth <= MAX_WIDTH_MOBILE_PHONE;

  // Reload AppHeader when sub-site changes
  history.listen((location: LocationState) => {
    setReload(location);
  });

  // Tablet/Desktop-screen
  if (!mobileScreen) {
    return (
      <div className={css(styles.container)}>
        <header className={css(styles.header)}>
          <span
            onClick={() => {
              history.push('/');
            }}
          >
            <img
              src={window.location.origin + '/images/logo.svg'}
              className={css(styles.logo)}
              alt="Logo"
            />
          </span>
          <div className={css(styles.links)}>
            {links &&
              links.map((link: NavLink) => (
                <AppHeaderNavEntry
                  isMobileScreen={false}
                  key={link.labelIdentifier}
                  navLink={link}
                />
              ))}
            <div className={css(styles.divider)} />
            {getLoginRegisterLinks(isAuthenticated, t, false, history)}
            {getAccountLink(isAuthenticated, false, history)}
          </div>
        </header>
      </div>
    );
  }

  // Mobile screen
  return (
    <div className={css(styles.container)}>
      <header className={css(styles.header)}>
        <Menu
          onOpen={() => {
            setMobileMenuOpen(true);
          }}
          onClose={() => {
            setMobileMenuOpen(false);
          }}
          isOpen={mobileMenuOpen}
          disableAutoFocus
          styles={mobileBurgerStyles}
          width={300}
        >
          <div className={css(mobileStyles.links)}>
            {links &&
              links.map((link: NavLink) => (
                <AppHeaderNavEntry
                  isMobileScreen={true}
                  key={link.labelIdentifier}
                  navLink={link}
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                />
              ))}
            <div className={css(mobileStyles.divider)} />
            {getLoginRegisterLinks(isAuthenticated, t, true, history, () => {
              setMobileMenuOpen(false);
            })}
          </div>
        </Menu>
        <span
          onClick={() => {
            history.push('/');
          }}
        >
          <img
            src={window.location.origin + '/images/logo.svg'}
            className={css(styles.logo)}
            alt="Logo"
          />
        </span>
        {getAccountLink(isAuthenticated, true, history)}
      </header>
    </div>
  );
}

async function logoutUser() {
  await initiateLogout();
}

async function loginUser() {
  await initiateLogin();
}

function getAccountLink(
  isAuthenticated: boolean,
  isMobileScreen: boolean,
  history: any
) {
  if (isAuthenticated === false) {
    return (
      <div
        className={css(
          isMobileScreen
            ? mobileStyles.emptyAccountLink
            : styles.emptyAccountLink
        )}
      />
    );
  }

  return (
    <span
      onClick={() => {
        history.push('/account');
      }}
    >
      <UserIcon
        className={css(
          isMobileScreen ? mobileStyles.accountLink : styles.accountLink
        )}
      />
    </span>
  );
}

function getLoginRegisterLinks(
  isAuthenticated: boolean,
  t: any,
  isMobileScreen: boolean,
  history: any,
  onClick?: () => void
) {
  if (isAuthenticated === false)
    return (
      <div className={css(isMobileScreen ? mobileStyles.links : styles.links)}>
        <MuiThemeProvider theme={materialUiTheme}>
          <span
            onClick={() => {
              if (onClick) onClick();
              loginUser();
            }}
            className={css(
              isMobileScreen
                ? mobileStyles.accountNavEntryLoginLink
                : styles.accountNavEntryLoginLink
            )}
          >
            <Button
              className={css(
                isMobileScreen
                  ? mobileStyles.accountNavEntryLogin
                  : styles.accountNavEntryLogin
              )}
              variant="contained"
              color="primary"
            >
              {t('GLOBAL.LOGIN')}
            </Button>
          </span>
        </MuiThemeProvider>

        <span
          className={css(
            isMobileScreen
              ? mobileStyles.accountNavEntryRegisterLink
              : styles.accountNavEntryRegisterLink
          )}
          onClick={() => {
            if (onClick) onClick();
            history.push('/register');
          }}
        >
          <span
            className={css(
              isMobileScreen
                ? mobileStyles.accountNavEntry
                : styles.accountNavEntry
            )}
          >
            {t('GLOBAL.REGISTER')}
          </span>
        </span>
      </div>
    );

  return (
    <div>
      <span
        onClick={() => {
          if (onClick) onClick();
          logoutUser();
        }}
        className={css(
          isMobileScreen ? mobileStyles.accountNavEntry : styles.accountNavEntry
        )}
      >
        {t('GLOBAL.LOGOUT')}
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  logo: {
    height: 34,
    cursor: 'pointer',
  },
  container: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
  },
  header: {
    height: 50,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: HEADER_BACKGROUND_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyAccountLink: {
    height: 0,
    width: 0,
  },
  accountLink: {
    marginLeft: 30,
    height: 40,
    width: 40,
    color: ACCENT_COLOR,
    cursor: 'pointer',
  },
  divider: {
    width: 1,
    height: 35,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: HEADER_TEXT_COLOR,
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accountNavEntryLogin: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontSize: TEXT_SIZE_BUTTON_BIG,
    marginRight: 30,
    fontWeight: 'bold',
    borderRadius: 10,
    minWidth: 0,
  },
  accountNavEntryLoginLink: {
    marginRight: 5,
    marginLeft: 5,
  },
  accountNavEntryRegisterLink: {
    textDecoration: 'none',
  },
  accountNavEntry: {
    color: HEADER_TEXT_COLOR,
    cursor: 'pointer',
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_NORMAL,
    opacity: 0.5,
  },
});

const mobileStyles = StyleSheet.create({
  divider: {
    width: 260,
    height: 1.8,
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: HEADER_TEXT_COLOR,
  },
  emptyAccountLink: {
    height: 28,
    width: 28,
  },
  accountLink: {
    height: 40,
    width: 40,
    marginRight: -12,
    color: ACCENT_COLOR,
    cursor: 'pointer',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accountNavEntry: {
    color: HEADER_TEXT_COLOR,
    cursor: 'pointer',
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 'bold',
    fontSize: '1.3em',
    opacity: 0.5,
  },
  accountNavEntryLoginLink: {
    marginTop: 13,
    marginBottom: 13,
  },
  accountNavEntryLogin: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontSize: TEXT_SIZE_BUTTON_BIG,
    fontWeight: 'bold',
    borderRadius: 10,
    minWidth: 0,
  },
  accountNavEntryRegisterLink: {
    textDecoration: 'none',
    marginTop: 13,
    marginBottom: 13,
  },
});

var mobileBurgerStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '28px',
    height: '22px',
  },
  bmBurgerBars: {
    background: ACCENT_COLOR,
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    left: '30px',
    top: '7px',
    height: '28px',
    width: '28px',
  },
  bmCross: {
    background: ACCENT_COLOR,
    height: '28px',
  },
  bmItem: {
    outline: 'none',
  },
  bmMenuWrap: {
    position: 'fixed',
    left: '0',
    top: '0',
    height: '100%',
  },
  bmMenu: {
    background: HEADER_BACKGROUND_COLOR,
    padding: '2.5em 1.5em 0',
  },
};
