import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import MetaTags from 'react-meta-tags';
import { TEXT_SIZE_BIG, TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { defaultStyles } from '../../styling/styles';
import { useTranslation } from 'react-i18next';

export default function Imprint(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.IMPRINT_TITLE');

  window.scrollTo(0, 0);

  return (
    <div className={css(styles.container)}>
      <MetaTags>
        <meta name="description" content={t('GLOBAL.IMPRINT_DESCRIPTION')} />
      </MetaTags>
      <span className={css(styles.header)}>
        <div>{t('IMPRINT.HEADER')}</div>
      </span>
      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION1_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION1_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION2_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION2_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION3_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION3_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION4_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION4_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION4_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION5_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION5_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION6_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('IMPRINT.SECTION6_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION7_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('IMPRINT.SECTION7_TEXT')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('IMPRINT.SECTION8_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('IMPRINT.SECTION8_TEXT')}</div>
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 60,
  },
  section: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_BIG,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
});
