import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FOOTER_BACKGROUND_COLOR } from '../styling/colors';
import {
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../util/AppComponentsProps';
import AppFooterNavEntry, { NavLink } from '../components/AppFooterNavEntry';
import { useHistory } from 'react-router-dom';

const links = [
  {
    labelIdentifier: 'GLOBAL.IMPRINT',
    route: '/imprint',
  },
  {
    labelIdentifier: 'GLOBAL.PRIVACY',
    route: '/privacy',
  },
  {
    labelIdentifier: 'GLOBAL.TERMS_OF_USE',
    route: '/terms',
  },
];

export type AppFooterProps = {
  appComponentsProps: AppComponentsProps;
};

export default function AppFooter(props: AppFooterProps) {
  const history = useHistory();
  const mobileScreen =
    props.appComponentsProps.windowWidth <= MAX_WIDTH_MOBILE_PHONE;

  // Tablet/Desktop-screen
  if (!mobileScreen) {
    return (
      <footer className={css(styles.footer)}>
        <span
          onClick={() => {
            history.push('/');
          }}
        >
          <img
            src={window.location.origin + '/images/applogo.svg'}
            className={css(styles.logo)}
            alt="Logo"
          />
        </span>

        <div className={css(styles.links)}>
          <section>
            {links &&
              links.map((link: NavLink) => (
                <AppFooterNavEntry
                  isMobileScreen={false}
                  key={link.labelIdentifier}
                  navLink={link}
                />
              ))}
          </section>
        </div>
      </footer>
    );
  }

  // Mobile screen
  return (
    <footer className={css(mobileStyles.footer)}>
      <span
        className={css(mobileStyles.logoContainer)}
        onClick={() => {
          history.push('/');
        }}
      >
        <img
          src={window.location.origin + '/images/applogo.svg'}
          className={css(mobileStyles.logo)}
          alt="Logo"
        />
      </span>
      {links &&
        links.map((link: NavLink) => (
          <AppFooterNavEntry
            isMobileScreen={true}
            key={link.labelIdentifier}
            navLink={link}
          />
        ))}
    </footer>
  );
}

const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: FOOTER_BACKGROUND_COLOR,
    left: 0,
    bottom: 0,
    width: '100%',
  },
  logo: {
    height: 65,
    marginLeft: 50,
    marginRight: 50,
    marginTop: 15,
    marginBottom: 15,
    cursor: 'pointer',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const mobileStyles = StyleSheet.create({
  footer: {
    left: 0,
    bottom: 0,
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: 'center',
    backgroundColor: FOOTER_BACKGROUND_COLOR,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoContainer: {
    marginBottom: 20,
    cursor: 'pointer',
  },
  logo: {
    height: 65,
  },
});
