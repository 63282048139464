import i18next, { TFunction } from 'i18next';
import { LatLng } from './LatLng';
import { MadePaymentInfo } from './MadePaymentInfo';
import { AreaType, getReadableSize } from '../user/UserSettings';

export type Currency = 'EUR' | 'USD';
export type PaymentInterval =
  | 'ANNUALLY'
  | 'BIANNUALLY'
  | 'QUARTERLY'
  | 'MONTHLY';

export type Property = {
  id: number;
  owner: boolean;
  otherVerifiedOwnerUserMailAddresses: string[];
  otherVerifiedRenterUserMailAddresses: string[];
  otherInvitedOwnerUserMailAddresses: string[];
  otherInvitedRenterUserMailAddresses: string[];
  changeDataPossible: boolean;
  title: string;
  officialData?: string;
  additionalInfo?: string;
  size: number;
  calculatedSize: number;
  coordinates: LatLng[];
  center: LatLng;
  createdDate: string;
  lastChangedDate: string;
  groupId: number;
  currentPaymentAmount?: number;
  currentCurrency?: Currency;
  currentPaymentInterval?: PaymentInterval;
  paymentHistory: MadePaymentInfo[];
};

export function generatePropertyName(property: Property, t: TFunction): string {
  if (property.officialData) {
    return property.title + ' (' + property.officialData + ')';
  }
  return property.title;
}

export function getReadableUserGivenSizeForOverview(
  property: Property,
  usedAreaType: AreaType,
  t: TFunction
): string {
  const userGivenSize = getReadableUserGivenSize(property, usedAreaType, t);
  return t('PROPERTIES.LIST.SIZE_X') + userGivenSize;
}

export function getReadableCalculatedSizeForOverview(
  property: Property,
  usedAreaType: AreaType,
  t: TFunction
): string {
  const userGivenSize = getReadableCalculatedSize(property, usedAreaType, t);
  return t('PROPERTIES.LIST.SIZE_X') + userGivenSize;
}

export function getReadableUserGivenSize(
  property: Property,
  usedAreaType: AreaType,
  t: TFunction
): string {
  return getReadableSize(property.size, usedAreaType, t);
}

export function getReadableCalculatedSize(
  property: Property,
  usedAreaType: AreaType,
  t: TFunction
): string {
  return getReadableSize(property.calculatedSize, usedAreaType, t);
}

export function getReadableCreatedDate(
  property: Property,
  t: TFunction
): string {
  const date = new Date(Date.parse(property.createdDate));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options) + t('GLOBAL.CLOCK');
}

export function getReadableLastChangedDate(
  property: Property,
  t: TFunction
): string {
  const date = new Date(Date.parse(property.lastChangedDate));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options) + t('GLOBAL.CLOCK');
}
