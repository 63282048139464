import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  ACCENT_COLOR,
  SECONDARY_TEXT_COLOR,
  SECONDARY_BUTTON_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { MuiThemeProvider, Button, CircularProgress } from '@material-ui/core';
import { fetchBackend } from '../../authentication';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Subscription,
  getReadableValidUntilDate,
  getHumanReadableProvider,
  getHumanReadableProviderWithPaymentMethod,
  getReadableCreatedDate,
} from '../../server/model/payment/Subscription';
import {
  ACCOUNT_MAX_WIDTH_CONTENT,
  MAX_WIDTH_CONTENT,
  SHOW_ERROR_INFO_TO_USER,
} from '../../util/AppComponentsProps';
import { TEXT_SIZE_VERY_BIG, TEXT_SIZE_VERY_SMALL } from '../../styling/dimens';
import { delay } from '../../util/DelayUtil';
import { Payment } from '../../server/model/payment/Payment';
import SinglePaymentComponent from '../../components/account/SinglePaymentComponent';

const RELOAD_INTERVAL_PENDING = 2000;

export default function AccountPaymentOverview(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const history = useHistory();
  const location = useLocation();

  // Can be an internal redirect with an info like "enough properties" or from the payment-callback with content "payment success/failed"
  const infoForUser = location.state
    ? (location.state as any).infoForUser
    : undefined;

  const [info, setInfo] = useState<string | undefined>(undefined);
  const [
    triggeredReloadForPendingState,
    setTriggeredReloadForPendingState,
  ] = useState<boolean>(false);
  const [hasSubscriptionData, setHasSubscriptionData] = useState<
    Boolean | undefined
  >(undefined);
  const [subscription, setSubscription] = useState<Subscription | undefined>(
    undefined
  );
  const [payments, setPayments] = useState<Payment[] | undefined>(undefined);
  const [
    sendingCreatePayPalSubscriptionLink,
    setSendingPayPalCreateSubscriptionLink,
  ] = useState<boolean>(false);
  const [
    sendingPauseSubscriptionRequest,
    setSendingPauseSubscriptionRequest,
  ] = useState<boolean>(false);
  const [
    sendingUnPauseSubscriptionRequest,
    setSendingUnPauseSubscriptionRequest,
  ] = useState<boolean>(false);

  async function reloadSubscriptionData() {
    if (hasSubscriptionData !== undefined) {
      setHasSubscriptionData(undefined);
      setSubscription(undefined);
      setPayments(undefined);
    }
  }

  async function reloadSubscriptionDataWithoutInvalidateCurrentData() {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ updateDataViaProvider: true }),
    };
    const subscriptionData = await fetchBackend(
      'secure/payment/getSubscriptionDetails',
      requestOptions
    );

    if (subscriptionData.success === true) {
      setHasSubscriptionData(true);
      if (subscriptionData.subscription) {
        setSubscription(subscriptionData.subscription);
      }
    } else {
      setHasSubscriptionData(false);
    }
    setTriggeredReloadForPendingState(false);
  }

  async function sendCreatePayPalSubscription() {
    setSendingPayPalCreateSubscriptionLink(true);
    const response = await fetchBackend(
      'secure/payment/createPaypalSubscription'
    );

    if (response.success === false) {
      setSendingPayPalCreateSubscriptionLink(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and open the link for payment
    if (response.success === true) {
      setInfo('Jetzt gehts los');
      await delay(1500);
      window.open(response.checkoutUrl, '_self');
    }
  }

  async function sendPauseSubscription() {
    setSendingPauseSubscriptionRequest(true);
    const response = await fetchBackend('secure/payment/pauseSubscription');

    if (response.success === false) {
      setSendingPauseSubscriptionRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and reload subscription data afterwards
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      setInfo('');
      setSendingPauseSubscriptionRequest(false);
      reloadSubscriptionData();
    }
  }

  async function sendUnPauseSubscription() {
    setSendingUnPauseSubscriptionRequest(true);
    const response = await fetchBackend('secure/payment/unPauseSubscription');

    if (response.success === false) {
      setSendingUnPauseSubscriptionRequest(false);
      setInfo(response.message);

      // Remove info text after some time
      setTimeout(function () {
        setInfo('');
      }, SHOW_ERROR_INFO_TO_USER);
    }

    // We will stay in loading mode, show the info and reload subscription data afterwards
    if (response.success === true) {
      setInfo(response.message);
      await delay(1500);
      setInfo('');
      setSendingUnPauseSubscriptionRequest(false);
      reloadSubscriptionData();
    }
  }

  useEffect(() => {
    async function initialLoadSubscriptionData() {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ updateDataViaProvider: true }),
      };
      const subscriptionData = await fetchBackend(
        'secure/payment/getSubscriptionDetails',
        requestOptions
      );

      if (subscriptionData.success === true) {
        setHasSubscriptionData(true);
        if (subscriptionData.subscription) {
          setSubscription(subscriptionData.subscription);
        }
      } else {
        setHasSubscriptionData(false);
      }
    }

    async function initialLoadPayments() {
      const paymentsData = await fetchBackend('secure/payment/payments');

      if (paymentsData.success === true) {
        setPayments(paymentsData.payments);
      } else {
        setPayments(undefined);
      }
    }

    if (hasSubscriptionData === undefined) initialLoadSubscriptionData();
    if (payments === undefined) initialLoadPayments();
  }, [hasSubscriptionData, payments]);

  if (hasSubscriptionData === undefined || payments === undefined) {
    return (
      <MuiThemeProvider theme={materialUiTheme}>
        <div className={css(styles.loadingContainer)}>
          <CircularProgress color={'primary'} size={40} />
        </div>
      </MuiThemeProvider>
    );
  }

  const paymentsInfo =
    payments && payments.length > 0 ? (
      <div>
        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <span className={css(styles.header)}>
          <div>{t('PAYMENT_PAGE.PAYMENTS_TITLE')}</div>
        </span>
        <div className={css(styles.paymentListDivider)} />
        {payments.map((payment: Payment) => {
          return <SinglePaymentComponent key={payment.id} payment={payment} />;
        })}
      </div>
    ) : null;

  const sendingSomeRequest =
    sendingCreatePayPalSubscriptionLink ||
    sendingPauseSubscriptionRequest ||
    sendingUnPauseSubscriptionRequest;

  // Handle cases, when user does not have current subscription
  if (
    hasSubscriptionData === false ||
    subscription === undefined ||
    subscription.status === 'CANCELLED' ||
    subscription.status === 'INACTIVE'
  ) {
    return (
      <div className={css(styles.container)}>
        <span className={css(styles.header)}>
          <div>{t('PAYMENT_PAGE.TITLE')}</div>
        </span>
        <div className={css(styles.spacing)} />
        {infoForUser && (
          <div className={css(styles.noPaidAboInfo)}>
            {t('PAYMENT_PAGE.NO_ABO_SPECIAL_INFO')}
            {infoForUser}
          </div>
        )}
        {infoForUser && <div className={css(styles.smallSpacing)} />}

        <span className={css(styles.text)}>
          {t('PAYMENT_PAGE.NO_ABO_TEXT_PREFIX')}
          {
            <span
              className={css(styles.priceOverviewLink)}
              onClick={() => {
                history.push('/price');
              }}
            >
              {t('GLOBAL.HERE')}
            </span>
          }
          {t('PAYMENT_PAGE.NO_ABO_TEXT_POSTFIX')}
        </span>

        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <Button
              className={css(styles.secondaryButton)}
              onClick={() => {
                if (!sendingSomeRequest) {
                  history.goBack();
                }
              }}
              variant="contained"
              color="secondary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingSomeRequest && t('GLOBAL.BACK')}
            </Button>

            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={sendingSomeRequest}
              onClick={() => {
                sendCreatePayPalSubscription();
              }}
              color="primary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!sendingSomeRequest && t('PAYMENT_PAGE.NO_ABO_START_PREMIUM')}
            </Button>
          </MuiThemeProvider>
        </form>
        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.subInfo)}>
          {t('PAYMENT_PAGE.NO_ABO_LEGAL_HINT')}
        </div>
        {paymentsInfo}
      </div>
    );
  }

  // Handle cases, when user an subscription (maybe paused, pending, active or with a failure)
  const provider = getHumanReadableProvider(t, subscription);
  const providerWithPayment = getHumanReadableProviderWithPaymentMethod(
    t,
    subscription
  );
  if (subscription.status === 'FAILURE') {
    return (
      <div className={css(styles.container)}>
        <span className={css(styles.header)}>
          <div>{t('PAYMENT_PAGE.TITLE')}</div>
        </span>
        <div className={css(styles.spacing)} />

        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ABO_STATUS')}
        </div>
        <div
          className={css(styles.text)}
          dangerouslySetInnerHTML={{
            __html: t('PAYMENT_PAGE.ABO_STATUS_FAILURE'),
          }}
        />
        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ACTIVE_INFO_PAYMENT_METHOD')}
        </div>
        <div className={css(styles.text)}>{providerWithPayment}</div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.INFO_SUBSCRIPTION_SINCE')}
        </div>
        <div className={css(styles.text)}>
          {getReadableCreatedDate(subscription)}
        </div>

        <div className={css(styles.spacing)} />
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <Button
              className={css(styles.secondaryButton)}
              onClick={() => {
                if (!sendingSomeRequest) {
                  history.goBack();
                }
              }}
              variant="contained"
              color="secondary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingSomeRequest && t('GLOBAL.BACK')}
            </Button>
          </MuiThemeProvider>
        </form>

        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.info)}>{info}</div>

        {paymentsInfo}
      </div>
    );
  }

  if (subscription.status === 'PENDING') {
    if (!triggeredReloadForPendingState) {
      setTriggeredReloadForPendingState(true);
      setTimeout(function () {
        reloadSubscriptionDataWithoutInvalidateCurrentData();
      }, RELOAD_INTERVAL_PENDING);
    }

    return (
      <MuiThemeProvider theme={materialUiTheme}>
        <div className={css(styles.loadingContainer)}>
          <CircularProgress color={'primary'} size={40} />
          <div className={css(styles.smallSpacing)} />
          <div className={css(styles.text)}>
            {t('PAYMENT_PAGE.ABO_STATUS_PENDING') + provider}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }

  if (subscription.status === 'ACTIVE') {
    return (
      <div className={css(styles.container)}>
        <span className={css(styles.header)}>
          <div>{t('PAYMENT_PAGE.TITLE')}</div>
        </span>
        <div className={css(styles.spacing)} />

        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ABO_STATUS')}
        </div>
        <div className={css(styles.text)}>
          {t('PAYMENT_PAGE.ABO_STATUS_ACTIVE')}
        </div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ACTIVE_INFO_PAYMENT_METHOD')}
        </div>
        <div className={css(styles.text)}>{providerWithPayment}</div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ACTIVE_INFO_NEXT_PAYMENT')}
        </div>
        <div className={css(styles.text)}>
          {subscription.amount +
            ' ' +
            subscription.currency +
            t('PAYMENT_PAGE.ACTIVE_INFO_NEXT_PAYMENT_AT') +
            getReadableValidUntilDate(subscription)}
        </div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.INFO_SUBSCRIPTION_SINCE')}
        </div>
        <div className={css(styles.text)}>
          {getReadableCreatedDate(subscription)}
        </div>

        <div className={css(styles.spacing)} />
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <Button
              className={css(styles.secondaryButton)}
              onClick={() => {
                if (!sendingSomeRequest) {
                  history.goBack();
                }
              }}
              variant="contained"
              color="secondary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingSomeRequest && t('GLOBAL.BACK')}
            </Button>
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={sendingSomeRequest}
              onClick={() => {
                sendPauseSubscription();
              }}
              color="primary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!sendingSomeRequest && t('PAYMENT_PAGE.BUTTON_PAUSE')}
            </Button>
          </MuiThemeProvider>
        </form>

        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.info)}>{info ? info : infoForUser}</div>

        {paymentsInfo}
      </div>
    );
  }

  if (subscription.status === 'PAUSED') {
    return (
      <div className={css(styles.container)}>
        <span className={css(styles.header)}>
          <div>{t('PAYMENT_PAGE.TITLE')}</div>
        </span>
        <div className={css(styles.spacing)} />

        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.ABO_STATUS')}
        </div>
        <div className={css(styles.text)}>
          {t('PAYMENT_PAGE.ABO_STATUS_PAUSED')}
        </div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.PAUSED_INFO_PAYMENT_METHOD')}
        </div>
        <div className={css(styles.text)}>{providerWithPayment}</div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.PAUSED_INFO_NEXT_PAYMENT')}
        </div>
        <div className={css(styles.text)}>
          {getReadableValidUntilDate(subscription)}
        </div>

        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PAYMENT_PAGE.INFO_SUBSCRIPTION_SINCE')}
        </div>
        <div className={css(styles.text)}>
          {getReadableCreatedDate(subscription)}
        </div>

        <div className={css(styles.spacing)} />
        <form>
          <MuiThemeProvider theme={materialUiTheme}>
            <Button
              className={css(styles.secondaryButton)}
              onClick={() => {
                if (!sendingSomeRequest) {
                  history.goBack();
                }
              }}
              variant="contained"
              color="secondary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'primary'} size={22} />
              )}
              {!sendingSomeRequest && t('GLOBAL.BACK')}
            </Button>
            <Button
              className={css(styles.primaryButton)}
              variant="contained"
              disabled={sendingSomeRequest}
              onClick={() => {
                sendUnPauseSubscription();
              }}
              color="primary"
            >
              {sendingSomeRequest && (
                <CircularProgress color={'secondary'} size={22} />
              )}
              {!sendingSomeRequest && t('PAYMENT_PAGE.BUTTON_UNPAUSE')}
            </Button>
          </MuiThemeProvider>
        </form>

        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.info)}>{info}</div>

        {paymentsInfo}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: ACCOUNT_MAX_WIDTH_CONTENT,
  },
  loadingContainer: {
    marginTop: 150,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  smallSpacing: {
    height: 20,
  },
  spacing: {
    height: 35,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    width: '38%',
    float: 'right',
    marginRight: 5,
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    background: SECONDARY_BUTTON_COLOR,
    width: '38%',
    float: 'left',
    marginLeft: 5,
  },
  inputField: {
    maxWidth: MAX_WIDTH_CONTENT / 2,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  caption: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR,
    opacity: 0.85,
    textAlign: 'justify',
  },
  info: {
    ...defaultStyles.textStyle,
    textAlign: 'center',
    opacity: 0.8,
  },
  noPaidAboInfo: {
    ...defaultStyles.textStyle,
    fontStyle: 'italic',
  },
  subInfo: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR,
    fontSize: TEXT_SIZE_VERY_SMALL,
  },
  priceOverviewLink: {
    ...defaultStyles.textStyle,
    color: ACCENT_COLOR,
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  paymentListDivider: {
    width: '100%',
    height: 1.0,
    opacity: 0.25,
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: PRIMARY_TEXT_COLOR,
  },
});
