import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { FOOTER_TEXT_COLOR } from '../styling/colors';
import { TEXT_SIZE_NORMAL } from '../styling/dimens';
import { useHistory } from 'react-router-dom';

export type NavLink = {
  labelIdentifier: string;
  route: string;
};

export type AppFooterNavEntryProps = {
  navLink: NavLink;
  isMobileScreen: boolean;
};

export default function AppFooterNavEntry(
  appFooterNavEntryProps: AppFooterNavEntryProps
) {
  const { labelIdentifier, route } = appFooterNavEntryProps.navLink;
  const { t } = useTranslation();
  const history = useHistory();

  if (appFooterNavEntryProps.isMobileScreen) {
    return (
      <span
        className={css(mobileStyles.navEntryLink)}
        onClick={() => {
          history.push(route);
        }}
      >
        <span className={css(mobileStyles.navEntry)}>{t(labelIdentifier)}</span>
      </span>
    );
  }
  return (
    <span
      className={css(styles.navEntryLink)}
      onClick={() => {
        history.push(route);
      }}
    >
      <span className={css(styles.navEntry)}>{t(labelIdentifier)}</span>
    </span>
  );
}

const styles = StyleSheet.create({
  navEntryLink: {
    textDecoration: 'none',
    marginLeft: 10,
    marginRight: 10,
  },
  navEntry: {
    paddingBottom: 5,
    paddingTop: 5,
    color: FOOTER_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_NORMAL,
    cursor: 'pointer',
  },
});

const mobileStyles = StyleSheet.create({
  navEntryLink: {
    textDecoration: 'none',
    marginTop: 2,
    marginBottom: 2,
  },
  navEntry: {
    paddingBottom: 5,
    paddingTop: 5,
    color: FOOTER_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: '1.15em',
    cursor: 'pointer',
  },
});
