import Keycloak from 'keycloak-js';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';
import { delay } from './util/DelayUtil';
import { Role } from './containers/admin/Role';

const ACCESS_TOKEN_STORE_NAME = 'stored_access_token';
const REFRESH_TOKEN_STORE_NAME = 'stored_refresh_token';
const TIME_SKEW_STORE_NAME = 'stored_timeskew';
const ADMIN_ACCESS_TOKEN_STORE_NAME = 'stored_admin_access_token';
const ADMIN_REFRESH_TOKEN_STORE_NAME = 'stored_admin_refresh_token';
const ADMIN_TIME_SKEW_STORE_NAME = 'stored_admin_timeskew';
const ACCESS_TOKEN_MIN_VALIDITY_SECONDS = 70;

export const auth = new Keycloak({
  realm: 'rentd',
  url: 'https://' + process.env.REACT_APP_BASE_URL + '/auth',
  clientId: 'react-frontend',
});

export const adminAuth = new Keycloak({
  realm: 'rentd-admin',
  url: 'https://' + process.env.REACT_APP_BASE_URL + '/auth',
  clientId: 'react-frontend',
});

auth
  .init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri:
      window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    token: getAccessToken(),
    refreshToken: getRefreshToken(),
    timeSkew: getTimeSkew(),
  })
  .then((authenticated: boolean) => {
    if (authenticated) {
      storeTokenData();
    }
  });

adminAuth
  .init({
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    token: getAdminAccessToken(),
    refreshToken: getAdminRefreshToken(),
    timeSkew: getAdminTimeSkew(),
  })
  .then((authenticated: boolean) => {
    if (authenticated) {
      storeAdminTokenData();
    }
  });

export async function checkAuthenticated(
  callback: (authenticated: boolean) => void
) {
  // without delay the user would not be logged in after login-process
  await delay(300);

  try {
    auth
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
        token: getAccessToken(),
        refreshToken: getRefreshToken(),
        timeSkew: getTimeSkew(),
      })
      .then((authenticated: boolean) => {
        if (authenticated) {
          refreshToken((tokenValid: boolean) => {
            callback(tokenValid);
          });
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      });
  } catch (_error) {
    callback(false);
  }
}

export async function checkAdminAuthenticated(
  callback: (authenticated: boolean) => void
) {
  // without delay the user would not be logged in after login-process
  await delay(300);

  try {
    adminAuth
      .init({
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        token: getAdminAccessToken(),
        refreshToken: getAdminRefreshToken(),
        timeSkew: getAdminTimeSkew(),
      })
      .then((authenticated: boolean) => {
        if (authenticated) {
          refreshAdminToken((tokenValid: boolean) => {
            callback(tokenValid);
          });
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      });
  } catch (_error) {
    callback(false);
  }
}

export async function retrieveAccessTokenOrUndefined() {
  await refreshToken(() => {});
  return getAccessToken();
}

export async function retrieveAdminAccessTokenOrUndefined() {
  await refreshAdminToken(() => {});
  return getAdminAccessToken();
}

export async function initiateLogin() {
  await auth.init({
    onLoad: 'login-required',
    redirectUri: window.location.origin,
    silentCheckSsoRedirectUri:
      window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  });
}

export async function initiateAdminLogin() {
  await adminAuth.init({
    onLoad: 'login-required',
    redirectUri: window.location.origin,
    silentCheckSsoRedirectUri:
      window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  });
}

export async function initiateLogout() {
  clearTokenData();
  await auth.logout({ redirectUri: window.location.origin });
}

export async function initiateAdminLogout() {
  clearAdminTokenData();
  await adminAuth.logout({ redirectUri: window.location.origin });
}

/**
 * Construct the authorization header needed for authenticated requests.
 * @param token the access token
 */
export function getAuthorizationHeader(token: string) {
  return `Bearer ${token}`;
}

/**
 * Execute a REST call to the backend. Includes authorization information if user is authorized.
 * @param path the path after the backend base URL (e.g. secure/news)
 * @param fetchOptions options that should be passed to fetch(…)
 * @param isPostRequest specify, if the result should be make via POST or GET
 * @param parseResultAsJson specify, if the result should be parsed and returned as json
 * @param useAdminAccessToken specify, if we should use the admin access token
 */
export async function fetchBackend(
  path: string,
  fetchOptions?: RequestInit,
  isPostRequest: boolean = false,
  parseResultAsJson: boolean = true,
  useAdminAccessToken: boolean = false
) {
  let defaultHeaders: { [key: string]: string } = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': i18next.language,
    'Access-Control-Allow-Credentials': 'true',
  };

  let token;
  if (useAdminAccessToken) {
    token = await retrieveAdminAccessTokenOrUndefined();
  } else {
    token = await retrieveAccessTokenOrUndefined();
  }

  if (token) {
    defaultHeaders.Authorization = getAuthorizationHeader(token);
  }

  const defaultMethod = isPostRequest ? 'POST' : 'GET';
  const res = await fetch('api/' + path, {
    method: defaultMethod,
    ...fetchOptions,
    headers: {
      ...defaultHeaders,
      ...(fetchOptions ? fetchOptions.headers : {}),
    },
  });

  // If user is not authenticated anymore, re-login
  if (res.status === 401) {
    if (useAdminAccessToken) {
      initiateAdminLogin();
    } else {
      initiateLogin();
    }
    return null;
  }

  if (parseResultAsJson) {
    return await res.json();
  }

  return res;
}

function storeTokenData() {
  const token = auth.token;
  if (token) localStorage.setItem(ACCESS_TOKEN_STORE_NAME, token);

  const refreshToken = auth.refreshToken;
  if (refreshToken)
    localStorage.setItem(REFRESH_TOKEN_STORE_NAME, refreshToken);

  const timeSkew = auth.timeSkew;
  if (timeSkew) localStorage.setItem(TIME_SKEW_STORE_NAME, timeSkew.toString());
}

function storeAdminTokenData() {
  const token = adminAuth.token;
  if (token) localStorage.setItem(ADMIN_ACCESS_TOKEN_STORE_NAME, token);

  const refreshToken = adminAuth.refreshToken;
  if (refreshToken)
    localStorage.setItem(ADMIN_REFRESH_TOKEN_STORE_NAME, refreshToken);

  const timeSkew = adminAuth.timeSkew;
  if (timeSkew)
    localStorage.setItem(ADMIN_TIME_SKEW_STORE_NAME, timeSkew.toString());
}

async function refreshToken(callback: (tokenValid: boolean) => void) {
  await auth
    .updateToken(ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
    .then((refreshed) => {
      // If refreshed is true, token was refreshed, else token is still valid
      if (refreshed) {
        storeTokenData();
      }
      callback(true);
    })
    .catch(() => {
      clearTokenData();
      callback(false);
    });
}

async function refreshAdminToken(callback: (tokenValid: boolean) => void) {
  await adminAuth
    .updateToken(ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
    .then((refreshed) => {
      // If refreshed is true, token was refreshed, else token is still valid
      if (refreshed) {
        storeAdminTokenData();
      }
      callback(true);
    })
    .catch(() => {
      clearAdminTokenData();
      callback(false);
    });
}

function clearTokenData() {
  localStorage.removeItem(ACCESS_TOKEN_STORE_NAME);
  localStorage.removeItem(REFRESH_TOKEN_STORE_NAME);
  localStorage.removeItem(TIME_SKEW_STORE_NAME);
}

function clearAdminTokenData() {
  localStorage.removeItem(ADMIN_ACCESS_TOKEN_STORE_NAME);
  localStorage.removeItem(ADMIN_REFRESH_TOKEN_STORE_NAME);
  localStorage.removeItem(ADMIN_TIME_SKEW_STORE_NAME);
}

function getAccessToken(): string | undefined {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORE_NAME);
  if (accessToken) return accessToken;

  return undefined;
}

function getAdminAccessToken(): string | undefined {
  const accessToken = localStorage.getItem(ADMIN_ACCESS_TOKEN_STORE_NAME);
  if (accessToken) return accessToken;

  return undefined;
}

function getRefreshToken(): string | undefined {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORE_NAME);
  if (refreshToken) return refreshToken;

  return undefined;
}

function getAdminRefreshToken(): string | undefined {
  const refreshToken = localStorage.getItem(ADMIN_REFRESH_TOKEN_STORE_NAME);
  if (refreshToken) return refreshToken;

  return undefined;
}

function getTimeSkew(): number | undefined {
  const timeSkew = localStorage.getItem(TIME_SKEW_STORE_NAME);
  if (timeSkew) return parseFloat(timeSkew);

  return undefined;
}

function getAdminTimeSkew(): number | undefined {
  const timeSkew = localStorage.getItem(ADMIN_TIME_SKEW_STORE_NAME);
  if (timeSkew) return parseFloat(timeSkew);

  return undefined;
}

export function getAdminRoles(): Role[] {
  const accessToken = getAdminAccessToken();
  if (!accessToken) return [];

  const decodedAccessToken = jwt_decode(accessToken);
  const allRoles = (decodedAccessToken as any).realm_access.roles;
  const foundRoles: Role[] = [];
  if (allRoles.includes('view_system_information'))
    foundRoles.push('view_system_information');

  if (allRoles.includes('view_users')) foundRoles.push('view_users');

  if (allRoles.includes('view_properties')) foundRoles.push('view_properties');

  if (allRoles.includes('send_mail_to_all'))
    foundRoles.push('send_mail_to_all');

  return foundRoles;
}
