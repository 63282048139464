import React, { useState, useEffect } from 'react';
import Home from './public/Home';
import { Redirect } from 'react-router-dom';
import { PropertiesAmount } from '../server/model/property/PropertiesAmount';
import { fetchBackend } from '../authentication';
import { AppComponentsProps } from '../util/AppComponentsProps';

export type RootPageProps = {
  isAuthenticated: boolean;
  appComponentsProps: AppComponentsProps;
};

export default function RootPage(props: RootPageProps) {
  const [propertiesAmount, setPropertiesAmount] = useState<
    PropertiesAmount | undefined
  >(undefined);

  useEffect(() => {
    const getPropertiesAmount = async function () {
      const propertiesAmountResponse = await fetchBackend(
        'secure/property/getPropertiesAmount'
      );

      if (propertiesAmountResponse.success === true) {
        setPropertiesAmount(propertiesAmountResponse);
      }
    };
    if (propertiesAmount === undefined && props.isAuthenticated) {
      getPropertiesAmount();
    }
  });

  if (!props.isAuthenticated) {
    return <Home appComponentsProps={props.appComponentsProps} />;
  }

  if (propertiesAmount === undefined) {
    return null;
  }

  if (
    propertiesAmount.ownerAmount !== undefined &&
    propertiesAmount.renterAmount !== undefined
  ) {
    if (propertiesAmount.renterAmount > propertiesAmount.ownerAmount) {
      return <Redirect to="/renter" />;
    } else {
      return <Redirect to="/owner" />;
    }
  }
  return <Redirect to="/owner" />;
}
