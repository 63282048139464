import {
  centerBoundingBoxEvent,
  markerClickedEvent,
  lastUsedPropertyMarkersSourceId,
} from './MapComponent';

// objects for caching and keeping track of HTML marker objects (for performance)
var markers = {};
var markersOnScreen = {};

var clusterMarkers = {};
var clusterMarkersOnScreen = {};
var clusterMarkerIdsOnScreen = [];
var isCurrenltyDoingUpdate = false;

let lastClickedSingleMarkerId = undefined;

export function removeCachedMarkerAndClusters() {
  markers = {};
  markersOnScreen = {};
  clusterMarkers = {};
  clusterMarkers = {};
  clusterMarkersOnScreen = {};
  clusterMarkerIdsOnScreen = [];
  lastClickedSingleMarkerId = undefined;
}

export function updateMarkers(map, mapboxgl, eventBus) {
  if (isCurrenltyDoingUpdate) return;
  isCurrenltyDoingUpdate = true;

  var newMarkers = {};
  var propertyMarkers = map.querySourceFeatures(
    lastUsedPropertyMarkersSourceId,
  );

  const newClusterMarkerIds = [];
  var clusterAnswersReceived = 0;

  // Removes old cluster icon, but the function needs to be executed after all current cluster markers are drawn
  function removeOldClusterIcons() {
    clusterAnswersReceived++;
    if (clusterAnswersReceived < newClusterMarkerIds.length) return;

    clusterMarkerIdsOnScreen.forEach((clusterId) => {
      // Delete old cluster icon, which is not visible anymore
      if (!newClusterMarkerIds.includes(clusterId)) {
        clusterMarkersOnScreen[clusterId].remove();
        clusterMarkersOnScreen[clusterId] = null;
      }
    });
    clusterMarkerIdsOnScreen = newClusterMarkerIds;
    isCurrenltyDoingUpdate = false;
  }

  // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
  // and add it to the map if it's not there already
  for (var i = 0; i < propertyMarkers.length; i++) {
    var propertyMarker = propertyMarkers[i];
    var propertyMarkerProperties = propertyMarker.properties;
    var propertyMarkerCoordinates = propertyMarker.geometry.coordinates;
    if (!propertyMarkerProperties) return;

    if (propertyMarkerProperties.cluster) {
      // Cluster markers will be added async
      const clusterId = propertyMarkerProperties.cluster_id;
      if (!newClusterMarkerIds.includes(clusterId)) {
        // Sometimes the same clusterId is added multiple times. Prevent this!
        newClusterMarkerIds.push(clusterId);
        addClusterIcon(
          propertyMarkerProperties,
          propertyMarkerCoordinates,
          map,
          mapboxgl,
          eventBus,
          removeOldClusterIcons,
        );
      }
    } else {
      // Normal markers will be added sync
      var id = propertyMarkerProperties.id;
      var marker = markers[id];
      if (!marker) {
        marker = markers[id] = createNormalIcon(
          propertyMarkerProperties,
          propertyMarkerCoordinates,
          mapboxgl,
          eventBus,
        );
      }
      newMarkers[id] = marker;

      // Add marker to map, if it is not added already
      if (!markersOnScreen[id]) marker.addTo(map);
    }
  }

  // If there is currently no cluster, delete all old still shown cluster marker
  if (newClusterMarkerIds.length === 0) removeOldClusterIcons();

  // Remove normal marker, which are currently not visible
  for (id in markersOnScreen) {
    if (!newMarkers[id]) markersOnScreen[id].remove();
  }
  markersOnScreen = newMarkers;
}

function createNormalIcon(
  propertyMarkerProperties,
  propertyMarkerCoordinates,
  mapboxgl,
  eventBus,
) {
  const text = propertyMarkerProperties.userVisibleNumber.toString();
  const lengthOfString = text.length;
  const textTranslationX =
    lengthOfString === 1 ? 9.5 : lengthOfString === 2 ? 7.5 : 5;

  var el = document.createElement('div');
  el.className = 'marker';
  el.innerHTML = `<div class="svg"><svg width="54px" height="54px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"> <path d="M12,2C8.13,2 5,5.13 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.13 15.87,2 12,2Z" fill="${
    propertyMarkerProperties.color
  }"/><text dominant-baseline="central" style="font-size:9px;fill:white;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" transform="translate(${textTranslationX}, ${10})">${text}</text></svg></div>`;
  el.addEventListener('click', function() {
    eventBus.publish(
      markerClickedEvent({
        propertyId: propertyMarkerProperties.id,
        lastClickedPropertyId: lastClickedSingleMarkerId,
      }),
    );

    if (lastClickedSingleMarkerId === propertyMarkerProperties.id) {
      lastClickedSingleMarkerId = undefined;
    } else {
      lastClickedSingleMarkerId = propertyMarkerProperties.id;
    }
  });

  return new mapboxgl.Marker({
    element: el,
    options: { anchor: 'bottom' },
  }).setLngLat(propertyMarkerCoordinates);
}

// Adds a marker as a cluster icon
function addClusterIcon(
  clusterProperties,
  clusterCoordinates,
  map,
  mapboxgl,
  eventBus,
  removeOldClusterIconsCallback,
) {
  var clusterId = clusterProperties.cluster_id;
  var clusterSource = map.getSource(lastUsedPropertyMarkersSourceId);

  // Get all points of this cluster (Up to 1000)
  clusterSource.getClusterLeaves(clusterId, 1000, 0, function(
    _err,
    clusterPoints,
  ) {
    var clusterMarker = clusterMarkers[clusterId];
    // If the marker for this cluster is not created in the past, do it now
    if (!clusterMarker) {
      clusterMarker = createClusterIcon(
        clusterPoints,
        clusterCoordinates,
        clusterId,
        mapboxgl,
        eventBus,
      );
    }

    // If the cluster is currently not shown, show it
    if (!clusterMarkersOnScreen[clusterId]) {
      clusterMarker.addTo(map);
      clusterMarkersOnScreen[clusterId] = clusterMarker;
    }

    removeOldClusterIconsCallback();
  });
}

function createClusterIcon(
  clusterPoints,
  clusterCoordinates,
  clusterId,
  mapboxgl,
  eventBus,
) {
  var groupIds = [];
  var groups = {};

  var offsetPerGroup = [];
  var totalPoints = 0;

  // Group cluster points by their groups
  clusterPoints.forEach((leave) => {
    const groupId = leave.properties.groupId;

    // Get the data of the group of this point and add it to the list of groups, if its the first occurrence
    let oldGroupData = groups[groupId];
    if (!oldGroupData) {
      oldGroupData = { color: leave.properties.color, count: 0 };
      groupIds.push(groupId);
    }

    const newGroupData = {
      color: oldGroupData.color,
      count: oldGroupData.count + 1,
    };

    groups[groupId] = newGroupData;
  });

  for (var i = 0; i < groupIds.length; i++) {
    const group = groups[groupIds[i]];
    offsetPerGroup.push(totalPoints);
    totalPoints += group.count;
  }

  var fontSize = totalPoints >= 50 ? 22 : totalPoints >= 10 ? 20 : 18;
  var textCircleRadius = totalPoints >= 50 ? 15 : totalPoints >= 10 ? 15 : 12;
  var radius = totalPoints >= 50 ? 46 : totalPoints >= 10 ? 38 : 30;
  var radius0 = Math.round(radius * 0.3);
  var width = radius * 2;

  var html = `<div><svg width="${width}" height="${width}" viewbox="0 0 ${width} ${width}" text-anchor="middle" style="font-size: ${fontSize}px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;">`;

  // Add the partial stuff for all groups to the icon
  for (i = 0; i < groupIds.length; i++) {
    const group = groups[groupIds[i]];
    html += donutSegment(
      offsetPerGroup[i] / totalPoints,
      (offsetPerGroup[i] + group.count) / totalPoints,
      radius,
      radius0,
      group.color,
    );
  }

  var smallestLatitude = 90.0;
  var biggestLatitude = -90.0;
  var smallestLongitude = 180.0;
  var biggestLongitude = -180.0;
  clusterPoints.forEach((leave) => {
    var clusterPointCoordinates = leave.geometry.coordinates;
    if (clusterPointCoordinates[0] < smallestLongitude)
      smallestLongitude = clusterPointCoordinates[0];
    if (clusterPointCoordinates[0] > biggestLongitude)
      biggestLongitude = clusterPointCoordinates[0];
    if (clusterPointCoordinates[1] < smallestLatitude)
      smallestLatitude = clusterPointCoordinates[1];
    if (clusterPointCoordinates[1] > biggestLatitude)
      biggestLatitude = clusterPointCoordinates[1];
  });

  const positionTextX = 2 * radius - textCircleRadius;
  const positionTextY = textCircleRadius;

  // Add the inner stuff and text to the icon
  html += `<circle cx="${positionTextX}" cy="${positionTextY}" r="${textCircleRadius}" opacity="0.8" fill="#FFFFFF" /><text dominant-baseline="central" transform="translate(${positionTextX}, ${positionTextY})">${totalPoints.toLocaleString()}</text></svg></div>`;
  var el = document.createElement('div');
  el.innerHTML = html;
  el.addEventListener('click', function() {
    eventBus.publish(
      centerBoundingBoxEvent({
        switchToMapViewOnMobileDevice: false,
        upperLeft: { longitude: smallestLongitude, latitude: biggestLatitude },
        lowerRight: { longitude: biggestLongitude, latitude: smallestLatitude },
      }),
    );
  });

  return (clusterMarkers[clusterId] = new mapboxgl.Marker({
    element: el,
  }).setLngLat(clusterCoordinates));
}

// Draws a part of a circle with the specifed color
function donutSegment(start, end, r, r0, color) {
  if (end - start === 1) end -= 0.00001;
  var a0 = 2 * Math.PI * (start - 0.25);
  var a1 = 2 * Math.PI * (end - 0.25);
  var x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  var x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  var largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />',
  ].join(' ');
}
