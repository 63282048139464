import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import {
  ACCENT_COLOR,
  TABLE_BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../styling/colors';
import { fetchBackend } from '../../authentication';
import { MuiThemeProvider, Button } from '@material-ui/core';
import {
  PropertiesInfo,
  PropertyInfo,
  getReadableCreatedDate,
  getReadableLastChangedDate,
} from '../../server/model/admin/PropertiesInfo';
import { TFunction } from 'i18next';

export default function AdminViewProperties(_props: any) {
  const [propertiesInfo, setPropertiesInfo] = useState<
    PropertiesInfo | undefined
  >(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  useEffect(() => {
    const loadPropertiesInfo = async function () {
      const requestOptions = {
        method: 'GET',
      };
      setPropertiesInfo(
        await fetchBackend(
          'secure/admin/viewProperties',
          requestOptions,
          false,
          true,
          true
        )
      );
    };
    if (propertiesInfo === undefined) loadPropertiesInfo();
  });

  if (propertiesInfo === undefined) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ADMIN_VIEW_PROPERTIES_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_PROPERTIES_PAGE.AMOUNT_PROPERTIES')}
      </div>
      <div className={css(styles.text)}>{propertiesInfo.amountProperties}</div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES')}
      </div>
      <table className={css(styles.table)}>
        <thead>
          <tr>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_CITY')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_SIZE')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_CALCULATED_SIZE'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_AMOUNT_OWNERS')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_AMOUNT_RENTERS'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_AMOUNT_INVITED_OWNERS'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_AMOUNT_INVITED_RENTERS'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_POSITION')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_CREATED_DATE')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_CHANGED_DATE')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_PAYMENT_AMOUNT'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_PROPERTIES_PAGE.PROPERTIES_CAPTION_PAYMENT_INTERVAL'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {propertiesInfo.properties.map(
            (propertyInfo: PropertyInfo, index: number) => {
              return (
                <PropertyInfoView
                  t={t}
                  propertyInfo={propertyInfo}
                  key={'price-index-row-' + index}
                />
              );
            }
          )}
        </tbody>
      </table>

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <MuiThemeProvider theme={materialUiTheme}>
        <span onClick={() => history.goBack()}>
          <Button
            className={css(styles.secondaryButton)}
            variant="contained"
            color="secondary"
          >
            {t('GLOBAL.BACK')}
          </Button>
        </span>
      </MuiThemeProvider>
    </div>
  );
}

function PropertyInfoView(props: { t: TFunction; propertyInfo: PropertyInfo }) {
  return (
    <tr>
      <td className={css(styles.tableEntry)}>{props.propertyInfo.city}</td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.size +
          ' ' +
          props.t('AREA_TYPES.SQUARE_METER_SHORT')}
      </td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.calculatedSize +
          ' ' +
          props.t('AREA_TYPES.SQUARE_METER_SHORT')}
      </td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.amountOwners}
      </td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.amountRenters}
      </td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.amountInvitedOwners}
      </td>
      <td className={css(styles.tableEntry)}>
        {props.propertyInfo.amountInvitedRenters}
      </td>
      <td className={css(styles.tableEntry)}>
        {'Lat: ' +
          props.propertyInfo.center.latitude +
          ', Lon: ' +
          props.propertyInfo.center.longitude}
      </td>
      <td className={css(styles.tableEntry)}>
        {getReadableCreatedDate(props.propertyInfo)}
      </td>
      <td className={css(styles.tableEntry)}>
        {getReadableLastChangedDate(props.propertyInfo)}
      </td>
      {props.propertyInfo.currentPaymentAmount &&
        props.propertyInfo.currentCurrency && (
          <td className={css(styles.tableEntry)}>
            {props.propertyInfo.currentPaymentAmount +
              ' ' +
              props.propertyInfo.currentCurrency}
          </td>
        )}
      {props.propertyInfo.currentPaymentInterval && (
        <td className={css(styles.tableEntry)}>
          {props.propertyInfo.currentPaymentInterval}
        </td>
      )}
    </tr>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 20,
  },
  section: {
    ...defaultStyles.textStyle,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
  },
  tableHeader: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 20,
    verticalAlign: 'center',
  },
  tableEntry: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 5,
    minWidth: 20,
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
  },
});
