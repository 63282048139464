import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MuiThemeProvider, Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR, SECONDARY_TEXT_COLOR } from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { UserAccount } from '../../server/model/user/UserAccount';
import { fetchBackend, initiateLogout } from '../../authentication';
import { SMALL_INPUT_MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { delay } from '../../util/DelayUtil';

export default function AccountDelete(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const history = useHistory();
  const [info, setInfo] = useState<string | undefined>(undefined);
  const [userAccount, setUserAccount] = useState<UserAccount | undefined>(
    undefined
  );
  const [
    sendingAccountDeleteRequest,
    setSendingAccountDeleteRequest,
  ] = useState<boolean>(false);

  async function sendDeleteAccountRequest() {
    setSendingAccountDeleteRequest(true);
    const requestOptions = {
      method: 'POST',
      body: '{}',
    };
    const response = await fetchBackend(
      'secure/account/deleteAccount',
      requestOptions
    );

    if (response.success === false) {
      setSendingAccountDeleteRequest(false);
    }

    // We will stay in loading mode, show the info and logout after a few seconds
    if (response.success === true) {
      setInfo(t('ACCOUNT_DELETE_PAGE.SUCCESSFUL'));
      await delay(1500);
      initiateLogout();
    }
  }

  useEffect(() => {
    const getAccountData = async function () {
      const userData = await fetchBackend('secure/account/data');

      if (userData.success === true) {
        setUserAccount(userData.data);
      }
    };
    if (userAccount === undefined) getAccountData();
  });

  if (userAccount === undefined) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ACCOUNT_DELETE_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <div
        dangerouslySetInnerHTML={{
          __html: t('ACCOUNT_DELETE_PAGE.INFO'),
        }}
        className={css(styles.hint)}
      />
      <div className={css(styles.spacing)} />
      <form>
        <MuiThemeProvider theme={materialUiTheme}>
          <div className={css(styles.spacing)} />
          <Button
            className={css(styles.primaryButton)}
            variant="contained"
            disabled={sendingAccountDeleteRequest}
            onClick={() => {
              sendDeleteAccountRequest();
            }}
            color="primary"
          >
            {sendingAccountDeleteRequest && (
              <CircularProgress color={'secondary'} size={22} />
            )}
            {!sendingAccountDeleteRequest && t('ACCOUNT_DELETE_PAGE.BUTTON')}
          </Button>
          <span onClick={() => history.goBack()}>
            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              color="secondary"
            >
              {t('GLOBAL.CANCEL')}
            </Button>
          </span>
        </MuiThemeProvider>
      </form>
      <div className={css(styles.spacing)} />
      <span className={css(styles.text)}>{info}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: SMALL_INPUT_MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 35,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    marginLeft: 15,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  hint: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR,
    textAlign: 'justify',
  },
});
