import turfArea from '@turf/area';

import { LatLng } from '../server/model/property/LatLng';
import {
  AreaType,
  convertFromSquareMeter,
} from '../server/model/user/UserSettings';

export function getAreaSizeInGivenAreaType(
  coordinates: LatLng[],
  areaType: AreaType
) {
  if (coordinates.length === 0) return 0;

  const positions = coordinates.map((latLng: LatLng) => [
    latLng.longitude,
    latLng.latitude,
  ]);

  const areaInSquareMeter = turfArea({
    coordinates: [positions],
    type: 'Polygon',
  });

  return convertFromSquareMeter(areaInSquareMeter, areaType);
}
