import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import SwipeableViews from 'react-swipeable-views';
import MetaTags from 'react-meta-tags';

import { defaultStyles, materialUiTheme } from '../../styling/styles';
import {
  ACCOUNT_MAX_WIDTH_CONTENT,
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../../util/AppComponentsProps';
import {
  TABLE_BORDER_COLOR,
  INDEX_COLOR_UNMARKED,
  ACCENT_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../styling/colors';
import { MuiThemeProvider, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  TEXT_SIZE_SLIGHTLY_BIGGER,
  TEXT_SIZE_VERY_BIG,
  TEXT_SIZE_SMALL,
} from '../../styling/dimens';
import { TFunction } from 'i18next';
import { Subscription } from '../../server/model/payment/Subscription';
import { fetchBackend } from '../../authentication';

const labels = [
  'PRICE_PAGE.ENTRY_ONE_LABEL',
  'PRICE_PAGE.ENTRY_TWO_LABEL',
  'PRICE_PAGE.ENTRY_THREE_LABEL',
  'PRICE_PAGE.ENTRY_FOUR_LABEL',
  'PRICE_PAGE.ENTRY_FIVE_LABEL',
  'PRICE_PAGE.ENTRY_SIX_LABEL',
];
const valuesFree = [
  'PRICE_PAGE.ENTRY_ONE_FREE',
  'PRICE_PAGE.ENTRY_TWO_FREE',
  'PRICE_PAGE.ENTRY_THREE_FREE',
  'PRICE_PAGE.ENTRY_FOUR_FREE',
  'PRICE_PAGE.ENTRY_FIVE_FREE',
  'PRICE_PAGE.ENTRY_SIX_FREE',
];
const valuesPremium = [
  'PRICE_PAGE.ENTRY_ONE_PREMIUM',
  'PRICE_PAGE.ENTRY_TWO_PREMIUM',
  'PRICE_PAGE.ENTRY_THREE_PREMIUM',
  'PRICE_PAGE.ENTRY_FOUR_PREMIUM',
  'PRICE_PAGE.ENTRY_FIVE_PREMIUM',
  'PRICE_PAGE.ENTRY_SIX_PREMIUM',
];
const maxIndices = 2;

export type PriceProps = {
  isAuthenticated: boolean;
  appComponentsProps: AppComponentsProps;
};

export default function Price(props: PriceProps) {
  const [mobileIndex, setMobileIndex] = useState<number>(0);
  const [hasSubscriptionData, setHasSubscriptionData] = useState<
    Boolean | undefined
  >(undefined);
  const [subscription, setSubscription] = useState<Subscription | undefined>(
    undefined
  );

  const { t } = useTranslation();
  const history = useHistory();
  document.title = t('GLOBAL.PRICE_TITLE');

  useEffect(() => {
    async function initialLoadSubscriptionData() {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ updateDataViaProvider: false }),
      };
      const subscriptionData = await fetchBackend(
        'secure/payment/getSubscriptionDetails',
        requestOptions
      );

      if (subscriptionData.success === true) {
        setHasSubscriptionData(true);
        if (subscriptionData.subscription) {
          setSubscription(subscriptionData.subscription);
        }
      } else {
        setHasSubscriptionData(false);
      }
    }

    if (hasSubscriptionData === undefined) {
      if (props.isAuthenticated) initialLoadSubscriptionData();
      else setHasSubscriptionData(false);
    }
  }, [hasSubscriptionData, props.isAuthenticated]);

  if (hasSubscriptionData === undefined) {
    return null;
  }

  const mobileScreen =
    props.appComponentsProps.windowWidth <= MAX_WIDTH_MOBILE_PHONE;
  const hasPremiumAbo = subscription
    ? subscription.status === 'ACTIVE' || subscription.status === 'PAUSED'
    : false;

  const metaTag = (
    <MetaTags>
      <meta name="description" content={t('GLOBAL.PRICE_DESCRIPTION')} />
    </MetaTags>
  );

  if (mobileScreen) {
    const indexMarker = [];
    for (var i = 0; i < maxIndices; i++) {
      const value = i;
      const marker =
        value === mobileIndex ? (
          <div
            key={'mobile-index-' + value}
            onClick={() => {
              setMobileIndex(value);
            }}
            className={css(styles.indexCircleMarked)}
          />
        ) : (
          <div
            key={'mobile-index-' + value}
            onClick={() => {
              setMobileIndex(value);
            }}
            className={css(styles.indexCircle)}
          />
        );
      indexMarker.push(marker);
    }

    return (
      <div className={css(styles.container)}>
        {metaTag}
        <span className={css(styles.header)}>
          <div>{t('PRICE_PAGE.HEADER')}</div>
        </span>
        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />
        <div className={css(styles.indexCircleContainer)}>{indexMarker}</div>
        <div className={css(styles.smallSpacing)} />

        <SwipeableViews
          className={css(
            StyleSheet.create({
              style: {
                maxWidth: '100%',
              },
            }).style
          )}
          enableMouseEvents
          index={mobileIndex}
          containerStyle={{ maxWidth: '100%' }}
          slideStyle={{ justifyContent: 'center', display: 'flex' }}
          onChangeIndex={(index: number) => {
            setMobileIndex(index);
          }}
        >
          <MobileTableEntry
            key={'price-index-0'}
            tableNumber={0}
            history={history}
            isAuthenticated={props.isAuthenticated}
            hasPremiumAbo={hasPremiumAbo}
            t={t}
          />
          <MobileTableEntry
            key={'price-index-1'}
            tableNumber={1}
            history={history}
            isAuthenticated={props.isAuthenticated}
            hasPremiumAbo={hasPremiumAbo}
            t={t}
          />
        </SwipeableViews>
        <div className={css(styles.spacing)} />
        <div className={css(styles.spacing)} />

        <div className={css(styles.caption)}>
          {t('PRICE_PAGE.STAR_INFO_ONE')}
        </div>
        <div className={css(styles.smallSpacing)} />
        <div className={css(styles.caption)}>
          {t('PRICE_PAGE.STAR_INFO_TWO')}
        </div>
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      {metaTag}
      <span className={css(styles.header)}>
        <div>{t('PRICE_PAGE.HEADER')}</div>
      </span>
      <div className={css(styles.spacing)} />
      <table className={css(styles.table)}>
        <thead>
          <tr>
            <th className={css(styles.tableHeader)}></th>
            <th className={css(styles.tableHeader)}>
              <HeaderEntry
                tableNumber={0}
                history={history}
                isAuthenticated={props.isAuthenticated}
                hasPremiumAbo={hasPremiumAbo}
                t={t}
              />
            </th>
            <th className={css(styles.tableHeader)}>
              <HeaderEntry
                tableNumber={1}
                history={history}
                isAuthenticated={props.isAuthenticated}
                hasPremiumAbo={hasPremiumAbo}
                t={t}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label: string, index: number) => {
            return (
              <tr key={'price-index-row-' + index}>
                <td className={css(styles.tableEntry)}>{t(label)}</td>
                <ValueEntry textIdentifier={valuesFree[index]} t={t} />
                <ValueEntry textIdentifier={valuesPremium[index]} t={t} />
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />

      <div className={css(styles.caption)}>{t('PRICE_PAGE.STAR_INFO_ONE')}</div>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.caption)}>{t('PRICE_PAGE.STAR_INFO_TWO')}</div>
    </div>
  );
}

function MobileTableEntry(props: {
  tableNumber: number;
  history: any;
  isAuthenticated: boolean;
  hasPremiumAbo: boolean;
  t: TFunction;
}) {
  const tableData = props.tableNumber === 0 ? valuesFree : valuesPremium;

  return (
    <table className={css(styles.table)}>
      <thead>
        <tr>
          <th colSpan={2} className={css(styles.tableHeader)}>
            <HeaderEntry
              tableNumber={props.tableNumber}
              history={props.history}
              isAuthenticated={props.isAuthenticated}
              hasPremiumAbo={props.hasPremiumAbo}
              t={props.t}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {labels.map((label: string, index: number) => {
          return (
            <tr key={'price-index-row-' + index}>
              <td className={css(styles.tableEntry)}>{props.t(label)}</td>
              <ValueEntry textIdentifier={tableData[index]} t={props.t} />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ValueEntry(props: { textIdentifier: string; t: TFunction }) {
  const text = props.t(props.textIdentifier);

  // Normal text, simply return the text
  if (!text.includes('*')) {
    return <td className={css(styles.tableEntry)}>{text}</td>;
  }

  const indexOfStar = text.indexOf('*');
  const content = text.substring(0, indexOfStar).trim();
  const starText = text.substring(indexOfStar, text.length).trim();
  return (
    <td className={css(styles.tableEntry)}>
      {content}
      <span className={css(styles.tableStarEntry)}>{starText}</span>
    </td>
  );
}

function HeaderEntry(props: {
  tableNumber: number;
  history: any;
  isAuthenticated: boolean;
  hasPremiumAbo: boolean;
  t: TFunction;
}) {
  const aboType =
    props.tableNumber === 0
      ? props.t('PRICE_PAGE.ABO_TYPE_FREE')
      : props.t('PRICE_PAGE.ABO_TYPE_PREMIUM');
  const priceInfo =
    props.tableNumber === 0
      ? props.t('PRICE_PAGE.ABO_TYPE_FREE_PRICE')
      : props.t('PRICE_PAGE.ABO_TYPE_PREMIUM_PRICE');

  const showButton = props.isAuthenticated
    ? props.tableNumber === 1 && !props.hasPremiumAbo
    : true;
  const buttonContent = props.isAuthenticated
    ? props.t('PRICE_PAGE.BUTTON_BOOK_PREMIUM')
    : props.t('PRICE_PAGE.BUTTON_REGISTER');
  const route = props.isAuthenticated ? '/accountpayment' : '/register';

  return (
    <div className={css(styles.headerContainer)}>
      <span className={css(styles.aboTypeInfo)}>{aboType}</span>
      <span className={css(styles.priceInfo)}>{priceInfo}</span>
      {showButton ? (
        <MuiThemeProvider theme={materialUiTheme}>
          <Button
            onClick={() => {
              props.history.push(route);
            }}
            className={css(styles.buttonStyle)}
            variant="contained"
            color="primary"
          >
            {buttonContent}
          </Button>
        </MuiThemeProvider>
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: ACCOUNT_MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
  },
  indexCircleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  indexCircle: {
    cursor: 'pointer',
    margin: 4,
    borderRadius: 24,
    width: 12,
    height: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: INDEX_COLOR_UNMARKED,
  },
  indexCircleMarked: {
    cursor: 'pointer',
    margin: 4,
    borderRadius: 24,
    width: 12,
    height: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ACCENT_COLOR,
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 20,
  },
  buttonStyle: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
    textDecoration: 'none',
    maxWidth: 150,
    alignSelf: 'center',
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
  },
  tableHeader: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 180,
    verticalAlign: 'top',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  aboTypeInfo: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    marginBottom: 4,
  },
  priceInfo: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    fontWeight: 'normal',
    marginBottom: 25,
  },
  tableEntry: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 20,
    minWidth: 70,
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
  },
  tableStarEntry: {
    top: '-.5em',
    position: 'relative',
    fontSize: '75%',
  },
  caption: {
    ...defaultStyles.textStyle,
    textAlign: 'center',
    fontSize: TEXT_SIZE_SMALL,
    color: SECONDARY_TEXT_COLOR,
  },
});
