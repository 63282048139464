import React from 'react';

import { fetchBackend } from '../../authentication';
import { useTranslation } from 'react-i18next';
import {
  Payment,
  getReadableDate,
  getReadableDateRange,
  getHumanReadableProviderWithPaymentMethod,
} from '../../server/model/payment/Payment';
import { defaultStyles } from '../../styling/styles';
import { css, StyleSheet } from 'aphrodite';
import { TEXT_COLOR_LINK, PRIMARY_TEXT_COLOR } from '../../styling/colors';
import { TEXT_SIZE_SMALL } from '../../styling/dimens';

export type SinglePaymentComponentProps = {
  payment: Payment;
};

export default function SinglePaymentComponent(
  props: SinglePaymentComponentProps
) {
  const { t } = useTranslation();

  async function downloadInvoice() {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ paymentId: props.payment.id }),
    };
    const invoiceResponse = await fetchBackend(
      'secure/payment/invoice',
      requestOptions,
      true,
      false
    );
    if (invoiceResponse.status === 200) {
      const filename = invoiceResponse.headers
        .get('Content-Disposition')
        .split('filename=')[1];

      invoiceResponse.blob().then((blob: any) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    }
  }

  const fullAmount = props.payment.amount;
  const taxAmount = props.payment.taxAmount;
  const netAmount = fullAmount - taxAmount;

  return (
    <div>
      <div className={css(styles.firstLineContainer)}>
        <span
          className={css(styles.link)}
          onClick={() => {
            downloadInvoice();
          }}
        >
          {getReadableDate(props.payment)}
        </span>
        <span className={css(styles.amountText)}>
          {fullAmount + ' ' + props.payment.currency}
        </span>
      </div>

      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.text)}>{t('SINGLE_PAYMENT.ABO_NAME')}</div>
      <div className={css(styles.text)}>
        {getReadableDateRange(props.payment)}
      </div>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.text)}>
        {t('SINGLE_PAYMENT.PAID_VIA') +
          getHumanReadableProviderWithPaymentMethod(t, props.payment)}
      </div>
      <div className={css(styles.text)}>
        {taxAmount > 0 ? netAmount + ' ' + props.payment.currency : ''}
        {taxAmount > 0 ? (
          <span className={css(styles.greyedOutText)}>
            {' (+' +
              taxAmount +
              ' ' +
              props.payment.currency +
              ' ' +
              t('SINGLE_PAYMENT.TAX') +
              ')'}
          </span>
        ) : null}
      </div>
      <div className={css(styles.divider)} />
    </div>
  );
}

const styles = StyleSheet.create({
  firstLineContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
  divider: {
    width: '100%',
    height: 1.0,
    opacity: 0.25,
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: PRIMARY_TEXT_COLOR,
  },
  smallSpacing: {
    height: 5,
  },
  text: {
    ...defaultStyles.textStyle,
    textSize: TEXT_SIZE_SMALL,
  },
  greyedOutText: {
    ...defaultStyles.textStyle,
    textSize: TEXT_SIZE_SMALL,
    opacity: 0.5,
  },
  amountText: {
    ...defaultStyles.textStyle,
    fontWeight: 'bold',
  },
  link: {
    ...defaultStyles.textStyle,
    color: TEXT_COLOR_LINK,
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});
