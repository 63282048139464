import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultStyles } from '../../styling/styles';
import { MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import {
  TEXT_SIZE_VERY_BIG,
  TEXT_SIZE_SLIGHTLY_BIGGER,
} from '../../styling/dimens';
import { ACCENT_COLOR, TEXT_COLOR_LINK } from '../../styling/colors';
import { getAdminRoles } from '../../authentication';

export default function AdminOverview(_props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const adminRoles = getAdminRoles();
  let linksView = [];
  if (adminRoles.includes('view_system_information')) {
    linksView.push(
      <div key="sysInfo">
        <div className={css(styles.spacing)} />
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/adminSystemInformation');
          }}
        >
          {t('ADMIN_PAGE.ACTION_SYSTEM_INFORMATION')}
        </span>
      </div>
    );
  }

  if (adminRoles.includes('view_users')) {
    linksView.push(
      <div key="showUser">
        <div className={css(styles.spacing)} />
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/adminViewUsers');
          }}
        >
          {t('ADMIN_PAGE.ACTION_SHOW_USER')}
        </span>
      </div>
    );
  }

  if (adminRoles.includes('view_properties')) {
    linksView.push(
      <div key="showProperties">
        <div className={css(styles.spacing)} />
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/adminViewProperties');
          }}
        >
          {t('ADMIN_PAGE.ACTION_SHOW_PROPERTIES')}
        </span>
      </div>
    );
  }

  if (adminRoles.includes('send_mail_to_all')) {
    linksView.push(
      <div key="sendMail">
        <div className={css(styles.spacing)} />
        <span
          className={css(styles.link)}
          onClick={() => {
            history.push('/adminSendMailAll');
          }}
        >
          {t('ADMIN_PAGE.ACTION_SEND_MAIL')}
        </span>
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ADMIN_PAGE.TITLE')}</div>
      </span>
      <div className={css(styles.spacing)} />

      {linksView.map((view) => view)}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 20,
  },
  link: {
    ...defaultStyles.textStyle,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
    color: TEXT_COLOR_LINK,
  },
});
