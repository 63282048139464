import i18next from 'i18next';
import { LatLng } from '../property/LatLng';
import { Currency, PaymentInterval } from '../property/Property';

export type PropertyInfo = {
  amountOwners: number;
  amountRenters: number;
  amountInvitedOwners: number;
  amountInvitedRenters: number;
  size: number; // In qm
  calculatedSize: number; // In qm
  center: LatLng;
  createdDate: string;
  lastChangedDate: string;
  city: string;
  currentPaymentAmount?: number;
  currentCurrency?: Currency;
  currentPaymentInterval?: PaymentInterval;
};

export type PropertiesInfo = {
  amountProperties: number;
  properties: PropertyInfo[];
};

export function getReadableCreatedDate(propertyInfo: PropertyInfo) {
  const date = new Date(Date.parse(propertyInfo.createdDate));
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getReadableLastChangedDate(propertyInfo: PropertyInfo) {
  const date = new Date(Date.parse(propertyInfo.lastChangedDate));
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}
