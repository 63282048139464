import React from 'react';
import { EventBus } from 'ts-bus';
import { Property } from '../../../server/model/property/Property';
import SingleNonEditablePaymentDetailComponent from './SingleNonEditablePaymentDetailComponent';
import SingleEditablePaymentDetailComponent from './SingleEditablePaymentDetailComponent';
import { UserSettings } from '../../../server/model/user/UserSettings';

export type SinglePaymentDetailComponentProps = {
  goBack: () => void;
  eventBus: EventBus;
  owner: boolean;
  userSettings: UserSettings;
  property: Property;
  groupColor: string;
};

export default function SinglePaymentDetailComponent(
  singlePaymentDetailComponentProps: SinglePaymentDetailComponentProps
) {
  if (singlePaymentDetailComponentProps.property.changeDataPossible) {
    return (
      <SingleEditablePaymentDetailComponent
        goBack={singlePaymentDetailComponentProps.goBack}
        eventBus={singlePaymentDetailComponentProps.eventBus}
        owner={singlePaymentDetailComponentProps.owner}
        userSettings={singlePaymentDetailComponentProps.userSettings}
        property={singlePaymentDetailComponentProps.property}
        groupColor={singlePaymentDetailComponentProps.groupColor}
      />
    );
  }
  return (
    <SingleNonEditablePaymentDetailComponent
      goBack={singlePaymentDetailComponentProps.goBack}
      eventBus={singlePaymentDetailComponentProps.eventBus}
      owner={singlePaymentDetailComponentProps.owner}
      property={singlePaymentDetailComponentProps.property}
      groupColor={singlePaymentDetailComponentProps.groupColor}
    />
  );
}
