import { TFunction } from 'i18next';

export type CpuLoad = {
  usageLastMinute: number;
  usageLastFiveMinutes: number;
  usageLastFifteenMinutes: number;
};

export type DiskInfo = {
  label: string;
  freeSpace: number;
  totalSpace: number;
};

export type SystemInformation = {
  processorInfo: string;
  systemUpTime: number;
  cpuLoad: CpuLoad;
  ramInfo: string;
  disks: DiskInfo[];
};

export function getReadableUpTime(
  systemInformation: SystemInformation,
  t: TFunction
) {
  const minutes = Math.floor(
    (systemInformation.systemUpTime / (1000 * 60)) % 60
  );
  const hours = Math.floor(
    (systemInformation.systemUpTime / (1000 * 60 * 60)) % 24
  );
  const days = Math.floor(
    systemInformation.systemUpTime / (1000 * 60 * 60 * 24)
  );

  return (
    days +
    ' ' +
    t('TIME_INFO.DAYS') +
    ', ' +
    hours +
    ' ' +
    t('TIME_INFO.HOURS') +
    ', ' +
    minutes +
    ' ' +
    t('TIME_INFO.MINUTES')
  );
}
