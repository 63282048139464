import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import {
  ACCENT_COLOR,
  TABLE_BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../styling/colors';
import { fetchBackend } from '../../authentication';
import { MuiThemeProvider, Button } from '@material-ui/core';
import {
  UsersInfo,
  UserInfo,
  getReadableRegisteredDate,
  getReadableSubscriptionCreatedDate,
  getReadableSubscriptionValidUntilDate,
} from '../../server/model/admin/UsersInfo';

export default function AdminViewUsers(_props: any) {
  const [usersInfo, setUsersInfo] = useState<UsersInfo | undefined>(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  useEffect(() => {
    const loadUsersInfo = async function () {
      const requestOptions = {
        method: 'GET',
      };
      setUsersInfo(
        await fetchBackend(
          'secure/admin/viewUsers',
          requestOptions,
          false,
          true,
          true
        )
      );
    };
    if (usersInfo === undefined) loadUsersInfo();
  });

  if (usersInfo === undefined) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ADMIN_VIEW_USERS_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_USERS_PAGE.AMOUNT_USERS')}
      </div>
      <div className={css(styles.text)}>{usersInfo.amountUsers}</div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_USERS_PAGE.AMOUNT_PAID_USERS')}
      </div>
      <div className={css(styles.text)}>
        {usersInfo.amountCurrentlyPaidUsers}
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_USERS_PAGE.AMOUNT_UNCOMMON_PAID_STATUS_USERS')}
      </div>
      <div className={css(styles.text)}>
        {usersInfo.amountUncommonPaidStatusUsers}
      </div>

      <div className={css(styles.spacing)} />
      <div className={css(styles.section)}>
        {t('ADMIN_VIEW_USERS_PAGE.USERS')}
      </div>
      <table className={css(styles.table)}>
        <thead>
          <tr>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_NAME')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_REGISTRATION')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_AMOUNT_OWNER_PROPERTIES')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_AMOUNT_RENTER_PROPERTIES'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_PAYMENT_METHOD')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_SUBSCRIPTION_STATUS')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_PAYMENT_PROVIDER')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_PROVIDER_STATUS')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_PROVIDER_SUBSCRIPTION_ID'
              )}
            </th>
            <th className={css(styles.tableHeader)}>
              {t('ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_SUBSCRIPTION_CREATED')}
            </th>
            <th className={css(styles.tableHeader)}>
              {t(
                'ADMIN_VIEW_USERS_PAGE.USERS_CAPTION_SUBSCRIPTION_VALID_UNTIL'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {usersInfo.users.map((user: UserInfo, index: number) => {
            return (
              <UserInfoView user={user} key={'price-index-row-' + index} />
            );
          })}
        </tbody>
      </table>

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <MuiThemeProvider theme={materialUiTheme}>
        <span onClick={() => history.goBack()}>
          <Button
            className={css(styles.secondaryButton)}
            variant="contained"
            color="secondary"
          >
            {t('GLOBAL.BACK')}
          </Button>
        </span>
      </MuiThemeProvider>
    </div>
  );
}

function UserInfoView(props: { user: UserInfo }) {
  const tableEntryStyle = props.user.userPaymentInfo
    ? props.user.userPaymentInfo.status === 'INACTIVE'
      ? styles.tableEntry
      : props.user.userPaymentInfo.status === 'ACTIVE'
      ? styles.tableEntrySubscriptionActive
      : props.user.userPaymentInfo.status === 'PAUSED'
      ? styles.tableEntrySubscriptionPaused
      : styles.tableEntrySubscriptionFailure
    : styles.tableEntry;

  return (
    <tr>
      <td className={css(tableEntryStyle)}>{props.user.email}</td>
      <td className={css(tableEntryStyle)}>
        {getReadableRegisteredDate(props.user)}
      </td>
      <td className={css(tableEntryStyle)}>
        {props.user.amountOwnerProperties}
      </td>
      <td className={css(tableEntryStyle)}>
        {props.user.amountRenterProperties}
      </td>
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {props.user.userPaymentInfo.paymentMethod}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {props.user.userPaymentInfo.status}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {props.user.userPaymentInfo.provider}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {props.user.userPaymentInfo.providerStatus}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {props.user.userPaymentInfo.providerSubscriptionId}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {getReadableSubscriptionCreatedDate(props.user.userPaymentInfo)}
        </td>
      )}
      {props.user.userPaymentInfo && (
        <td className={css(tableEntryStyle)}>
          {getReadableSubscriptionValidUntilDate(props.user.userPaymentInfo)}
        </td>
      )}
    </tr>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 20,
  },
  section: {
    ...defaultStyles.textStyle,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
  },
  tableHeader: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 20,
    verticalAlign: 'center',
  },
  tableEntry: {
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 5,
    minWidth: 20,
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
  },
  tableEntrySubscriptionFailure: {
    color: '#990000',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 5,
    minWidth: 20,
    textAlign: 'center',
  },
  tableEntrySubscriptionPaused: {
    color: '#999900',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 5,
    minWidth: 20,
    textAlign: 'center',
  },
  tableEntrySubscriptionActive: {
    color: '#009900',
    border: '1px solid',
    borderColor: TABLE_BORDER_COLOR,
    padding: 5,
    minWidth: 20,
    textAlign: 'center',
  },
});
