import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import MetaTags from 'react-meta-tags';
import { TEXT_SIZE_BIG, TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { defaultStyles } from '../../styling/styles';
import { useTranslation } from 'react-i18next';

export default function Terms(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TERMS_OF_USE_TITLE');

  window.scrollTo(0, 0);

  return (
    <div className={css(styles.container)}>
      <MetaTags>
        <meta
          name="description"
          content={t('GLOBAL.TERMS_OF_USE_DESCRIPTION')}
        />
      </MetaTags>
      <span className={css(styles.header)}>
        <div>{t('TERMS_OF_USE.HEADER')}</div>
      </span>
      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION1_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION1_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION1_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION1_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION2_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION2_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION2_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION2_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION2_TEXT4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION2_TEXT5', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION3_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION3_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION3_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION3_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION3_TEXT4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION4_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION4_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION4_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION4_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION4_TEXT4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION4_TEXT5', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION5_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION5_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION5_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION6_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION6_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION6_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION6_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION7_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT1')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT2')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT3')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT4')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT5')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION7_TEXT6')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION8_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION8_TEXT1')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION8_TEXT2')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION8_TEXT3')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION9_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION9_TEXT1')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION9_TEXT2')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION9_TEXT3')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION9_TEXT4')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION9_TEXT5')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION10_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT1')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT2')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT3')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT4')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT5')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT6')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT7')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT8')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION10_TEXT9')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION11_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION11_TEXT1')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION11_TEXT2')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION11_TEXT3')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION12_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.SECTION12_TEXT1')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('TERMS_OF_USE.SECTION13_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION13_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION13_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION13_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION13_TEXT4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('TERMS_OF_USE.SECTION13_TEXT5', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.VERSION')}</div>
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 60,
  },
  section: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_BIG,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
});
