import React from 'react';
import { AppComponentsProps } from '../../util/AppComponentsProps';
import PropertiesPage from './PropertiesPage';
import { useTranslation } from 'react-i18next';

export type RenterPropertiesPageProps = {
  appComponentsProps: AppComponentsProps;
};

export default function RenterPropertiesPage(
  renterPropertiesPageProps: RenterPropertiesPageProps
) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  return (
    <PropertiesPage
      appComponentsProps={renterPropertiesPageProps.appComponentsProps}
      owner={false}
    />
  );
}
