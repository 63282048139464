import React, { useState, useEffect, Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField, CircularProgress } from '@material-ui/core';
import { fetchBackend } from '../../authentication';
import { LatLng } from '../../server/model/property/LatLng';
import { useTranslation } from 'react-i18next';

const MIN_TIME_DIFFERENCE_SEARCH_AGAIN = 200;
const MAX_AUTOCOMPLETE_RESULTS = 5;

// Init a timeout variable to be used below
let searchCall: NodeJS.Timeout | null = null;

export type GeocodeResult = {
  visualName: string;
  boundingBox: {
    upperLeft: LatLng;
    lowerRight: LatLng;
  };
};

export type GeocoderAutoCompleteProps = {
  disabled: boolean;
  clickedGeocodeResult: (geocodeResult: GeocodeResult) => void;
};

export default function GeocoderAutoComplete(
  geocoderAutoCompleteProps: GeocoderAutoCompleteProps
) {
  const [searchString, setSearchString] = useState('');
  const [searching, setSearching] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [resultList, setResultList] = useState<GeocodeResult[]>([]);

  const { t } = useTranslation();
  const loading = open && searching;

  useEffect(() => {
    let active = true;

    // stop last searchCall and create a new one
    if (searchCall) {
      clearTimeout(searchCall);
    }

    searchCall = setTimeout(function () {
      updateData();
    }, MIN_TIME_DIFFERENCE_SEARCH_AGAIN);

    async function updateData() {
      if (searchString) {
        setSearching(true);
        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
            searchInput: searchString,
          }),
        };
        const response = await fetchBackend(
          'secure/map/geocode',
          requestOptions
        );
        setSearching(false);
        if (active && response.success) {
          const results = response.results.map((result: any) => {
            return {
              visualName: result.displayName,
              boundingBox: {
                upperLeft: result.boundingBoxUpperLeft,
                lowerRight: result.boundingBoxLowerRight,
              },
            };
          });

          setResultList(results);
        }
      } else {
        setResultList([]);
      }
    }

    return () => {
      active = false;
    };
  }, [searchString]);

  useEffect(() => {
    if (!open) {
      setResultList([]);
    }
  }, [open]);

  const filterOptions = (data: GeocodeResult[]) =>
    data.slice(0, MAX_AUTOCOMPLETE_RESULTS);

  return (
    <Autocomplete
      filterOptions={filterOptions}
      disabled={geocoderAutoCompleteProps.disabled}
      forcePopupIcon={false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_index, selectedItem) => {
        if (selectedItem) {
          geocoderAutoCompleteProps.clickedGeocodeResult(selectedItem);
        }
      }}
      getOptionLabel={(geocodeResult) => geocodeResult.visualName}
      autoComplete={false}
      options={resultList}
      noOptionsText={t('GEOCODER.NO_RESULTS')}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          type={'search'}
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
          label={t('GEOCODER.SEARCH_HINT')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
