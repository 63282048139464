import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CheckMark from '@material-ui/icons/CheckCircleRounded';
import {
  TextField,
  MuiThemeProvider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TEXT_SIZE_VERY_BIG, TEXT_SIZE_SMALL } from '../../styling/dimens';
import {
  ACCENT_COLOR,
  SECONDARY_TEXT_COLOR,
  SECONDARY_BUTTON_COLOR,
} from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { UserAccount } from '../../server/model/user/UserAccount';
import { fetchBackend } from '../../authentication';
import {
  MAX_WIDTH_CONTENT,
  SMALL_INPUT_MAX_WIDTH_CONTENT,
} from '../../util/AppComponentsProps';
import {
  passwordLengthOk,
  passwordHasAtLeastOneNumber,
  passwordHasAtLeastOneUpperCase,
  passwordHasAtLeastOneLowerCase,
} from '../../util/PasswordCheck';
import { delay } from '../../util/DelayUtil';

export default function AccountChangePassword(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const history = useHistory();
  const [info, setInfo] = useState<string | undefined>(undefined);
  const [userAccount, setUserAccount] = useState<UserAccount | undefined>(
    undefined
  );
  const [newPassword1, setNewPassword1] = useState<string | undefined>(
    undefined
  );
  const [newPassword2, setNewPassword2] = useState<string | undefined>(
    undefined
  );
  const [
    sendingPasswordChangeRequest,
    setSendingPasswordChangeRequest,
  ] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [textInputErrorEnabled, setTextInputErrorEnabled] = useState<boolean>(
    false
  );
  const [textInputErrorInfo, setTextInputErrorInfo] = useState<string>('');

  const [isPasswordLengthOk, setIsPasswordLengthOk] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneNumber,
    setHasPasswordAtLeastOneNumber,
  ] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneLowerCase,
    setHasPasswordAtLeastOneLowerCase,
  ] = useState<boolean>(false);
  const [
    hasPasswordAtLeastOneUpperCase,
    setHasPasswordAtLeastOneUpperCase,
  ] = useState<boolean>(false);
  const [isSamePasswordTwice, setIsSamePasswordTwice] = useState<boolean>(
    false
  );

  function removeErrorFromTextInput() {
    setTextInputErrorEnabled(false);
    setTextInputErrorInfo('');
  }

  async function sendChangePasswordRequest() {
    setSendingPasswordChangeRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ newPassword: newPassword1 }),
    };
    const response = await fetchBackend(
      'secure/account/changePassword',
      requestOptions
    );

    if (response.success === false) {
      setSendingPasswordChangeRequest(false);
      setTextInputErrorEnabled(true);
      setTextInputErrorInfo(response.message);
    }

    // We will stay in loading mode, show the info and go back after a few seconds to account-page
    if (response.success === true) {
      setInfo(t('ACCOUNT_CHANGE_PASSWORD_PAGE.CHANGE_SUCCESSFUL'));
      await delay(1500);
      history.goBack();
    }
  }

  useEffect(() => {
    const getAccountData = async function () {
      const userData = await fetchBackend('secure/account/data');

      if (userData.success === true) {
        setUserAccount(userData.data);
      }
    };
    if (userAccount === undefined) getAccountData();
  });

  if (userAccount === undefined) {
    return null;
  }

  function checkPasswordInput(
    password1: string | undefined,
    password2: string | undefined
  ) {
    const lengthOk = passwordLengthOk(password1);
    const atLeastOneNumber = passwordHasAtLeastOneNumber(password1);
    const atLeastOneUpperCase = passwordHasAtLeastOneUpperCase(password1);
    const atLeastOneLowerCase = passwordHasAtLeastOneLowerCase(password1);
    const bothPasswordsAreSame = password1 === password2;
    const passwordIsCorrectFormat =
      lengthOk &&
      atLeastOneLowerCase &&
      atLeastOneUpperCase &&
      atLeastOneNumber &&
      bothPasswordsAreSame;

    setIsPasswordLengthOk(lengthOk);
    setHasPasswordAtLeastOneNumber(atLeastOneNumber);
    setHasPasswordAtLeastOneUpperCase(atLeastOneUpperCase);
    setHasPasswordAtLeastOneLowerCase(atLeastOneLowerCase);
    setIsSamePasswordTwice(bothPasswordsAreSame);
    setButtonDisabled(!passwordIsCorrectFormat);
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ACCOUNT_CHANGE_PASSWORD_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <form>
        <MuiThemeProvider theme={materialUiTheme}>
          <TextField
            type="password"
            className={css(styles.inputField)}
            onChange={(input) => {
              removeErrorFromTextInput();
              const value = input.target.value;
              setNewPassword1(value);
              checkPasswordInput(value, newPassword2);
            }}
            disabled={sendingPasswordChangeRequest}
            error={textInputErrorEnabled}
            helperText={textInputErrorInfo}
            fullWidth
            label={t('ACCOUNT_CHANGE_PASSWORD_PAGE.NEW_PASSWORD_ONE')}
          />
          <div className={css(styles.spacing)} />
          <TextField
            type="password"
            className={css(styles.inputField)}
            onChange={(input) => {
              removeErrorFromTextInput();
              const value = input.target.value;
              setNewPassword2(value);
              checkPasswordInput(newPassword1, value);
            }}
            disabled={sendingPasswordChangeRequest}
            error={textInputErrorEnabled}
            helperText={textInputErrorInfo}
            fullWidth
            label={t('ACCOUNT_CHANGE_PASSWORD_PAGE.NEW_PASSWORD_TWO')}
          />
          <div className={css(styles.spacing)} />

          <div className={css(styles.passwordHintContainer)}>
            <CheckMark
              className={
                isPasswordLengthOk
                  ? css(styles.checkMarkIconFulfilled)
                  : css(styles.checkMarkIcon)
              }
            />
            <span className={css(styles.passwordHintText)}>
              {t('PASSWORD_POLICY.LENGTH')}
            </span>
          </div>
          <div className={css(styles.passwordHintContainer)}>
            <CheckMark
              className={
                hasPasswordAtLeastOneNumber
                  ? css(styles.checkMarkIconFulfilled)
                  : css(styles.checkMarkIcon)
              }
            />
            <span className={css(styles.passwordHintText)}>
              {t('PASSWORD_POLICY.NUMBER')}
            </span>
          </div>
          <div className={css(styles.passwordHintContainer)}>
            <CheckMark
              className={
                hasPasswordAtLeastOneUpperCase
                  ? css(styles.checkMarkIconFulfilled)
                  : css(styles.checkMarkIcon)
              }
            />
            <span className={css(styles.passwordHintText)}>
              {t('PASSWORD_POLICY.UPPER_CASE')}
            </span>
          </div>
          <div className={css(styles.passwordHintContainer)}>
            <CheckMark
              className={
                hasPasswordAtLeastOneLowerCase
                  ? css(styles.checkMarkIconFulfilled)
                  : css(styles.checkMarkIcon)
              }
            />
            <span className={css(styles.passwordHintText)}>
              {t('PASSWORD_POLICY.LOWER_CASE')}
            </span>
          </div>
          <div className={css(styles.passwordHintContainer)}>
            <CheckMark
              className={
                isSamePasswordTwice
                  ? css(styles.checkMarkIconFulfilled)
                  : css(styles.checkMarkIcon)
              }
            />
            <span className={css(styles.passwordHintText)}>
              {t('PASSWORD_POLICY.SAME_PASSWORDS')}
            </span>
          </div>

          <div className={css(styles.spacing)} />
          <Button
            className={css(styles.primaryButton)}
            variant="contained"
            disabled={buttonDisabled || sendingPasswordChangeRequest}
            onClick={() => {
              sendChangePasswordRequest();
            }}
            color="primary"
          >
            {sendingPasswordChangeRequest && (
              <CircularProgress color={'secondary'} size={22} />
            )}
            {!sendingPasswordChangeRequest && t('GLOBAL.SAVE')}
          </Button>
          <span onClick={() => history.goBack()}>
            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              color="secondary"
            >
              {t('GLOBAL.CANCEL')}
            </Button>
          </span>
        </MuiThemeProvider>
      </form>
      <div className={css(styles.spacing)} />
      <span className={css(styles.text)}>{info}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: SMALL_INPUT_MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 35,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    marginLeft: 15,
  },
  inputField: {
    maxWidth: MAX_WIDTH_CONTENT / 2,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  hint: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR,
    textAlign: 'justify',
  },
  passwordHintContainer: {
    flexDirection: 'row',
  },
  passwordHintText: {
    ...defaultStyles.textStyle,
    verticalAlign: 'super',
    fontSize: TEXT_SIZE_SMALL,
    marginLeft: 8,
  },
  checkMarkIcon: {
    color: SECONDARY_BUTTON_COLOR,
  },
  checkMarkIconFulfilled: {
    color: ACCENT_COLOR,
  },
});
