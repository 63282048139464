export type AppComponentsProps = {
  windowWidth: number;
  windowHeight: number;
};

export const DEFAULT_PADDING = 30;

export const MAX_WIDTH_PROPERTIES_LIST = 500;
export const MAX_WIDTH_MOBILE_PHONE = 767;
export const MAX_WIDTH_CONTENT = 1080;

export const SMALL_INPUT_MAX_WIDTH_CONTENT = 400;
export const ACCOUNT_MAX_WIDTH_CONTENT = 600;

export const SHOW_ERROR_INFO_TO_USER = 5000;
export const HOME_MAX_WIDTH_CONTENT = 1200;
