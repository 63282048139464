import i18next from 'i18next';
import {
  PaymentMethod,
  SubscriptionStatus,
  PaymentProvider,
} from '../payment/Subscription';

export type UserPaymentInfo = {
  paymentMethod: PaymentMethod;
  status: SubscriptionStatus;
  provider: PaymentProvider;
  providerStatus: string;
  providerSubscriptionId: string;
  createdDate: string;
  validUntil: string;
};

export type UserInfo = {
  email: string;
  registeredDate: string;
  amountOwnerProperties: number;
  amountRenterProperties: number;
  userPaymentInfo?: UserPaymentInfo;
};

export type UsersInfo = {
  amountUsers: number;
  amountUncommonPaidStatusUsers: number;
  amountCurrentlyPaidUsers: number;
  users: UserInfo[];
};

export function getReadableRegisteredDate(userInfo: UserInfo) {
  const date = new Date(Date.parse(userInfo.registeredDate));
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getReadableSubscriptionCreatedDate(
  userPaymentInfo: UserPaymentInfo
) {
  const date = new Date(Date.parse(userPaymentInfo.createdDate));
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}

export function getReadableSubscriptionValidUntilDate(
  userPaymentInfo: UserPaymentInfo
) {
  const date = new Date(Date.parse(userPaymentInfo.validUntil));
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}
