import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { MAX_WIDTH_CONTENT } from '../../util/AppComponentsProps';
import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { fetchBackend } from '../../authentication';
import {
  MuiThemeProvider,
  Button,
  TextField,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import { delay } from '../../util/DelayUtil';

export default function AdminSendMail(_props: any) {
  const [subject, setSubject] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [onlyPremiumUsers, setOnlyPremiumUsers] = useState<boolean>(false);
  const [info, setInfo] = useState<string | undefined>(undefined);
  const [sendingMailRequest, setSendingMailRequest] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  async function sendMailRequest() {
    setSendingMailRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        subject: subject,
        content: content,
        onlyPremiumUsers: onlyPremiumUsers,
      }),
    };
    const response = await fetchBackend(
      'secure/admin/sendMailToAll',
      requestOptions,
      true,
      true,
      true
    );

    // We will stay in loading mode, show the info and go back after a few seconds to account-page
    setSendingMailRequest(false);
    setInfo(response.info);
    await delay(3500);
    history.goBack();
  }

  const buttonActivated = subject.length > 0 && content.length > 0;

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ADMIN_SEND_MAIL_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <form>
        <MuiThemeProvider theme={materialUiTheme}>
          <TextField
            className={css(styles.inputField)}
            onChange={(input) => {
              const value = input.target.value;
              setSubject(value);
            }}
            disabled={sendingMailRequest}
            fullWidth
            label={t('ADMIN_SEND_MAIL_PAGE.SUBJECT')}
          />
          <div className={css(styles.spacing)} />
          <TextField
            className={css(styles.inputField)}
            onChange={(input) => {
              const value = input.target.value;
              setContent(value);
            }}
            multiline={true}
            disabled={sendingMailRequest}
            fullWidth
            label={t('ADMIN_SEND_MAIL_PAGE.CONTENT')}
          />
          <div className={css(styles.spacing)} />
          <div className={css(styles.entryContainer)}>
            <div>
              <span className={css(styles.text)}>
                {t('ADMIN_SEND_MAIL_PAGE.ONLY_SEND_TO_PREMIUM_USERS')}
              </span>
            </div>
            <div>
              <Switch
                checked={onlyPremiumUsers}
                color="primary"
                onChange={() => {
                  setOnlyPremiumUsers(!onlyPremiumUsers);
                }}
              />
            </div>
          </div>
          <div className={css(styles.spacing)} />
          <div className={css(styles.spacing)} />
          <Button
            className={css(styles.primaryButton)}
            variant="contained"
            disabled={sendingMailRequest || !buttonActivated}
            onClick={() => {
              sendMailRequest();
            }}
            color="primary"
          >
            {sendingMailRequest && (
              <CircularProgress color={'secondary'} size={22} />
            )}
            {!sendingMailRequest && t('ADMIN_SEND_MAIL_PAGE.SEND')}
          </Button>
          <span onClick={() => history.goBack()}>
            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              color="secondary"
            >
              {t('GLOBAL.CANCEL')}
            </Button>
          </span>
        </MuiThemeProvider>
      </form>
      <div className={css(styles.spacing)} />
      <span className={css(styles.text)}>{info}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  entryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spacing: {
    height: 20,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    marginLeft: 15,
  },
  inputField: {
    maxWidth: MAX_WIDTH_CONTENT,
  },
});
