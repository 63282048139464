import { LatLng } from './LatLng';

export type Group = {
  id: number;
  owner: boolean;
  center: LatLng;
  boundingBoxUpperLeft: LatLng;
  boundingBoxLowerRight: LatLng;
  calculatedSize: number;
  village?: string;
  city: string;
  county?: string;
};

export function generateGroupName(group?: Group): string {
  if (group) {
    if (group.county) return group.city + ' (' + group.county + ')';
    return group.city;
  }

  return '';
}
