export const HEADER_BACKGROUND_COLOR = '#000000';
export const HEADER_TEXT_COLOR = '#FFFFFF';
export const FOOTER_BACKGROUND_COLOR = '#A6ADB4';
export const FOOTER_TEXT_COLOR = '#EAEBEB';
export const CONTENT_BACKGROUND_COLOR = '#F6F8F9';

export const ACCENT_COLOR = '#008777';
export const ACCENT_COLOR_DISABLED = '#00877755';
export const ACCENT_COLOR_LIGHT = '#4DB7A6';
export const ACCENT_COLOR_DARK = '#00594B';

export const PRIMARY_BUTTON_COLOR = ACCENT_COLOR;
export const PRIMARY_BUTTON_TEXT_COLOR = '#FFFFFF';
export const SECONDARY_BUTTON_COLOR = '#CCCCCC';
export const SECONDARY_BUTTON_TEXT_COLOR = '#000000';

export const PRIMARY_TEXT_COLOR = '#202124';
export const SECONDARY_TEXT_COLOR = '#777777';
export const SECONDARY_TEXT_COLOR_ON_WHITE = '#A6ADB4';

export const PROPERTIES_BACKGROUND_COLOR = '#FFFFFF';
export const NOTIFICATION_COLOR = '#FF3700';

export const TEXT_COLOR_LINK = '#2d74df';
export const TEXT_COLOR_ERROR = '#E56253';

export const TABLE_BORDER_COLOR = '#CCCCCF';
export const INDEX_COLOR_UNMARKED = '#CCCCCC';

export const HOME_TEXT_COLOR_TOP = '#E6E6E6';
export const HOME_TEXT_COLOR_FEATURES = '#FFFFFF';
