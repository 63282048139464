import { TFunction } from 'i18next';
import { roundNumberWithMax2Decimals } from '../../../util/StringUtil';

export type AreaType = 'SQUARE_FOOT' | 'SQUARE_METER' | 'ACRE' | 'HECTARE';

export type UserSettings = {
  preferredAreaType: AreaType;
  showGroups: boolean;
  mailNotificationsEnabled: boolean;
};

export function convertFromSquareMeter(
  areaInSquareMeter: number,
  requestedAreaType: AreaType
): number {
  switch (requestedAreaType) {
    case 'SQUARE_FOOT':
      return areaInSquareMeter * 10.7639;
    case 'SQUARE_METER':
      return areaInSquareMeter;
    case 'ACRE':
      return areaInSquareMeter * 0.0002471054;
    case 'HECTARE':
      return areaInSquareMeter * 0.0001;
  }
}

export function getReadableSize(
  size: number,
  usedAreaType: AreaType,
  t: TFunction
): string {
  const areaTypeName = getAreaTypeName(usedAreaType, t);
  return roundNumberWithMax2Decimals(size) + ' ' + areaTypeName;
}

export function getAreaTypeName(usedAreaType: AreaType, t: TFunction) {
  switch (usedAreaType) {
    case 'SQUARE_FOOT':
      return t('AREA_TYPES.SQUARE_FOOT_SHORT');
    case 'SQUARE_METER':
      return t('AREA_TYPES.SQUARE_METER_SHORT');
    case 'ACRE':
      return t('AREA_TYPES.ACRE_SHORT');
    case 'HECTARE':
      return t('AREA_TYPES.HECTARE_SHORT');
  }
}
