import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import {
  MAX_WIDTH_MOBILE_PHONE,
  AppComponentsProps,
  MAX_WIDTH_CONTENT,
  DEFAULT_PADDING,
  HOME_MAX_WIDTH_CONTENT,
} from '../../util/AppComponentsProps';
import {
  TEXT_SIZE_BIGGER,
  TEXT_SIZE_VERY_VERY_BIG,
  TEXT_SIZE_BIG,
  TEXT_SIZE_VERY_BIG,
  TEXT_SIZE_NORMAL,
} from '../../styling/dimens';
import {
  HOME_TEXT_COLOR_TOP,
  ACCENT_COLOR_DARK,
  HOME_TEXT_COLOR_FEATURES,
} from '../../styling/colors';
import { MuiThemeProvider, Button } from '@material-ui/core';
import { materialUiTheme } from '../../styling/styles';
import { useHistory } from 'react-router-dom';

export type HomeProps = {
  appComponentsProps: AppComponentsProps;
};

export default function Home(props: HomeProps) {
  const { t } = useTranslation();
  const history = useHistory();
  document.title = t('GLOBAL.HOME_TITLE');

  const mobileScreen =
    props.appComponentsProps.windowWidth <= MAX_WIDTH_MOBILE_PHONE;

  if (mobileScreen) {
    return (
      <div className={css(mobileStyles.container)}>
        <MetaTags>
          <meta name="description" content={t('GLOBAL.HOME_DESCRIPTION')} />
        </MetaTags>
        <div className={css(styles.topBackground)}>
          <div className={css(mobileStyles.topContentWrapper)}>
            <div className={css(mobileStyles.headerText)}>
              {t('HOME_PAGE.TOP_HEADER')}
            </div>
            <div className={css(mobileStyles.subHeaderText)}>
              {t('HOME_PAGE.TOP_TEXT')}
            </div>
            <MuiThemeProvider theme={materialUiTheme}>
              <span
                onClick={() => {
                  history.push('/register');
                }}
              >
                <Button
                  className={css(mobileStyles.registerButton)}
                  variant="contained"
                  color="primary"
                >
                  {t('HOME_PAGE.TOP_BUTTON')}
                </Button>
              </span>
            </MuiThemeProvider>
          </div>
        </div>

        <div className={css(mobileStyles.accentColorContainer)}>
          <div className={css(mobileStyles.entryContainer)}>
            <div className={css(mobileStyles.entryTextContainer)}>
              <span className={css(mobileStyles.entryHeader)}>
                {t('HOME_PAGE.FIRST_FEATURE_HEADER')}
              </span>
              <span className={css(mobileStyles.entryText)}>
                {t('HOME_PAGE.FIRST_FEATURE_TEXT')}
              </span>
            </div>

            <video
              className={css(mobileStyles.videoEntry)}
              autoPlay
              playsInline
              muted
              loop
            >
              <source src="videos/mapusage_small.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className={css(mobileStyles.blackContainer)}>
          <div className={css(mobileStyles.entryContainer)}>
            <div className={css(mobileStyles.entryTextContainer)}>
              <span className={css(mobileStyles.entryHeader)}>
                {t('HOME_PAGE.SECOND_FEATURE_HEADER')}
              </span>
              <span className={css(mobileStyles.entryText)}>
                {t('HOME_PAGE.SECOND_FEATURE_TEXT')}
              </span>
            </div>
            <img
              src={window.location.origin + '/images/HomeList.jpg'}
              className={css(mobileStyles.imageEntry)}
              alt="List"
            />
          </div>
        </div>

        <div className={css(mobileStyles.accentColorContainer)}>
          <div className={css(mobileStyles.entryContainer)}>
            <div className={css(mobileStyles.entryTextContainer)}>
              <span className={css(mobileStyles.entryHeader)}>
                {t('HOME_PAGE.THIRD_FEATURE_HEADER')}
              </span>
              <span className={css(mobileStyles.entryText)}>
                {t('HOME_PAGE.THIRD_FEATURE_TEXT')}
              </span>
            </div>

            <video
              className={css(mobileStyles.videoEntry)}
              autoPlay
              playsInline
              muted
              loop
            >
              <source src="videos/changeproperty_small.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className={css(mobileStyles.blackContainer)}>
          <div className={css(mobileStyles.entryContainer)}>
            <div className={css(mobileStyles.entryTextContainer)}>
              <span className={css(mobileStyles.entryHeader)}>
                {t('HOME_PAGE.FOURTH_FEATURE_HEADER')}
              </span>
              <span className={css(mobileStyles.entryText)}>
                {t('HOME_PAGE.FOURTH_FEATURE_TEXT')}
              </span>
            </div>
            <img
              src={window.location.origin + '/images/HomeOtherUser.jpg'}
              className={css(mobileStyles.imageEntry)}
              alt="List"
            />
          </div>
        </div>

        <div className={css(mobileStyles.accentColorContainer)}>
          <div className={css(mobileStyles.entryContainer)}>
            <div className={css(mobileStyles.entryTextContainer)}>
              <span className={css(mobileStyles.entryHeader)}>
                {t('HOME_PAGE.FIFTH_FEATURE_HEADER')}
              </span>
              <span className={css(mobileStyles.entryText)}>
                {t('HOME_PAGE.FIFTH_FEATURE_TEXT')}
              </span>
            </div>
            <img
              src={window.location.origin + '/images/HomePayments.jpg'}
              className={css(mobileStyles.imageEntry)}
              alt="List"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      <MetaTags>
        <meta name="description" content={t('GLOBAL.HOME_DESCRIPTION')} />
      </MetaTags>
      <div className={css(styles.topBackground)}>
        <div className={css(styles.topContentWrapper)}>
          <div className={css(styles.headerText)}>
            {t('HOME_PAGE.TOP_HEADER')}
          </div>
          <div className={css(styles.subHeaderText)}>
            {t('HOME_PAGE.TOP_TEXT')}
          </div>
          <MuiThemeProvider theme={materialUiTheme}>
            <span
              onClick={() => {
                history.push('/register');
              }}
            >
              <Button
                className={css(styles.registerButton)}
                variant="contained"
                color="primary"
              >
                {t('HOME_PAGE.TOP_BUTTON')}
              </Button>
            </span>
          </MuiThemeProvider>
        </div>
      </div>

      <div className={css(styles.accentColorContainer)}>
        <div className={css(styles.entryContainer)}>
          <video
            className={css(styles.videoEntry)}
            autoPlay
            playsInline
            muted
            loop
          >
            <source src="videos/mapusage.mp4" type="video/mp4" />
          </video>
          <div className={css(styles.entryTextContainer)}>
            <span className={css(styles.entryHeader)}>
              {t('HOME_PAGE.FIRST_FEATURE_HEADER')}
            </span>
            <span className={css(styles.entryText)}>
              {t('HOME_PAGE.FIRST_FEATURE_TEXT')}
            </span>
          </div>
        </div>
      </div>

      <div className={css(styles.blackContainer)}>
        <div className={css(styles.entryContainer)}>
          <div className={css(styles.entryTextContainer)}>
            <span className={css(styles.entryHeader)}>
              {t('HOME_PAGE.SECOND_FEATURE_HEADER')}
            </span>
            <span className={css(styles.entryText)}>
              {t('HOME_PAGE.SECOND_FEATURE_TEXT')}
            </span>
          </div>
          <img
            src={window.location.origin + '/images/HomeList.jpg'}
            className={css(styles.imageEntry)}
            alt="List"
          />
        </div>
      </div>

      <div className={css(styles.accentColorContainer)}>
        <div className={css(styles.entryContainer)}>
          <video
            className={css(styles.videoEntry)}
            autoPlay
            playsInline
            muted
            loop
          >
            <source src="videos/changeproperty.mp4" type="video/mp4" />
          </video>
          <div className={css(styles.entryTextContainer)}>
            <span className={css(styles.entryHeader)}>
              {t('HOME_PAGE.THIRD_FEATURE_HEADER')}
            </span>
            <span className={css(styles.entryText)}>
              {t('HOME_PAGE.THIRD_FEATURE_TEXT')}
            </span>
          </div>
        </div>
      </div>

      <div className={css(styles.blackContainer)}>
        <div className={css(styles.entryContainer)}>
          <div className={css(styles.entryTextContainer)}>
            <span className={css(styles.entryHeader)}>
              {t('HOME_PAGE.FOURTH_FEATURE_HEADER')}
            </span>
            <span className={css(styles.entryText)}>
              {t('HOME_PAGE.FOURTH_FEATURE_TEXT')}
            </span>
          </div>
          <img
            src={window.location.origin + '/images/HomeOtherUser.jpg'}
            className={css(styles.imageEntry)}
            alt="List"
          />
        </div>
      </div>

      <div className={css(styles.accentColorContainer)}>
        <div className={css(styles.entryContainer)}>
          <img
            src={window.location.origin + '/images/HomePayments.jpg'}
            className={css(styles.imageEntry)}
            alt="List"
          />
          <div className={css(styles.entryTextContainer)}>
            <span className={css(styles.entryHeader)}>
              {t('HOME_PAGE.FIFTH_FEATURE_HEADER')}
            </span>
            <span className={css(styles.entryText)}>
              {t('HOME_PAGE.FIFTH_FEATURE_TEXT')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url(images/acre.jpg) no-repeat center center',
    height: 'calc(100vh - 50px)', // Minus header
    backgroundSize: 'cover',
  },
  topContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    width: 'auto',
    padding: 40,
    borderRadius: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  blackContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 100,
    alignItems: 'center',
    backgroundColor: '#222222',
  },
  accentColorContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 100,
    alignItems: 'center',
    backgroundColor: ACCENT_COLOR_DARK,
  },
  entryContainer: {
    width: HOME_MAX_WIDTH_CONTENT,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageEntry: {
    borderRadius: 5,
    maxWidth: (HOME_MAX_WIDTH_CONTENT * 3) / 7,
    marginLeft: 20,
    marginRight: 20,
  },
  videoEntry: {
    borderRadius: 5,
    maxWidth: (HOME_MAX_WIDTH_CONTENT * 3) / 7,
    marginLeft: 20,
    marginRight: 20,
  },
  entryTextContainer: {
    display: 'flex',
    maxWidth: (HOME_MAX_WIDTH_CONTENT * 3) / 7,
    flexDirection: 'column',
    marginLeft: 20,
    marginRight: 20,
  },
  entryHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 30,
    fontSize: TEXT_SIZE_VERY_BIG,
    color: HOME_TEXT_COLOR_FEATURES,
  },
  entryText: {
    textAlign: 'left',
    fontSize: TEXT_SIZE_BIG,
    color: HOME_TEXT_COLOR_FEATURES,
  },
  headerText: {
    paddingLeft: DEFAULT_PADDING,
    paddingRight: DEFAULT_PADDING,
    marginBottom: 20,
    maxWidth: MAX_WIDTH_CONTENT,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_VERY_VERY_BIG,
    color: HOME_TEXT_COLOR_TOP,
  },
  subHeaderText: {
    paddingLeft: DEFAULT_PADDING,
    paddingRight: DEFAULT_PADDING,
    marginBottom: 100,
    maxWidth: MAX_WIDTH_CONTENT,
    textAlign: 'center',
    fontSize: TEXT_SIZE_BIGGER,
    color: HOME_TEXT_COLOR_TOP,
  },
  registerButton: {
    color: HOME_TEXT_COLOR_TOP,
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_BIG,
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    width: 'auto',
    padding: 15,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  blackContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 65,
    paddingBottom: 65,
    alignItems: 'center',
    backgroundColor: '#222222',
  },
  accentColorContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 65,
    paddingBottom: 65,
    alignItems: 'center',
    backgroundColor: ACCENT_COLOR_DARK,
  },
  entryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageEntry: {
    borderRadius: 3,
    maxWidth: 300,
    marginLeft: 20,
    marginRight: 20,
  },
  videoEntry: {
    borderRadius: 3,
    maxWidth: 300,
    marginLeft: 20,
    marginRight: 20,
  },
  entryTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 45,
  },
  entryHeader: {
    fontWeight: 'bold',
    marginBottom: 10,
    fontSize: TEXT_SIZE_BIGGER,
    color: HOME_TEXT_COLOR_FEATURES,
  },
  entryText: {
    textAlign: 'center',
    fontSize: TEXT_SIZE_NORMAL,
    color: HOME_TEXT_COLOR_FEATURES,
  },
  headerText: {
    paddingLeft: DEFAULT_PADDING / 2,
    paddingRight: DEFAULT_PADDING / 2,
    marginBottom: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_BIGGER,
    color: HOME_TEXT_COLOR_TOP,
  },
  subHeaderText: {
    paddingLeft: DEFAULT_PADDING / 2,
    paddingRight: DEFAULT_PADDING / 2,
    marginBottom: 45,
    textAlign: 'center',
    fontSize: TEXT_SIZE_BIG,
    color: HOME_TEXT_COLOR_TOP,
  },
  registerButton: {
    color: HOME_TEXT_COLOR_TOP,
    cursor: 'pointer',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 'bold',
    fontSize: TEXT_SIZE_NORMAL,
  },
});
