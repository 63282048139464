import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import CookieConsent from 'react-cookie-consent';

import ErrorContainer from './containers/public/ErrorContainer';
import PrivateRoute from './PrivateRoute';
import AppHeader from './containers/AppHeader';
import AppFooter from './containers/AppFooter';
import { checkAuthenticated, checkAdminAuthenticated } from './authentication';
import { useTranslation } from 'react-i18next';
import {
  HEADER_BACKGROUND_COLOR,
  ACCENT_COLOR,
  HEADER_TEXT_COLOR,
  CONTENT_BACKGROUND_COLOR,
} from './styling/colors';
import { TEXT_SIZE_NORMAL } from './styling/dimens';
import Imprint from './containers/legal/Imprint';
import Register from './containers/account/Register';
import AccountOverview from './containers/account/AccountOverview';
import AccountChangeMail from './containers/account/AccountChangeMail';
import AccountChangePassword from './containers/account/AccountChangePassword';
import AccountDelete from './containers/account/AccountDelete';
import RootPage from './containers/RootPage';
import Price from './containers/public/Price';
import OwnerPropertiesPage from './containers/property/OwnerPropertiesPage';
import RenterPropertiesPage from './containers/property/RenterPropertiesPage';
import AccountPaymentOverview from './containers/account/AccountPaymentOverview';
import PaymentSuccess from './containers/account/PaymentSuccess';
import PaymentCancel from './containers/account/PaymentCancel';
import Terms from './containers/legal/Terms';
import Privacy from './containers/legal/Privacy';
import AdminRoute from './AdminRoute';
import AdminOverview from './containers/admin/AdminOverview';
import AdminSystemInformation from './containers/admin/AdminSystemInformation';
import AdminViewUsers from './containers/admin/AdminViewUsers';
import AdminViewProperties from './containers/admin/AdminViewProperties';
import AdminSendMail from './containers/admin/AdminSendMail';

export default function App() {
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [windowHeight, setWindowHeight] = useState<number>(
    typeof window !== 'undefined' ? window.innerHeight : 0
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  );
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState<
    boolean | undefined
  >(undefined);
  const { t } = useTranslation();
  updateViewportHeight();

  function updateViewportHeight() {
    // This is needed to set correct height for all devices (especially mobile devices)
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  function updateDimensions() {
    setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
    setWindowHeight(typeof window !== 'undefined' ? window.innerHeight : 0);
    updateViewportHeight();
  }

  // Check Authentication status
  useEffect(() => {
    document.title = t('GLOBAL.TITLE');
    const checkAuthStatus = async function () {
      await checkAuthenticated((authenticated: boolean) => {
        setIsAuthenticated(authenticated);
      });
    };
    if (isAuthenticated === undefined) checkAuthStatus();

    const checkAdminAuthStatus = async function () {
      await checkAdminAuthenticated((authenticated: boolean) => {
        setIsAdminAuthenticated(authenticated);
      });
    };
    if (isAdminAuthenticated === undefined) checkAdminAuthStatus();

    // Add and remove listener to get notified about changed window sizes
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  });

  if (isAuthenticated === undefined || isAdminAuthenticated === undefined) {
    return null;
  }

  // Update dimensions, if they are still 0
  if (
    !windowWidth ||
    windowWidth === 0 ||
    !windowHeight ||
    windowHeight === 0
  ) {
    setTimeout(() => {
      updateDimensions();
    }, 50);
    return null;
  }

  const priceRouteComponent = (props: any) => {
    return React.createElement(Price, {
      ...props,
      isAuthenticated: isAuthenticated,
      appComponentsProps: {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      },
    });
  };

  return (
    <Router>
      <div id="container">
        <AppHeader
          isAuthenticated={isAuthenticated}
          appComponentsProps={{ windowWidth, windowHeight }}
        />
        <div className={css(cssStyles.content)}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <RootPage
                  {...props}
                  appComponentsProps={{
                    windowWidth: windowWidth,
                    windowHeight: windowHeight,
                  }}
                  isAuthenticated={isAuthenticated}
                />
              )}
            />

            <Route path="/price" exact render={priceRouteComponent} />
            <Route path="/imprint" exact component={Imprint} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/register" exact component={Register} />
            <AdminRoute
              path="/admin"
              exact
              component={AdminOverview}
              isAdminAuthenticated={isAdminAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <AdminRoute
              path="/adminSystemInformation"
              exact
              component={AdminSystemInformation}
              isAdminAuthenticated={isAdminAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <AdminRoute
              path="/adminViewUsers"
              exact
              component={AdminViewUsers}
              isAdminAuthenticated={isAdminAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <AdminRoute
              path="/adminViewProperties"
              exact
              component={AdminViewProperties}
              isAdminAuthenticated={isAdminAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <AdminRoute
              path="/adminSendMailAll"
              exact
              component={AdminSendMail}
              isAdminAuthenticated={isAdminAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/account"
              exact
              component={AccountOverview}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/accountpayment"
              exact
              component={AccountPaymentOverview}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/paymentsuccess"
              exact
              component={PaymentSuccess}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/paymentcancel"
              exact
              component={PaymentCancel}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/accountchangemail"
              exact
              component={AccountChangeMail}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/accountchangepassword"
              exact
              component={AccountChangePassword}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/accountdelete"
              exact
              component={AccountDelete}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/owner"
              exact
              component={OwnerPropertiesPage}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <PrivateRoute
              path="/renter"
              exact
              component={RenterPropertiesPage}
              isAuthenticated={isAuthenticated}
              appComponentsProps={{ windowWidth, windowHeight }}
            />
            <Route component={ErrorContainer} />
          </Switch>
        </div>
        <CookieConsent
          location="bottom"
          buttonText={t('COOKIE_HINT.BUTTON')}
          cookieName="rentdCookieAccept"
          expires={365}
          style={{ background: ACCENT_COLOR }}
          contentStyle={{
            color: HEADER_TEXT_COLOR,
            fontSize: TEXT_SIZE_NORMAL,
          }}
          buttonStyle={{
            color: HEADER_TEXT_COLOR,
            fontSize: TEXT_SIZE_NORMAL,
            background: HEADER_BACKGROUND_COLOR,
            borderRadius: 10,
            boxShadow: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            padding: '8px 15px',
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          {t('COOKIE_HINT.TEXT')}
          <a href={'/privacy'}>
            <span>{t('COOKIE_HINT.LINK')}</span>
          </a>
          {t('COOKIE_HINT.TEXT2')}
        </CookieConsent>
        <AppFooter appComponentsProps={{ windowWidth, windowHeight }} />
      </div>
    </Router>
  );
}

const cssStyles = StyleSheet.create({
  content: {
    backgroundColor: CONTENT_BACKGROUND_COLOR,
    marginTop: 50,
    flexGrow: 1,
  },
});
