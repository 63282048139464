import i18next from 'i18next';

export type UserAccount = {
  email: string;
  registeredDate: string;
};

export function getReadableDate(userAccount: UserAccount) {
  const date = new Date(Date.parse(userAccount.registeredDate));
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(i18next.language, options);
}
