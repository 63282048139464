import {
  ACCENT_COLOR,
  PRIMARY_BUTTON_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_BUTTON_TEXT_COLOR,
  SECONDARY_BUTTON_COLOR,
  ACCENT_COLOR_DISABLED,
} from './colors';
import {
  TEXT_SIZE_NORMAL,
  TEXT_SIZE_BUTTON_NORMAL,
  TEXT_SIZE_BUTTON_SMALL,
} from './dimens';
import { MAX_WIDTH_CONTENT, DEFAULT_PADDING } from '../util/AppComponentsProps';
import { createTheme } from '@material-ui/core/styles';

export const defaultStyles = {
  containerStyle: {
    maxWidth: MAX_WIDTH_CONTENT,
    flex: 1,
    paddingTop: DEFAULT_PADDING,
    paddingBottom: DEFAULT_PADDING,
    paddingLeft: DEFAULT_PADDING,
    paddingRight: DEFAULT_PADDING,
    margin: '0 auto',
  },
  textStyle: {
    color: PRIMARY_TEXT_COLOR,
    fontSize: TEXT_SIZE_NORMAL,
    lineHeight: '1.25em',
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
  },
  materialPrimaryButtonStyle: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
    fontSize: TEXT_SIZE_BUTTON_NORMAL,
    fontWeight: 'bold',
    color: PRIMARY_BUTTON_TEXT_COLOR,
    minWidth: 120,
  },
  materialSecondaryButtonStyle: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
    fontSize: TEXT_SIZE_BUTTON_NORMAL,
    fontWeight: 'bold',
    color: SECONDARY_BUTTON_TEXT_COLOR,
    minWidth: 120,
  },
  materialSmallButtonStyle: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
    fontSize: TEXT_SIZE_BUTTON_SMALL,
    fontWeight: 'bold',
    color: PRIMARY_BUTTON_TEXT_COLOR,
    minWidth: 80,
  },
};

export const materialUiTheme = createTheme({
  palette: {
    action: {
      disabledBackground: ACCENT_COLOR_DISABLED,
    },
    primary: {
      main: ACCENT_COLOR,
    },
    secondary: {
      main: SECONDARY_BUTTON_COLOR,
    },
  },
});
