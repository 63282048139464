import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import MetaTags from 'react-meta-tags';
import { TEXT_SIZE_BIG, TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { defaultStyles } from '../../styling/styles';
import { useTranslation } from 'react-i18next';

export default function Privacy(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.PRIVACY_TITLE');

  window.scrollTo(0, 0);

  return (
    <div className={css(styles.container)}>
      <MetaTags>
        <meta name="description" content={t('GLOBAL.PRIVACY_DESCRIPTION')} />
      </MetaTags>
      <span className={css(styles.header)}>
        <div>{t('PRIVACY_PAGE.HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SUB_HEADER', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION1_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION1_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION2_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT3', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT4', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER5', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT5', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER6', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT6', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER7', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT7', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_SUB_HEADER8', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION2_TEXT8', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION3_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION3_SUB_HEADER1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION3_TEXT1', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.subSection)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION3_SUB_HEADER2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION3_TEXT2', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION4_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION4_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION5_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION5_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION6_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION6_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION7_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION7_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.spacing)} />
      <span className={css(styles.section)}>
        <div>{t('PRIVACY_PAGE.SECTION8_HEADER')}</div>
      </span>
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('PRIVACY_PAGE.SECTION8_TEXT', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </span>

      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <div className={css(styles.smallSpacing)} />
      <span className={css(styles.text)}>
        <div>{t('TERMS_OF_USE.VERSION')}</div>
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  smallSpacing: {
    height: 10,
  },
  spacing: {
    height: 60,
  },
  section: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_BIG,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  subSection: {
    ...defaultStyles.textStyle,
    textTransform: 'uppercase',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
    wordBreak: 'break-word',
  },
});
