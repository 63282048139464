import React, { useState, useEffect } from 'react';
import { EventBus } from 'ts-bus';

import PropertiesComponent from '../../components/property/PropertiesComponent';
import MapComponent, {
  markerClickedEvent,
  centerBoundingBoxEvent,
  switchToMapTabEvent,
} from '../../components/map/MapComponent';
import {
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../../util/AppComponentsProps';

import { makeStyles, Theme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import { materialUiTheme } from '../../styling/styles';

const eventBus = new EventBus();
let subscribedEvents: { (): void }[] = [];

export type PropertiesPageProps = {
  owner: boolean;
  appComponentsProps: AppComponentsProps;
};

export default function PropertiesPage(
  propertiesPageProps: PropertiesPageProps
) {
  const materialStyles = useMaterialStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentPage(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setCurrentPage(index);
  };

  const mobileScreen =
    propertiesPageProps.appComponentsProps.windowWidth <=
    MAX_WIDTH_MOBILE_PHONE;

  useEffect(() => {
    if (!mobileScreen) return;

    subscribedEvents.push(
      eventBus.subscribe(switchToMapTabEvent, () => {
        handleChangeIndex(1);
      })
    );

    subscribedEvents.push(
      eventBus.subscribe(markerClickedEvent, (event) => {
        const clickedPropertyId = event.payload.propertyId;
        const lastClickedPropertyId = event.payload.lastClickedPropertyId;

        // Only change to list tab, if the user clicked the marker a second time (when already zoomed in)
        if (lastClickedPropertyId === clickedPropertyId) {
          handleChangeIndex(0);
        }
      })
    );

    subscribedEvents.push(
      eventBus.subscribe(centerBoundingBoxEvent, (event) => {
        const switchToMapViewOnMobileDevice =
          event.payload.switchToMapViewOnMobileDevice;

        if (switchToMapViewOnMobileDevice) {
          handleChangeIndex(1);
        }
      })
    );

    return () => {
      subscribedEvents.forEach((subscribedEvent) => subscribedEvent());
      subscribedEvents = [];
    };
  }, [mobileScreen]);

  if (!mobileScreen) {
    return (
      <div>
        <PropertiesComponent
          hidden={false}
          eventBus={eventBus}
          appComponentsProps={propertiesPageProps.appComponentsProps}
          owner={propertiesPageProps.owner}
        />
        <MapComponent
          hidden={false}
          appComponentsProps={propertiesPageProps.appComponentsProps}
          eventBus={eventBus}
        />
      </div>
    );
  }

  return (
    <div className={materialStyles.root}>
      <MuiThemeProvider theme={materialUiTheme}>
        <AppBar position="static" color="default">
          <Tabs
            value={currentPage}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={t('PROPERTIES.MOBILE_TAB.LIST_TAB')}
              id="full-width-tab-0"
              aria-controls="full-width-tabpanel-0"
            />
            <Tab
              label={t('PROPERTIES.MOBILE_TAB.MAP_TAB')}
              id="full-width-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
          </Tabs>
        </AppBar>
        <PropertiesComponent
          hidden={currentPage !== 0}
          eventBus={eventBus}
          appComponentsProps={propertiesPageProps.appComponentsProps}
          owner={propertiesPageProps.owner}
        />

        <MapComponent
          hidden={currentPage !== 1}
          appComponentsProps={propertiesPageProps.appComponentsProps}
          eventBus={eventBus}
        />
      </MuiThemeProvider>
    </div>
  );
}

const useMaterialStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));
