import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  TextField,
  MuiThemeProvider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { TEXT_SIZE_VERY_BIG } from '../../styling/dimens';
import { ACCENT_COLOR } from '../../styling/colors';
import { defaultStyles, materialUiTheme } from '../../styling/styles';
import { useTranslation } from 'react-i18next';
import { UserAccount } from '../../server/model/user/UserAccount';
import { fetchBackend } from '../../authentication';
import {
  MAX_WIDTH_CONTENT,
  SMALL_INPUT_MAX_WIDTH_CONTENT,
} from '../../util/AppComponentsProps';
import { validateMail } from '../../util/MailCheck';
import { delay } from '../../util/DelayUtil';

export default function AccounChangeMail(_props: any) {
  const { t } = useTranslation();
  document.title = t('GLOBAL.TITLE');

  const history = useHistory();
  const [info, setInfo] = useState<string | undefined>(undefined);
  const [userAccount, setUserAccount] = useState<UserAccount | undefined>(
    undefined
  );
  const [newMailAddress, setNewMailAddress] = useState<string | undefined>(
    undefined
  );
  const [givenTan, setGivenTan] = useState<string | undefined>(undefined);
  const [requestedTan, setRequestedTan] = useState<boolean>(false);
  const [sendingMailRequest, setSendingMailRequest] = useState<boolean>(false);
  const [sendingTanRequest, setSendingTanRequest] = useState<boolean>(false);
  const [buttonSendMailDisabled, setButtonSendMailDisabled] = useState<boolean>(
    true
  );
  const [mailTextInputErrorEnabled, setMailTextInputErrorEnabled] = useState<
    boolean
  >(false);
  const [mailTextInputErrorInfo, setMailTextInputErrorInfo] = useState<string>(
    ''
  );
  const [tanTextInputErrorEnabled, setTanTextInputErrorEnabled] = useState<
    boolean
  >(false);
  const [tanTextInputErrorInfo, setTanTextInputErrorInfo] = useState<string>(
    ''
  );

  function removeErrorFromMailTextInput() {
    setMailTextInputErrorEnabled(false);
    setMailTextInputErrorInfo('');
  }

  function removeErrorFromTanTextInput() {
    setTanTextInputErrorEnabled(false);
    setTanTextInputErrorInfo('');
  }

  async function sendChangeMailAdressRequest() {
    setSendingMailRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ newMail: newMailAddress }),
    };
    const response = await fetchBackend(
      'secure/account/changeMail',
      requestOptions
    );
    setSendingMailRequest(false);

    if (response.success === false) {
      setMailTextInputErrorEnabled(true);
      setMailTextInputErrorInfo(response.message);
    }

    if (response.success === true) {
      setInfo(t('ACCOUNT_CHANGE_MAIL_PAGE.REQUEST_SUCCESSFUL'));
      setRequestedTan(true);
    }
  }

  async function sendChangeMailAdressVerificationRequest() {
    setSendingTanRequest(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ tan: givenTan }),
    };
    const response = await fetchBackend(
      'secure/account/changeMailVerification',
      requestOptions
    );

    if (response.success === false) {
      setSendingTanRequest(false);
      setTanTextInputErrorEnabled(true);
      setTanTextInputErrorInfo(response.message);
    }

    // We will stay in loading mode, show the info and go back after a few seconds to account-page
    if (response.success === true) {
      setInfo(t('ACCOUNT_CHANGE_MAIL_PAGE.CHANGE_SUCCESSFUL'));
      await delay(1500);
      history.goBack();
    }
  }

  useEffect(() => {
    const getAccountData = async function () {
      const userData = await fetchBackend('secure/account/data');

      if (userData.success === true) {
        setUserAccount(userData.data);
      }
    };
    if (userAccount === undefined) getAccountData();
  });

  if (userAccount === undefined) {
    return null;
  }

  function onClickMainButton() {
    if (requestedTan) sendChangeMailAdressVerificationRequest();
    else sendChangeMailAdressRequest();
  }

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>{t('ACCOUNT_CHANGE_MAIL_PAGE.TITLE')}</div>
      </span>

      <div className={css(styles.spacing)} />
      <form>
        <MuiThemeProvider theme={materialUiTheme}>
          <TextField
            className={css(styles.inputField)}
            disabled
            fullWidth
            label={t('ACCOUNT_CHANGE_MAIL_PAGE.CURRENT_ADDRESS')}
            defaultValue={userAccount.email}
          />
          <div className={css(styles.spacing)} />
          <TextField
            type="email"
            className={css(styles.inputField)}
            onChange={(input) => {
              removeErrorFromMailTextInput();
              const value = input.target.value;
              const isValid = validateMail(value);
              setNewMailAddress(value);
              setButtonSendMailDisabled(!isValid);
            }}
            disabled={requestedTan || sendingMailRequest}
            error={mailTextInputErrorEnabled}
            helperText={mailTextInputErrorInfo}
            fullWidth
            label={t('ACCOUNT_CHANGE_MAIL_PAGE.NEW_ADDRESS')}
          />
          {requestedTan && (
            <div>
              <div className={css(styles.spacing)} />
              <TextField
                className={css(styles.inputField)}
                onChange={(input) => {
                  setInfo('');
                  removeErrorFromTanTextInput();
                  const value = input.target.value;
                  setGivenTan(value);
                }}
                hidden={true}
                error={tanTextInputErrorEnabled}
                helperText={tanTextInputErrorInfo}
                fullWidth
                label={t('ACCOUNT_CHANGE_MAIL_PAGE.VERIFICATION_TAN')}
              />
            </div>
          )}
          <div className={css(styles.spacing)} />
          <div className={css(styles.spacing)} />
          <Button
            className={css(styles.primaryButton)}
            variant="contained"
            disabled={
              buttonSendMailDisabled || sendingMailRequest || sendingTanRequest
            }
            onClick={() => onClickMainButton()}
            color="primary"
          >
            {(sendingMailRequest || sendingTanRequest) && (
              <CircularProgress color={'secondary'} size={22} />
            )}
            {!sendingMailRequest && !sendingTanRequest && t('GLOBAL.SAVE')}
          </Button>
          <span onClick={() => history.goBack()}>
            <Button
              className={css(styles.secondaryButton)}
              variant="contained"
              color="secondary"
            >
              {t('GLOBAL.CANCEL')}
            </Button>
          </span>
        </MuiThemeProvider>
      </form>
      <div className={css(styles.spacing)} />
      <span className={css(styles.text)}>{info}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.containerStyle,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: SMALL_INPUT_MAX_WIDTH_CONTENT,
  },
  header: {
    ...defaultStyles.textStyle,
    fontSize: TEXT_SIZE_VERY_BIG,
    fontWeight: 'bold',
    color: ACCENT_COLOR,
    wordBreak: 'break-word',
  },
  spacing: {
    height: 35,
  },
  primaryButton: {
    ...defaultStyles.materialPrimaryButtonStyle,
    fontWeight: 'bold',
  },
  secondaryButton: {
    ...defaultStyles.materialSecondaryButtonStyle,
    fontWeight: 'bold',
    marginLeft: 15,
  },
  inputField: {
    maxWidth: MAX_WIDTH_CONTENT / 2,
  },
  text: {
    ...defaultStyles.textStyle,
    textAlign: 'justify',
  },
});
