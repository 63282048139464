import React, { useState } from 'react';
import { PropertyToDraw } from '../map/model/PropertyToDraw';
import { AreaType } from '../../server/model/user/UserSettings';
import { EventBus } from 'ts-bus';
import { StyleSheet, css } from 'aphrodite';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
  generatePropertyName,
  getReadableUserGivenSizeForOverview,
} from '../../server/model/property/Property';
import { CIRCLE_SIZE, centerBoundingBox } from './PropertiesListComponent';
import { defaultStyles } from '../../styling/styles';
import { SECONDARY_TEXT_COLOR_ON_WHITE } from '../../styling/colors';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export type SingleListEntryComponentProps = {
  isInvitedProperties: boolean;
  propertyToDraw: PropertyToDraw;
  preferredAreaType: AreaType;
  eventBus: EventBus;
  clickedDetails: () => void;
  clickedPaymentDetails: () => void;
  clickedInvitedDetails: () => void;
  clickedAcceptInvitation: () => void;
  clickedDeclineInvitation: () => void;
};

export function SingleListEntryComponent(
  singleListEntryComponentProps: SingleListEntryComponentProps
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [acceptInviteDialogOpen, setAcceptInviteDialogOpen] = useState(false);
  const [declineInviteDialogOpen, setDeclineInviteDialogOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const {
    propertyToDraw,
    preferredAreaType,
    eventBus,
  } = singleListEntryComponentProps;
  const { t } = useTranslation();
  const materialStyles = provideMaterialStyles();

  const handleClickOpenAcceptInviteDialog = () => {
    setAcceptInviteDialogOpen(true);
  };

  const handleCloseAcceptInviteDialog = () => {
    setAcceptInviteDialogOpen(false);
  };

  const handleClickOpenDeclineInviteDialog = () => {
    setDeclineInviteDialogOpen(true);
  };

  const handleCloseDeclineInviteDialog = () => {
    setDeclineInviteDialogOpen(false);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const items = singleListEntryComponentProps.isInvitedProperties
    ? [
        {
          name: t('PROPERTIES.INVITED_PROPERTIES.MENU_DETAILS'),
          callback: singleListEntryComponentProps.clickedInvitedDetails,
        },
        {
          name: t('PROPERTIES.INVITED_PROPERTIES.MENU_ACCEPT_INVITE'),
          callback: () => {
            handleClickOpenAcceptInviteDialog();
          },
        },
        {
          name: t('PROPERTIES.INVITED_PROPERTIES.MENU_DECLINE_INVITE'),
          callback: () => {
            handleClickOpenDeclineInviteDialog();
          },
        },
      ]
    : [
        {
          name: t('PROPERTIES.LIST.MENU_DETAILS'),
          callback: singleListEntryComponentProps.clickedDetails,
        },
        {
          name: t('PROPERTIES.LIST.MENU_PAYMENT_DETAILS'),
          callback: singleListEntryComponentProps.clickedPaymentDetails,
        },
      ];

  return (
    <ListItem
      id={`property-${propertyToDraw.property.id}`}
      button
      onClick={() => {
        centerBoundingBox(propertyToDraw.property, eventBus, true);
      }}
      className={materialStyles.nested}
    >
      <ListItemIcon>
        <svg width={CIRCLE_SIZE} height={CIRCLE_SIZE}>
          <g>
            <circle
              cx={CIRCLE_SIZE / 2}
              cy={CIRCLE_SIZE / 2}
              fill={propertyToDraw.color}
              r={CIRCLE_SIZE / 2}
            ></circle>
            <text
              fill="white"
              fontWeight="bold"
              x={CIRCLE_SIZE / 2}
              y={CIRCLE_SIZE / 2}
              fontSize={CIRCLE_SIZE / 2}
              textAnchor="middle"
              alignmentBaseline="central"
              dominantBaseline="middle"
            >
              {propertyToDraw.userVisibleNumber}
            </text>
          </g>
        </svg>
      </ListItemIcon>
      <ListItemText>
        <div className={css(styles.textContainer)}>
          <div className={css(styles.textHeader)}>
            {generatePropertyName(propertyToDraw.property, t)}
          </div>
          <div className={css(styles.textFooter)}>
            {getReadableUserGivenSizeForOverview(
              propertyToDraw.property,
              preferredAreaType,
              t
            )}
          </div>
        </div>
      </ListItemText>
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={css(styles.moreIconContainer)}
          onClick={(event) => {
            handleClickMenu(event);

            // Don't forward click
            event.stopPropagation();
          }}
        >
          <MoreIcon className={css(styles.moreIcon)} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={(event: any) => {
            handleCloseMenu();

            // Don't forward click
            event.stopPropagation();
          }}
        >
          {items.map((optionItem: OptionItem) => (
            <MenuItem
              key={optionItem.name}
              onClick={(event) => {
                handleCloseMenu();
                optionItem.callback();

                // Don't forward click
                event.stopPropagation();
              }}
            >
              {optionItem.name}
            </MenuItem>
          ))}
        </Menu>

        <Dialog
          open={acceptInviteDialogOpen}
          onClose={(event: any) => {
            handleCloseAcceptInviteDialog();

            // Don't forward click
            event.stopPropagation();
          }}
          aria-labelledby="acceptListDialogTitle"
          aria-describedby="acceptListDialogText"
        >
          <DialogTitle id="acceptListDialogTitle">
            {t('PROPERTIES.INVITED_PROPERTIES.ACCEPT_INVITE_DIALOG_TITLE')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="acceptListDialogText">
              {t('PROPERTIES.INVITED_PROPERTIES.ACCEPT_INVITE_DIALOG_TEXT')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => {
                handleCloseAcceptInviteDialog();

                // Don't forward click
                event.stopPropagation();
              }}
              color="primary"
            >
              {t('GLOBAL.NO')}
            </Button>
            <Button
              onClick={(event) => {
                handleCloseAcceptInviteDialog();
                singleListEntryComponentProps.clickedAcceptInvitation();

                // Don't forward click
                event.stopPropagation();
              }}
              color="primary"
              autoFocus
            >
              {t('GLOBAL.YES')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={declineInviteDialogOpen}
          onClose={(event: any) => {
            handleCloseDeclineInviteDialog();

            // Don't forward click
            event.stopPropagation();
          }}
          aria-labelledby="declineListDialogTitle"
          aria-describedby="declineListDialogText"
        >
          <DialogTitle id="declineListDialogTitle">
            {t('PROPERTIES.INVITED_PROPERTIES.DECLINE_INVITE_DIALOG_TITLE')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="declineListDialogText">
              {t('PROPERTIES.INVITED_PROPERTIES.DECLINE_INVITE_DIALOG_TEXT')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => {
                handleCloseDeclineInviteDialog();

                // Don't forward click
                event.stopPropagation();
              }}
              color="primary"
            >
              {t('GLOBAL.NO')}
            </Button>
            <Button
              onClick={(event) => {
                handleCloseDeclineInviteDialog();
                singleListEntryComponentProps.clickedDeclineInvitation();

                // Don't forward click
                event.stopPropagation();
              }}
              color="primary"
              autoFocus
            >
              {t('GLOBAL.YES')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ListItem>
  );
}

export type OptionItem = {
  name: string;
  callback: () => void;
};

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  textHeader: {
    ...defaultStyles.textStyle,
  },
  textFooter: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
  },
  moreIcon: {
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
  },
  moreIconContainer: {
    padding: 0,
  },
});

const provideMaterialStyles = makeStyles(() => ({
  nested: {
    paddingLeft: 16 + CIRCLE_SIZE / 2,
  },
}));
