import React, { useState } from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import InvitedItemsIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import AddItemIcon from '@material-ui/icons/PlaylistAddRounded';
import { materialUiTheme } from '../../styling/styles';
import { defaultStyles } from '../../styling/styles';

import {
  NOTIFICATION_COLOR,
  PRIMARY_BUTTON_TEXT_COLOR,
  ACCENT_COLOR,
} from '../../styling/colors';
import { useTranslation } from 'react-i18next';

export type PropertyAddButtonComponentProps = {
  invitedPropertiesAmount: number;
  clickedAddNewProperty: () => void;
  clickedShowInvitedProperties: () => void;
};

export default function PropertyAddButtonComponent(
  propertyAddButtonComponentProps: PropertyAddButtonComponentProps
) {
  const [addButtonOpen, setAddButtonOpen] = useState(false);

  const { t } = useTranslation();
  const materialStyles = provideMaterialStyles();
  const invitedIconEnabled =
    propertyAddButtonComponentProps.invitedPropertiesAmount > 0;
  const textInvitedProperties = invitedIconEnabled
    ? propertyAddButtonComponentProps.invitedPropertiesAmount === 1
      ? t('PROPERTIES.LIST.SINGLE_INVITATION_OTHER_PROPERTIES')
      : propertyAddButtonComponentProps.invitedPropertiesAmount +
        t('PROPERTIES.LIST.MULTIPLE_INVITATIONS_OTHER_PROPERTIES')
    : t('PROPERTIES.LIST.NO_INVITED_PROPERTIES');

  const actions = [
    {
      icon: (
        <InvitedItemsIcon
          className={css(
            invitedIconEnabled ? styles.optionIcon : styles.optionIconDisabled
          )}
        />
      ),
      id: 0,
      name: (
        <div
          className={css(
            invitedIconEnabled ? styles.optionProps : styles.optionPropsDisabled
          )}
        >
          {textInvitedProperties}
        </div>
      ),
      action: () => {
        if (!invitedIconEnabled) return;
        setAddButtonOpen(false);
        propertyAddButtonComponentProps.clickedShowInvitedProperties();
      },
    },
    {
      icon: <AddItemIcon className={css(styles.optionIcon)} />,
      id: 1,
      name: (
        <div className={css(styles.optionProps)}>
          {t('PROPERTIES.LIST.ADD_NEW_PROPERTY')}
        </div>
      ),
      action: () => {
        setAddButtonOpen(false);
        propertyAddButtonComponentProps.clickedAddNewProperty();
      },
    },
  ];

  return (
    <div className={css(styles.container)}>
      <MuiThemeProvider theme={materialUiTheme}>
        <SpeedDial
          ariaLabel=""
          className={materialStyles.speedDial}
          icon={<SpeedDialIcon />}
          onClose={() => setAddButtonOpen(false)}
          onOpen={() => setAddButtonOpen(true)}
          open={addButtonOpen}
          direction={'up'}
        >
          {actions.map((action) => (
            <SpeedDialAction
              icon={action.icon}
              key={action.id}
              className={materialStyles.buttonContainer}
              classes={{ staticTooltipLabel: materialStyles.labelContainer }}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => action.action()}
            />
          ))}
        </SpeedDial>
        {invitedIconEnabled ? (
          <div className={css(styles.addButtonIndicator)}>
            <svg width={18} height={18}>
              <g>
                <circle cx={9} cy={9} fill={NOTIFICATION_COLOR} r={9}></circle>
              </g>
            </svg>
          </div>
        ) : null}
      </MuiThemeProvider>
    </div>
  );
}

const provideMaterialStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  buttonContainer: {
    background: ACCENT_COLOR,
    backgroundColor: ACCENT_COLOR + ' !important',
  },
  labelContainer: {
    background: ACCENT_COLOR,
  },
}));

const styles = StyleSheet.create({
  addButtonIndicator: {
    position: 'absolute',
    zIndex: 1100,
    bottom: 52,
    right: 15,
  },
  optionProps: {
    ...defaultStyles.textStyle,
    color: PRIMARY_BUTTON_TEXT_COLOR,
    width: 220,
  },
  optionPropsDisabled: {
    width: 220,
    color: PRIMARY_BUTTON_TEXT_COLOR,
    opacity: 0.4,
  },
  optionIcon: {
    color: PRIMARY_BUTTON_TEXT_COLOR,
  },
  optionIconDisabled: {
    color: PRIMARY_BUTTON_TEXT_COLOR,
    opacity: 0.4,
  },
  container: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    left: 0,
    bottom: 0,
    width: '100%',
  },
});
