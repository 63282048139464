export function passwordLengthOk(password?: string): boolean {
  return password ? password.length >= 8 : false;
}

export function passwordHasAtLeastOneNumber(password?: string): boolean {
  const hasNumber = /\d/;
  return password ? hasNumber.test(password) : false;
}

export function passwordHasAtLeastOneUpperCase(password?: string): boolean {
  return password ? password.toLowerCase() !== password : false;
}

export function passwordHasAtLeastOneLowerCase(password?: string): boolean {
  return password ? password.toUpperCase() !== password : false;
}
