import React, { useState, useEffect, useCallback } from 'react';
import { EventBus } from 'ts-bus';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { useHistory } from 'react-router-dom';

import { Group, generateGroupName } from '../../server/model/property/Group';
import { defaultStyles } from '../../styling/styles';
import {
  PROPERTIES_BACKGROUND_COLOR,
  SECONDARY_TEXT_COLOR_ON_WHITE,
  SECONDARY_TEXT_COLOR,
  ACCENT_COLOR,
} from '../../styling/colors';
import { PropertyToDraw } from '../map/model/PropertyToDraw';

import {
  centerBoundingBoxEvent,
  markerClickedEvent,
} from '../map/MapComponent';
import {
  AppComponentsProps,
  MAX_WIDTH_MOBILE_PHONE,
} from '../../util/AppComponentsProps';
import { NO_GROUPS_GROUP_ID } from '../../util/PropertyUtil';
import { AreaType } from '../../server/model/user/UserSettings';
import { SingleListEntryComponent } from './SingleListEntryComponent';
import { Property } from '../../server/model/property/Property';

export const CIRCLE_SIZE = 32;

let subscribedEvents: { (): void }[] = [];

export type PropertiesListComponentProps = {
  nonUsablePropertiesAmount: number;
  canAddMoreProperties: boolean;
  isInvitedProperties: boolean;
  owner: boolean;
  eventBus: EventBus;
  groupsWithProperties: GroupsWithProperties;
  showGroups: boolean;
  preferredAreaType: AreaType;
  appComponentsProps: AppComponentsProps;
  clickedPropertyDetails?: (propertyToDraw: PropertyToDraw) => void;
  clickedPaymentDetails?: (propertyToDraw: PropertyToDraw) => void;
  clickedShowSingleInvitedProperty?: (property: Property) => void;
  clickedAcceptInvitedProperty?: (property: Property) => void;
  clickedDeclineInvitedProperty?: (property: Property) => void;
};

export default function PropertiesListComponent(
  propertiesListComponentProps: PropertiesListComponentProps
) {
  const [isGroupOpened, setIsGroupOpened] = useState<{
    [groupId: number]: boolean;
  }>({});
  const { t } = useTranslation();
  const history = useHistory();

  const changeOpenStateOfGivenGroup = useCallback(
    (groupId: number, onlyChangeToOpenState: boolean = false) => {
      if (isGroupOpened[groupId] && onlyChangeToOpenState) return;

      isGroupOpened[groupId] = !isGroupOpened[groupId];
      setIsGroupOpened(() => ({ ...isGroupOpened }));
    },
    [isGroupOpened]
  );

  const mobileScreen =
    propertiesListComponentProps.appComponentsProps.windowWidth <=
    MAX_WIDTH_MOBILE_PHONE;

  useEffect(() => {
    subscribedEvents.push(
      propertiesListComponentProps.eventBus.subscribe(
        markerClickedEvent,
        (event) => {
          const clickedPropertyId = event.payload.propertyId;
          const lastClickedPropertyId = event.payload.lastClickedPropertyId;

          if (propertiesListComponentProps.showGroups) {
            // Get property and groupPosition
            var clickedGroupPropertyToDraw: PropertyToDraw | undefined;
            let groupIdOfClickedProperty: number | undefined;
            Object.keys(
              propertiesListComponentProps.groupsWithProperties
            ).forEach((groupIdString: string) => {
              const groupId = (groupIdString as unknown) as number;
              const group =
                propertiesListComponentProps.groupsWithProperties[groupId];
              const searchedProperty = group.propertiesToDraw.find(
                (propertyToDraw) =>
                  propertyToDraw.property.id === clickedPropertyId
              );
              if (searchedProperty) {
                clickedGroupPropertyToDraw = searchedProperty;
                groupIdOfClickedProperty = groupId;
              }
            });

            // Center bounding box of property and open/scroll to group
            if (clickedGroupPropertyToDraw && groupIdOfClickedProperty) {
              // Only zoom in property in mobile view, if not already zoomed in
              if (
                !mobileScreen ||
                lastClickedPropertyId !== clickedPropertyId
              ) {
                centerBoundingBox(
                  clickedGroupPropertyToDraw.property,
                  propertiesListComponentProps.eventBus,
                  true
                );
              }

              // Do only change stuff in list in mobile view, if the user clicked the marker again when zoomed
              if (
                !mobileScreen ||
                lastClickedPropertyId === clickedPropertyId
              ) {
                changeOpenStateOfGivenGroup(groupIdOfClickedProperty, true);
                const propertyElement = document.getElementById(
                  `property-${clickedGroupPropertyToDraw.property.id}`
                );

                // Wait till the group is really opened
                setTimeout(function () {
                  if (propertyElement && !mobileScreen) {
                    propertyElement.scrollIntoView();
                  }
                }, 200);
              }
            }
          } else {
            // Get property
            const clickedPropertyToDraw = propertiesListComponentProps.groupsWithProperties[
              NO_GROUPS_GROUP_ID
            ].propertiesToDraw.find(
              (propertyToDraw) =>
                propertyToDraw.property.id === clickedPropertyId
            );

            // Center bounding box of property and scroll to item in list
            if (clickedPropertyToDraw) {
              // Only zoom in property in mobile view, if not already zoomed in
              if (
                !mobileScreen ||
                lastClickedPropertyId !== clickedPropertyId
              ) {
                centerBoundingBox(
                  clickedPropertyToDraw.property,
                  propertiesListComponentProps.eventBus,
                  true
                );
              }

              // Do only change stuff in list in mobile view, if the user clicked the marker again when zoomed
              if (
                !mobileScreen ||
                lastClickedPropertyId === clickedPropertyId
              ) {
                const propertyElement = document.getElementById(
                  `property-${clickedPropertyToDraw.property.id}`
                );
                if (propertyElement && !mobileScreen) {
                  propertyElement.scrollIntoView();
                }
              }
            }
          }
        }
      )
    );

    return () => {
      subscribedEvents.forEach((subscribedEvent) => subscribedEvent());
      subscribedEvents = [];
    };
  }, [
    changeOpenStateOfGivenGroup,
    mobileScreen,
    propertiesListComponentProps.eventBus,
    propertiesListComponentProps.groupsWithProperties,
    propertiesListComponentProps.showGroups,
  ]);

  let premiumAboInfo = undefined;
  if (propertiesListComponentProps.nonUsablePropertiesAmount > 0) {
    const firstText =
      propertiesListComponentProps.nonUsablePropertiesAmount === 1
        ? (propertiesListComponentProps.isInvitedProperties
            ? t('PAYMENT_PAGE.TOO_MUCH_INVITED_PROPERTIES_PREFIX')
            : t('PAYMENT_PAGE.TOO_MUCH_PROPERTIES_PREFIX')) +
          '1' +
          (propertiesListComponentProps.isInvitedProperties
            ? t('PAYMENT_PAGE.TOO_MUCH_INVITED_PROPERTIES_POSTFIX_SINGLE')
            : t('PAYMENT_PAGE.TOO_MUCH_PROPERTIES_POSTFIX_SINGLE'))
        : (propertiesListComponentProps.isInvitedProperties
            ? t('PAYMENT_PAGE.TOO_MUCH_INVITED_PROPERTIES_PREFIX')
            : t('PAYMENT_PAGE.TOO_MUCH_PROPERTIES_PREFIX')) +
          propertiesListComponentProps.nonUsablePropertiesAmount +
          (propertiesListComponentProps.isInvitedProperties
            ? t('PAYMENT_PAGE.TOO_MUCH_INVITED_PROPERTIES_POSTFIX_MULTIPLE')
            : t('PAYMENT_PAGE.TOO_MUCH_PROPERTIES_POSTFIX_MULTIPLE'));
    premiumAboInfo = (
      <div className={css(styles.premiumAboInfoContainer)}>
        <span className={css(styles.premiumAboInfo)}>
          {firstText}{' '}
          {
            <span
              className={css(styles.premiumAboInfoLink)}
              onClick={() => {
                history.push('/accountpayment');
              }}
            >
              {t('GLOBAL.HERE')}
            </span>
          }
          {t('PAYMENT_PAGE.PREMIUM_ABO_HINT')}
        </span>
      </div>
    );
  } else if (!propertiesListComponentProps.canAddMoreProperties) {
    premiumAboInfo = (
      <div className={css(styles.premiumAboInfoContainer)}>
        <span className={css(styles.premiumAboInfo)}>
          {t('PAYMENT_PAGE.ENOUGH_PROPERTIES_HINT')}{' '}
          {
            <span
              className={css(styles.premiumAboInfoLink)}
              onClick={() => {
                history.push('/accountpayment');
              }}
            >
              {t('GLOBAL.HERE')}
            </span>
          }
          {t('PAYMENT_PAGE.PREMIUM_ABO_HINT')}
        </span>
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      {Object.keys(propertiesListComponentProps.groupsWithProperties).length ===
      0 ? (
        <div className={css(styles.info)}>
          {propertiesListComponentProps.isInvitedProperties
            ? t('PROPERTIES.INVITED_PROPERTIES.NO_INVITED_PROPERTIES_AVAILABLE')
            : t('PROPERTIES.LIST.NO_PROPERTIES_AVAILABLE')}
        </div>
      ) : (
        drawList(
          propertiesListComponentProps.groupsWithProperties,
          changeOpenStateOfGivenGroup,
          isGroupOpened,
          premiumAboInfo,
          propertiesListComponentProps.showGroups,
          propertiesListComponentProps.preferredAreaType,
          propertiesListComponentProps.isInvitedProperties,
          propertiesListComponentProps.eventBus,
          t,
          propertiesListComponentProps.clickedPropertyDetails,
          propertiesListComponentProps.clickedPaymentDetails,
          propertiesListComponentProps.clickedShowSingleInvitedProperty,
          propertiesListComponentProps.clickedAcceptInvitedProperty,
          propertiesListComponentProps.clickedDeclineInvitedProperty
        )
      )}
    </div>
  );
}

function drawList(
  groups: GroupsWithProperties,
  changeOpenStateOfGivenGroup: {
    (groupId: number): void;
  },
  isGroupOpened: { [groupId: number]: boolean },
  premiumAboInfo: JSX.Element | undefined,
  showGroups: boolean,
  preferredAreaType: AreaType,
  isInvitedProperties: boolean,
  eventBus: EventBus,
  t: TFunction,
  clickedPropertyDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedPaymentDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedShowSingleInvitedProperty?: (property: Property) => void,
  clickedAcceptInvitedProperty?: (property: Property) => void,
  clickedDeclineInvitedProperty?: (property: Property) => void
) {
  if (showGroups) {
    return drawGroupsList(
      groups,
      changeOpenStateOfGivenGroup,
      isGroupOpened,
      premiumAboInfo,
      preferredAreaType,
      isInvitedProperties,
      eventBus,
      t,
      clickedPropertyDetails,
      clickedPaymentDetails,
      clickedShowSingleInvitedProperty,
      clickedAcceptInvitedProperty,
      clickedDeclineInvitedProperty
    );
  }

  return drawOnlyPropertiesList(
    groups[NO_GROUPS_GROUP_ID].propertiesToDraw,
    preferredAreaType,
    isInvitedProperties,
    eventBus,
    premiumAboInfo,
    clickedPropertyDetails,
    clickedPaymentDetails,
    clickedShowSingleInvitedProperty,
    clickedAcceptInvitedProperty,
    clickedDeclineInvitedProperty
  );
}

function drawOnlyPropertiesList(
  propertiesToDraw: PropertyToDraw[],
  preferredAreaType: AreaType,
  isInvitedProperties: boolean,
  eventBus: EventBus,
  premiumAboInfo: JSX.Element | undefined,
  clickedPropertyDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedPaymentDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedShowSingleInvitedProperty?: (property: Property) => void,
  clickedAcceptInvitedProperty?: (property: Property) => void,
  clickedDeclineInvitedProperty?: (property: Property) => void
) {
  const materialStyles = provideMaterialStyles();
  return (
    <List className={materialStyles.root}>
      {propertiesToDraw.map((propertyToDraw) => (
        <SingleListEntryComponent
          isInvitedProperties={isInvitedProperties}
          key={`property-${propertyToDraw.property.id}`}
          propertyToDraw={propertyToDraw}
          preferredAreaType={preferredAreaType}
          eventBus={eventBus}
          clickedDetails={() => {
            if (clickedPropertyDetails) {
              clickedPropertyDetails(propertyToDraw);
            }
          }}
          clickedPaymentDetails={() => {
            if (clickedPaymentDetails) {
              clickedPaymentDetails(propertyToDraw);
            }
          }}
          clickedInvitedDetails={() => {
            if (clickedShowSingleInvitedProperty) {
              clickedShowSingleInvitedProperty(propertyToDraw.property);
            }
          }}
          clickedAcceptInvitation={() => {
            if (clickedAcceptInvitedProperty) {
              clickedAcceptInvitedProperty(propertyToDraw.property);
            }
          }}
          clickedDeclineInvitation={() => {
            if (clickedDeclineInvitedProperty) {
              clickedDeclineInvitedProperty(propertyToDraw.property);
            }
          }}
        />
      ))}
      {premiumAboInfo}
    </List>
  );
}

function drawGroupsList(
  groups: GroupsWithProperties,
  changeOpenStateOfGivenGroup: {
    (groupId: number): void;
  },
  isGroupOpened: { [groupId: number]: boolean },
  premiumAboInfo: JSX.Element | undefined,
  preferredAreaType: AreaType,
  isInvitedProperties: boolean,
  eventBus: EventBus,
  t: TFunction,
  clickedPropertyDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedPaymentDetails?: (propertyToDraw: PropertyToDraw) => void,
  clickedShowSingleInvitedProperty?: (property: Property) => void,
  clickedAcceptInvitedProperty?: (property: Property) => void,
  clickedDeclineInvitedProperty?: (property: Property) => void
) {
  const materialStyles = provideMaterialStyles();
  const groupIds: number[] = [];
  for (const groupId in groups) {
    groupIds.push((groupId as unknown) as number);
  }

  return (
    <List className={materialStyles.root}>
      {groupIds.map((groupId) => (
        <li key={`group-${groupId}`} className={materialStyles.listSection}>
          <ul className={materialStyles.ul}>
            <ListSubheader className={materialStyles.listSectionHeader}>
              <ListItem
                button
                onClick={() => changeOpenStateOfGivenGroup(groupId)}
              >
                <ListItemIcon>
                  <div
                    className={css(
                      StyleSheet.create({
                        style: {
                          ...runtimeStyles.groupCircle,
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: groups[groupId].color,
                        },
                      }).style
                    )}
                  />
                </ListItemIcon>
                <ListItemText>
                  <div className={css(styles.groupTextContainer)}>
                    <div className={css(styles.groupTextHeader)}>
                      {generateGroupName(groups[groupId].groupData)}
                    </div>
                    <div className={css(styles.groupTextFooter)}>
                      {getAmountOfPropertiesForGivenGroup(
                        groups[groupId].propertiesToDraw,
                        t
                      )}
                    </div>
                  </div>
                </ListItemText>
                {isGroupOpened[groupId] ? (
                  <img
                    src={window.location.origin + '/images/ExpandLess.svg'}
                    alt="ExpandLess"
                  />
                ) : (
                  <img
                    src={window.location.origin + '/images/ExpandMore.svg'}
                    alt="ExpandMore"
                  />
                )}
              </ListItem>
            </ListSubheader>
            <Collapse in={isGroupOpened[groupId]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {groups[groupId].propertiesToDraw.map((propertyToDraw) => (
                  <SingleListEntryComponent
                    isInvitedProperties={isInvitedProperties}
                    key={`property-${propertyToDraw.property.id}`}
                    propertyToDraw={propertyToDraw}
                    preferredAreaType={preferredAreaType}
                    eventBus={eventBus}
                    clickedDetails={() => {
                      if (clickedPropertyDetails) {
                        clickedPropertyDetails(propertyToDraw);
                      }
                    }}
                    clickedPaymentDetails={() => {
                      if (clickedPaymentDetails) {
                        clickedPaymentDetails(propertyToDraw);
                      }
                    }}
                    clickedInvitedDetails={() => {
                      if (clickedShowSingleInvitedProperty) {
                        clickedShowSingleInvitedProperty(
                          propertyToDraw.property
                        );
                      }
                    }}
                    clickedAcceptInvitation={() => {
                      if (clickedAcceptInvitedProperty) {
                        clickedAcceptInvitedProperty(propertyToDraw.property);
                      }
                    }}
                    clickedDeclineInvitation={() => {
                      if (clickedDeclineInvitedProperty) {
                        clickedDeclineInvitedProperty(propertyToDraw.property);
                      }
                    }}
                  />
                ))}
              </List>
            </Collapse>
          </ul>
        </li>
      ))}
      {premiumAboInfo}
    </List>
  );
}

export function centerBoundingBox(
  property: Property,
  eventBus: EventBus,
  switchToMapView: boolean
) {
  var smallestLatitude = 90.0;
  var biggestLatitude = -90.0;
  var smallestLongitude = 180.0;
  var biggestLongitude = -180.0;
  property.coordinates.forEach((latLng) => {
    if (latLng.longitude < smallestLongitude)
      smallestLongitude = latLng.longitude;
    if (latLng.longitude > biggestLongitude)
      biggestLongitude = latLng.longitude;
    if (latLng.latitude < smallestLatitude) smallestLatitude = latLng.latitude;
    if (latLng.latitude > biggestLatitude) biggestLatitude = latLng.latitude;
  });

  eventBus.publish(
    centerBoundingBoxEvent({
      switchToMapViewOnMobileDevice: switchToMapView,
      upperLeft: {
        longitude: smallestLongitude,
        latitude: biggestLatitude,
      },
      lowerRight: {
        longitude: biggestLongitude,
        latitude: smallestLatitude,
      },
    })
  );
}

function getAmountOfPropertiesForGivenGroup(
  propertiesToDraw: PropertyToDraw[],
  t: TFunction
): string {
  const amount = propertiesToDraw.length;

  if (amount === 1) return amount + t('PROPERTIES.LIST.ONE_PROPERTY');
  return amount + t('PROPERTIES.LIST.MULTIPLE_PROPERTIES');
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  groupTextContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  groupTextHeader: {
    ...defaultStyles.textStyle,
  },
  groupTextFooter: {
    ...defaultStyles.textStyle,
    color: SECONDARY_TEXT_COLOR_ON_WHITE,
  },
  info: {
    ...defaultStyles.textStyle,
    textAlign: 'center',
  },
  premiumAboInfoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    alignItems: 'center',
  },
  premiumAboInfo: {
    ...defaultStyles.textStyle,
    padding: 20,
    color: SECONDARY_TEXT_COLOR,
    textAlign: 'center',
  },
  premiumAboInfoLink: {
    ...defaultStyles.textStyle,
    color: ACCENT_COLOR,
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const runtimeStyles = {
  groupCircle: {
    borderRadius: CIRCLE_SIZE * 2,
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const provideMaterialStyles = makeStyles(() => ({
  root: {
    width: '100%',
    zIndex: 0,
    position: 'relative',
    overflow: 'auto',
    height: 'calc(100% - 16px)',
  },
  listSection: {
    backgroundColor: PROPERTIES_BACKGROUND_COLOR,
    marginLeft: 7,
    marginRight: 7,
    marginTop: 10,
    marginBottom: 10,
  },
  listSectionHeader: {
    padding: 0,
  },
  ul: {
    backgroundColor: PROPERTIES_BACKGROUND_COLOR,
    padding: 0,
  },
}));

export type GroupsWithProperties = {
  [groupId: number]: {
    propertiesToDraw: PropertyToDraw[];
    color: string;
    groupData?: Group;
  };
};
